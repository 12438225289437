export enum FieldNames {
    AddressLine = "address_line",
    AddessLandmark="address_landmark",
    AddressArea="address_area",
    AddressCity="address_city",
    AddressDistrict="address_district",
    AddressState="address_state",
    AddressPincode="address_pincode",
    AddressCountry="address_country",
    PrecriptionDocument="prescription_document",
    IdProofDocument="id_proof_document",
    AddressProofDocument="address_proof_document",
    PurchaseInvoiceDocument="purchase_invoice",
    Declaration="declaration",
    PatientConsent="patient_consent",
    TermsAndConditions="terms_and_conditions",
    Consent="consent",
    Disclaimer="disclaimer",
    PrivacyNotice="privacy_notice",
    PatientUuid = "patient_uuid",
    PatientFullName = "patient_full_name",
    PatientCode = "patient_code",
    IsIndianNational = "is_indian_national",
    PackScheme = "pack_scheme",
    Diagnosis = "diagnosis",
    TransplantDate = "transplant_date",
    Mobile = "mobile",
    AltMobile = "alt_mobile",
    DemoConfirmation = "demo_confirmation",
    DoctorUuid = "doctor_uuid",
    DoctorFullName = "doctor_full_name",
    Hospital = "hospital",
    CouponCode = "coupon_code",
    FgoCode = "fgo_code",
    PrescriptionDate = "prescription_date",
    TreatmentStartDate = "treatment_start_date",
    NextFreeCycleDate = "next_free_cycle_date",
    LastInfusionDate = "last_infusion_date",
    NextInfusionDate = "next_infusion_date",
    TotalCyclesCompleted = "total_cycles_completed",
    TotalCyclesRemaining = "total_cycles_remaining",
    NoOfTherapyMonths = "no_of_therapy_months",
    NoOfFreeMonths = "no_of_free_months",
    Status = "status",
    CloserComments = "closer_comments",
    Comments = "comments",
    RejectionReason = "rejection_reason",
    Treatment = "treatment",
    TreatmentCycle = "treatment_cycle",
    ProgramId = "program_id",
    ProductId = "product_id",
    Dosage = "dosage",
    Uom = "uom",
    DivisionCode = "division_code",
    OrgCode = "org_code",
    CreatedBy = "created_by",
    UpdatedBy = "updated_by",
    CreatedAt = "created_at",
    UpdatedAt = "updated_at",
    DeletedAt = "deleted_at",
    Gender = "gender",
    DemoConfirmationToken = "demo_confirmation_token",
    DemoConfirmationAcknowledgement = "demo_confirmation_acknowledgement",
    ConsentToken = "consent_token",
    ConsentAcknowledgement = "consent_acknowledgement",
    ConsentAcknowledgementDate = "consent_acknowledgement_date",
    LastOrderDate = "last_order_date",
    Email = "email",
    Initiator = "initiator",
    PatientCaretakerName = "patient_caretaker_name",
    HospitalCoordinatorName = "hospital_coordinator_name",
    PatientStatus = "patient_status",
    Age = "age",
    DOB="date_of_birth",
    MetaData = "meta_data",
    IOP="iop",
    DiseaseDuration="disease_duration_years",
    PrescribedDrugs="prescribed_drugs",
    BaselineIop="baseline_iop",
   ComorbidCondtitions= "comorbid_conditions"



}