import { GenerateFileURL } from "../../../domain/usages/fullfilment/generate-url";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpPostClient } from "../../protocols/http/http-post-client";


export class RemoteGenerateFileURL implements GenerateFileURL {
  constructor(
    private readonly url: string,
    private readonly HttpPostClient: HttpPostClient
  ) {}

  async generate(params: GenerateFileURL.Params): Promise<any> {
    const httpResponse = await this.HttpPostClient.post({
      url: this.url,
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    const statusCode = httpResponse.status.toString();
    if (
      !statusCode.startsWith("2") &&
      !statusCode.startsWith("4") &&
      statusCode !== "500"
    ) {
      return null;
    }

    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
