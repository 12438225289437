import { StateCreator } from "zustand";
import { EnrollmentFilterType } from "../../../domain/models/enrollment-filter.type";
import { useEnrollmentMainStore } from "./enrollmentMainStore";

interface FilterEnrollmentSLiceType {
  handleAppliedFilters: Function;
  filter: EnrollmentFilterType;
  filterState: boolean;
  handleFilterClose: Function;
  handleFilterOpen: Function;
}

const initialStates = {
  filter: {} as EnrollmentFilterType,
  filterState: false,
};

export const useFilterEnrollmentSlice: StateCreator<
FilterEnrollmentSLiceType
> = (set, get) => ({
  ...initialStates,
  handleAppliedFilters: (appliedFilter: EnrollmentFilterType) => {
    let fetchEnrollment = useEnrollmentMainStore.getState();
    fetchEnrollment.enrollmentList.splice(0);
    set(() => ({ filter: appliedFilter }));
    fetchEnrollment.fetchEnrollments( 1, appliedFilter, false);
  },
  handleFilterClose: () => {
    set(() => ({ filterState: false }));
  },
  handleFilterOpen: () => {
    set(() => ({ filterState: true }));
  },
});
