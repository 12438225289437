import { Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Add from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import AddNewEnrollment from "./AddNewEnrollment";
import { pageRoutes } from "../../../routes";
import LogoutIcon from "@mui/icons-material/Logout";
import { useEnrollmentMainStore } from "../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import { useLoginPageStore } from "../../../store/loginPageStore/loginPageStore";
import { LocalLoggedInUser } from "../../../data/usecases/local-logged-in-user";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { useAppointmentDetailsStore } from "../../../store/adminPageStore/patientDetailsStore/appointmentDetailsStore/appointmentDetailsStore";

import { getDomain } from "../../../domain/Utils";
import DomainName from "../../../domain/domain";
import { useProgramDetailStore } from "../../../store/adminPageStore/patientDetailsStore/programDetailsStore/programDetailsStore";
import { useSendToCFAStore } from "../../../store/adminPageStore/patientDetailsStore/sendToCFAStore/sendToCFAStore";
import UnlockModal from "../modals/UnlockModal";
import { GOOGLE_RECAPTCHE_SITE_KEY } from "../../../base";
import useRecaptcha from "../../hooks/reCaptcha";

const HeaderActions: React.FC = () => {
  const navigate = useNavigate();
  const { handleAddNewEnrollment, addNewEnrollment, isBlurred } =
    useEnrollmentMainStore();
  const { loginDetails, handleLogOut } = useLoginPageStore();
  const { fetchProgramOptions } = useProgramDetailStore();
  const { fetchProductOptions } = useSendToCFAStore();
  const {
    captchaToken: fetchProductOptionsToken,
    executeRecaptcha: executeFetchProductOptionsRecaptcha,
  } = useRecaptcha(GOOGLE_RECAPTCHE_SITE_KEY, "fetchProductOptions");

  const {
    captchaToken: fetchProgramOptionsToken,
    executeRecaptcha: executeFetchProgramOptionsRecaptcha,
  } = useRecaptcha(GOOGLE_RECAPTCHE_SITE_KEY, "fetchProgramOptions");

  const { showAllAppointments, handleShowAllAppointments } =
    useAppointmentDetailsStore();
  const { selectedCard } = useEnrollmentMainStore();
  const storage = LocalJsonStorage.getInstance();
  const loggedInUser = new LocalLoggedInUser(storage);
  const handleLogout = () => {
    handleLogOut();
    loggedInUser.logout();
    navigate(pageRoutes.loginPage);
  };
  const [openUnlockModal, setOpenUnlockModal] = useState<boolean>(false);

  const handleNewEnrolment = () => {
    executeFetchProductOptionsRecaptcha();
    executeFetchProgramOptionsRecaptcha();
  };
  useEffect(() => {
    if (fetchProgramOptionsToken) {
      fetchProgramOptions(fetchProgramOptionsToken).then(() => {
        handleAddNewEnrollment();
      });
    }
  }, [fetchProgramOptionsToken]);
  useEffect(() => {
    if (fetchProductOptionsToken) {
      fetchProductOptions(fetchProductOptionsToken);
    }
  }, [fetchProductOptionsToken]);

  return (
    <Stack direction="row" spacing={5}>
      {isBlurred && (
        <Button variant="text">
          <Typography
            color={"error.main"}
            textTransform={"none"}
            fontWeight={700}
            onClick={() => setOpenUnlockModal(true)}
          >
            🔒 Are you an authorized person to view these details? Click here to
            proceed.
          </Typography>
        </Button>
      )}

      {selectedCard.consent_acknowledgement == 1 &&
        getDomain() != DomainName.EISAI &&
        !isBlurred && (
          <Button
            color="secondary"
            onClick={() => handleShowAllAppointments()}
            className="addnewbutton"
            sx={{ width: "100px" }}
          >
            <Typography textTransform="none">
              {showAllAppointments ? "Home" : "Calender"}
            </Typography>
          </Button>
        )}
      {loginDetails.role_id !== 10 && !isBlurred && (
        <Button
          color="secondary"
          variant="contained"
          onClick={handleNewEnrolment}
          className="addnewbutton"
          startIcon={<Add />}
        >
          Add New
        </Button>
      )}

      <Button
        onClick={handleLogout}
        color={"error"}
        startIcon={<LogoutIcon fontSize="small" />}
      >
        <Typography variant="subtitle2" fontWeight={600}>
          LOG OUT
        </Typography>
      </Button>
      {addNewEnrollment && <AddNewEnrollment />}
      {openUnlockModal && (
        <UnlockModal
          openModal={openUnlockModal}
          handleCloseModal={() => setOpenUnlockModal(false)}
        />
      )}
    </Stack>
  );
};
export default HeaderActions;
