import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Status } from "../../../../../constants/enums/status";
import { ActionButton } from "../../../../../custom-theme/ActionButton";
import { DispatchOrderResponse } from "../../../../../domain/models/dispatch-order-type";
import { Vendor } from "../../../../../domain/models/get-vendor";
import { useOrderDetailsStore } from "../../../../../store/stockistPageStore/orderStore/orderDetailsStore";
import { Constant } from "../../../../../constants/constant";
import moment from "moment";
import dayjs from "dayjs";

const DispatchDetails: React.FC = () => {
  const { selectedOrder, onDispatch, vendorList } = useOrderDetailsStore();
  const { control, handleSubmit, setValue } = useForm<DispatchOrderResponse>();

  useEffect(() => {
    setValue(
      "dispatch_date",
      selectedOrder.dispatch_date ? dayjs(selectedOrder.dispatch_date) : null
    );
    setValue(
      "vendor_id",
      selectedOrder.vendor?.name ? selectedOrder.vendor?.name : ""
    );
    setValue("lr_number", selectedOrder.lr_no ? selectedOrder.lr_no : "");
  }, [selectedOrder.id]);

  const handleClick = (data: DispatchOrderResponse) => {
    let payload = {
      dispatch_date:
        data.dispatch_date &&
        moment(data.dispatch_date.toString()).format("YYYY-MM-DD"),
      vendor_id: parseInt(data.vendor_id),
      lr_number: data.lr_number,
    };
    onDispatch(payload);
  };

  return (
    <>
      <Grid container gap={2} justifyContent={"space-between"}>
        {(selectedOrder.status === Status.DISPATCHED ||
          selectedOrder.status === Status.PACKING ||
          selectedOrder.status === Status.DELIVERED) && (
          <>
            <Grid item xs={12}>
              <Typography variant={"body1"}>Dispatch Details:</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <InputLabel>Vendor:</InputLabel>
              <Controller
                name="vendor_id"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) =>
                  selectedOrder.status === Status.PACKING ? (
                    <Select
                      disabled={selectedOrder.status !== Status.PACKING}
                      size="small"
                      fullWidth
                      error={error && true}
                      onChange={onChange}
                      value={value}
                    >
                      {vendorList.map((option: Vendor) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <TextField
                      disabled={selectedOrder.status !== Status.PACKING}
                      size="small"
                      value={selectedOrder.vendor?.name}
                    />
                  )
                }
                rules={{
                  required: { value: true, message: Constant.FIELD_REQUIRED },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <InputLabel>LR Number:</InputLabel>
              <Controller
                name="lr_number"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    disabled={selectedOrder.status !== Status.PACKING}
                    value={value}
                    error={error && true}
                    helperText={error?.message}
                    defaultValue={selectedOrder.lr_no}
                    size="small"
                    name="lrNumber"
                    onChange={onChange}
                  />
                )}
                rules={{
                  required: { value: true, message: Constant.FIELD_REQUIRED },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <InputLabel>Dispatched Date:</InputLabel>
              <Controller
                name="dispatch_date"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disablePast
                      format="DD/MM/YYYY"
                      disabled={selectedOrder.status !== Status.PACKING}
                      value={value}
                      onChange={onChange}
                    />
                  </LocalizationProvider>
                )}
                rules={{
                  required: { value: true, message: Constant.FIELD_REQUIRED },
                }}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          {selectedOrder.status === Status.PACKING && (
            <ActionButton onClick={handleSubmit(handleClick)} fullWidth>
              <Typography textTransform={"capitalize"}>
                Dispatch Order
              </Typography>
            </ActionButton>
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default DispatchDetails;
