import { Chip, SxProps, Theme } from "@mui/material";

type GaChipProps = {
  text: string;
  isDisabled?: boolean;
  isSelected: boolean;
  onClick: () => void;
  sx?: SxProps<Theme>;
};

export const GaChip: React.FC<GaChipProps> = ({
  text,
  isDisabled = false,
  isSelected,
  onClick,
  sx,
}) => {
  return (
    <Chip
      label={text}
      onClick={onClick}
      disabled={isDisabled}
      sx={{
        color: isSelected ? "white" : "black",
        ...sx,
      }}
    />
  );
};
