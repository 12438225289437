import { useState } from "react";
import * as React from "react";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import "../../../../components/patient-information/OncologyPageStyles.css";

import AddNewFollowUps from "./sub-components/AddNewFollowUps";
import { FetchState } from "../../../../../domain/models/fetch-state-type";
import ListLoading from "../../../Loading/ListLoading";
import { useFollowUpsStore } from "../../../../../store/adminPageStore/followUpStore/followUpsStore";

import ViewFollowupHistory from "./sub-components/ViewFollowupHistory";
import ViewFollowUpThread from "./sub-components/ViewFollowUpThread";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import { FollowupThreadType } from "../../../../../domain/models/followup-thread-type";
import { toTitleCase } from "../../../../../domain/Utils";
import ConfirmModal from "../../../modals/ConfirmModal";
import { useEnrollmentMainStore } from "../../../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";

const FollowUpsPanel: React.FC = () => {
  const {
    followupThreadCount,
    fetchFollowUpThreadState,
    followUpThreads,
    fetchFollowUpForThread,
    closeFollowUpThreadLoading,
    closeFollowUpThread,
  } = useFollowUpsStore();
  const { isBlurred } = useEnrollmentMainStore();
  const [addFollowUp, setAddFollowUp] = useState<boolean>(false);
  const [viewHistory, setViewHistory] = useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [selectedThread, setSelectedThread] = useState<number | null>(null);
  const [addFollowupForThread, setAddFollowupForThread] =
    useState<boolean>(false);
  const handleCloseModal = () => {
    setAddFollowUp(false);
  };

  const handleViewFollowupHistory = (value: boolean, id: number) => {
    setViewHistory(value);
    setSelectedThread(id);
    fetchFollowUpForThread(id, false);
  };
  const getThreadDetails = (id: number) => {
    const threadDetails = followUpThreads.find((thread) => thread.id == id);
    return threadDetails ? threadDetails : ({} as FollowupThreadType);
  };
  const handleOpenConfirmModal = (id: number | null, value: boolean) => {
    setOpenConfirmModal(value);
    setSelectedThread(id);
  };
  return (
    <>
      <div style={{ overflow: "scroll", height: "70vh" }}>
        {fetchFollowUpThreadState === FetchState.LOADING && <ListLoading />}
        {fetchFollowUpThreadState === FetchState.SUCCESS && !viewHistory && (
          <Stack>
            {!viewHistory && !isBlurred && (
              <>
                <Button
                  variant="contained"
                  color="info"
                  fullWidth
                  onClick={() => setAddFollowUp(true)}
                >
                  <Typography
                    variant="subtitle2"
                    fontWeight={550}
                    color={"white"}
                  >
                    Create Follow-up Thread
                  </Typography>
                </Button>
                <Typography
                  variant="subtitle2"
                  marginLeft="10px"
                  marginTop="5px"
                  fontWeight={550}
                >
                  Count: {followupThreadCount}
                </Typography>
                <Stack gap={2}>
                  {followUpThreads.length > 0 &&
                    !isBlurred &&
                    followUpThreads.map((followupThread) => {
                      return (
                        <ViewFollowUpThread
                          handleViewHistory={handleViewFollowupHistory}
                          followupThread={followupThread}
                          handleOpenConfirmModal={handleOpenConfirmModal}
                        />
                      );
                    })}
                </Stack>
              </>
            )}

            <AddNewFollowUps
              addFollowUpThread={addFollowUp}
              handleCloseModal={handleCloseModal}
            />
          </Stack>
        )}
        {viewHistory && (
          <>
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              marginBottom={2}
            >
              <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
                <IconButton size="small" onClick={() => setViewHistory(false)}>
                  <ArrowBackIosRoundedIcon sx={{ fontSize: "15px" }} />
                </IconButton>

                <Typography variant="subtitle1" fontWeight={660}>
                  {selectedThread &&
                    getThreadDetails(selectedThread) &&
                    toTitleCase(getThreadDetails(selectedThread).title)}
                </Typography>
              </Stack>
              <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                {addFollowupForThread && (
                  <IconButton
                    sx={{ border: "1px solid lightGrey", borderRadius: 2 }}
                    onClick={() => setAddFollowupForThread(false)}
                  >
                    <CancelRoundedIcon fontSize="small" color="error" />
                  </IconButton>
                )}
                {!addFollowupForThread &&
                  selectedThread &&
                  getThreadDetails(selectedThread).status == "active" && (
                    <IconButton
                      sx={{ border: "1px solid lightGrey", borderRadius: 2 }}
                      onClick={() => setAddFollowupForThread(true)}
                    >
                      <AddCircleIcon fontSize="small" color="primary" />
                    </IconButton>
                  )}
              </Stack>
            </Stack>
            <ViewFollowupHistory
              addFollowup={addFollowupForThread}
              handleOpenAddFollowup={() => setAddFollowupForThread(false)}
            />
          </>
        )}
        {openConfirmModal && (
          <ConfirmModal
            openConfirmationModal={openConfirmModal}
            handleCloseConfirmationModal={() =>
              handleOpenConfirmModal(null, false)
            }
            message="Are you sure you want to close this followup thread?"
            handleSuccessCallback={() =>
              closeFollowUpThread(selectedThread, handleOpenConfirmModal, null)
            }
            loading={closeFollowUpThreadLoading}
          />
        )}
      </div>
    </>
  );
};
export default FollowUpsPanel;
