import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { AUTH_HEADER, AUTH_TOKEN_KEY, USER_LOGIN_API } from "../../base";
import { RemoteGenerateUserToken } from "../../data/usecases/remote-generate-user-token";
import Endpoints from "../../domain/endpoints";
import { GenerateUserToken } from "../../domain/usages/generate-token-for-patient";
import { LocalLoggedInUser } from "../../data/usecases/local-logged-in-user";
import { useNavigate } from "react-router-dom";
import { pageRoutes } from "../../routes";

interface GenerateUserTokenSliceType {
  generateToken: Function;
  tokenForPatient: string;
  generateTokenLoading: boolean;
}
const initialStates = {
  tokenForPatient: "",
  generateTokenLoading: false,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useGenerateUserTokenSlice: StateCreator<
  GenerateUserTokenSliceType
> = (set, get) => ({
  ...initialStates,
  generateToken: async () => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token  });
    const remoteGenerateUserToken: GenerateUserToken =
      new RemoteGenerateUserToken(
        `${USER_LOGIN_API}${Endpoints.GENERATE_USER_TOKEN}`,
        axiosHttpClient
      );
    set(() => ({
      generateTokenLoading: true,
    }));
    let result = await remoteGenerateUserToken.generate();
    if (result.success) {
      loggedInUser.setPatientToken(result.token);
      set(() => ({
        tokenForPatient: result.token,
        generateTokenLoading: false,
      }));
      Swal.fire({
        title: "Logged In Successfully",
        icon: "success",
        timer: 2000,
      });
    } else {
      set(() => ({
        generateTokenLoading: false,
      }));
      Swal.fire(result.message, "", "error");
    }
  },
});
