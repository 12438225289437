import React, { useMemo } from "react";
import { Box, Stepper, Step, StepLabel, Typography } from "@mui/material";

interface CustomStep {
  label: string;
  secondaryLabel: (activeStep: number) => string;
}

interface AppointmentCardStepperProps {
  activeStep: number;
  declined: boolean;
}

const getSteps = (isDeclined: boolean): CustomStep[] => [
  {
    label: "Appointment Created",
    secondaryLabel: (activeStep) => "Created by admin",
  },
  {
    label: isDeclined ? "Doctor Declined" : "Awaiting Confirmation",
    secondaryLabel: (activeStep) =>
      isDeclined
        ? activeStep >= 1
          ? "Unavailable"
          : "-"
        : activeStep >= 1
        ? "Virtual appointment"
        : "-",
  },
  {
    label: "Appointment Complete",
    secondaryLabel: (activeStep) =>
      activeStep >= 2
        ? activeStep >= 3
          ? "Appointment completed"
          : "Waiting for appointment"
        : "-",
  },
];

const AppointmentCardStepper: React.FC<AppointmentCardStepperProps> = ({
  activeStep,
  declined,
}) => {
  const steps = useMemo(() => getSteps(declined), [declined]);

  return (
    <Box borderBottom={"1px solid #E8E6E3"} pt={1} pb={1}>
      <Stepper activeStep={activeStep} orientation="horizontal">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel error={step.label === "Doctor Declined"}>
              <Typography
                fontSize={"12px"}
                fontWeight={600}
                color={
                  declined && index === 1
                    ? "red"
                    : index === activeStep
                    ? "primary"
                    : "inherit"
                }
              >
                {step.label}
              </Typography>
              <Typography fontSize={"12px"} color={"#ABB7C2"}>
                {step.secondaryLabel(activeStep)}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default AppointmentCardStepper;
