import { FileUploadToDB } from "../../../domain/usages/fullfilment/file-upload-to-DB";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpPostClient } from "../../protocols/http/http-post-client";


export class RemoteUploadFileToDB implements FileUploadToDB {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async upload(
    order_id: number,
    sub_order_id: number,
    params: FileUploadToDB.Params
  ): Promise<any> {
    const httpResponse = await this.httpPostClient.post({
      url: this.url
        .replace(":order_id", order_id.toString())
        .replace(":sub_order_id", sub_order_id.toString()),
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

    const statusCode = httpResponse.status.toString();
    if (
      !statusCode.startsWith("2") &&
      !statusCode.startsWith("4") &&
      statusCode !== "500"
    ) {
      return null;
    }

    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
