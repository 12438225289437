import { Skeleton, Stack } from "@mui/material";

type Props = {};

const FormLoading = (props: Props) => {
  return (
    <Stack spacing={2} margin={3}>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((index) => {
        return (
          <Skeleton
            key={index}
            variant="rectangular"
            animation="wave"
            height={60}
          />
        );
      })}
    </Stack>
  );
};

export default FormLoading;
