import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import {
  COMMON_AUTH_HEADER,
  ORDER_SERVICE,
  ORDER_SERVICE_TOKEN,
} from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { FetchSubOrderDetails } from "../../../domain/usages/fetch-suborder-details";
import { RemoteFetchSubOrderDetails } from "../../../data/usecases/remote-fetch-suborder-details";
import { SubOrderDetailsType } from "../../../domain/models/suborder-details-type";

interface FetchSubOrderDetailsSliceType {
  fetchSubOrderDetails: Function;
  subOrderDetails: SubOrderDetailsType;
  fetchSubOrderDetailsState: FetchState;
}

const initialStates = {
  subOrderDetails: {} as SubOrderDetailsType,
  fetchSubOrderDetailsState: FetchState.DEFAULT,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchSubOrderDetailsSlice: StateCreator<
  FetchSubOrderDetailsSliceType
> = (set) => ({
  ...initialStates,
  fetchSubOrderDetails: async (orderId: number, subOrderId: number) => {
    set(() => ({ fetchSubOrderDetailsState: FetchState.LOADING }));
    axiosHttpClient.setAuthHeaders({
      [COMMON_AUTH_HEADER]: `${ORDER_SERVICE_TOKEN}`,
    });

    const remoteFetchSubOrderDetails: FetchSubOrderDetails =
      new RemoteFetchSubOrderDetails(
        `${ORDER_SERVICE}${Endpoints.FETCH_SUBORDER_DETAILS}`,
        axiosHttpClient
      );
    let result = await remoteFetchSubOrderDetails.fetch(
      orderId.toString(),
      subOrderId.toString()
    );
    if (result.success) {
     
      set(() => ({
        subOrderDetails: result.sub_order,
        fetchSubOrderDetailsState: FetchState.SUCCESS,
      }));
    }
  },
});
