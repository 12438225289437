import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import { AUTH_HEADER, PSP_AUTH_TOKEN_KEY, ORDER_SERVICE } from "../../../base";
import { RemoteUpdateOrderDetails } from "../../../data/usecases/remote-update-order-details";
import Endpoints from "../../../domain/endpoints";
import { OrderItems } from "../../../domain/models/get-order-type";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { useOrderDetailsStore } from "./orderDetailsStore";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { UpdateOrderDetails } from "../../../domain/usages/update-order-details";
import { LocalLoggedInUser } from "../../../data/usecases/local-logged-in-user";

interface UpdateOrderSliceType {
  handleSaveChange: (data: OrderItems[]) => void;
  showSaveButton: boolean;
  handleShowSaveButton: Function;
}

const initialStates = {
  formData: [],
  showSaveButton: false,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useUpdateOrderSlice: StateCreator<UpdateOrderSliceType> = (
  set,
  get
) => ({
  ...initialStates,
  handleShowSaveButton: (value: boolean) => {
    set(() => ({ showSaveButton: value }));
  },
  handleSaveChange: async (data: OrderItems[]) => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getPSPToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token });

    const remoteUpdateOrderDetails: UpdateOrderDetails =
      new RemoteUpdateOrderDetails(
        `${ORDER_SERVICE}${Endpoints.UPDATE_ORDER_DETAILS}`,
        axiosHttpClient
      );

    const newArray = data.map((obj) => {
      const {
        description,
        expiry_date,
        created_at,
        uom,
        updated_at,
        order_id,
        total_price,
        unit_price,
        item,
        ...rest
      } = obj;
      return rest;
    });

    let orderDetail = useOrderDetailsStore.getState();

    let payload = {
      user_id: orderDetail.selectedOrder.user_id,
      enrolment_id: orderDetail.selectedOrder.enrolment_id,
      items: newArray,
    };
    let result = await remoteUpdateOrderDetails.update(
      orderDetail.selectedOrder.id,
      payload
    );
    if (result.success) {
      get().handleShowSaveButton(false);
      Swal.fire({
        title: "Saved SuccessFully",
        icon: "success",
        timer: 2000,
      });
    } else {
      get().handleShowSaveButton(true);
      Swal.fire({ title: "Upload Failed", icon: "error", timer: 3000 });
    }
  },
});
