import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  CAPTCHA_HEADER,
  PSP_AUTH_TOKEN_KEY,
  PSP_PROGRAM_SERVICE_API_URL,
} from "../../../base";
import { RemoteCreateNewEnrollment } from "../../../data/usecases/remote-create-new-enrollment";
import Endpoints from "../../../domain/endpoints";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { CreateNewEnrollment } from "../../../domain/usages/create-new-enrollment";
import { useConsentStore } from "../../patientConsentStore/consentStore";
import { isString } from "lodash";
import { RemoteCreateNewEnrollmentByPatient } from "../../../data/usecases/remote-create-new-enrollment-by-patient";

interface CreateEnrollmentByPatientSliceType {
  createEnrollmentByPatient: (data: CreateNewEnrollment.Params, captacha:string, getEnrolmentByConsentTokenDetailsToken:string) => void;
  createEnrolmentLoading: boolean;
}

const initialStates = {
  createEnrolmentLoading: false,
};

const axiosHttpClient = AxiosHttpClient.getInstance();

export const useCreateEnrollmentByPatientSlice: StateCreator<
  CreateEnrollmentByPatientSliceType
> = (set, get) => ({
  ...initialStates,
  createEnrollmentByPatient: async (payload: CreateNewEnrollment.Params, captacha:string, getEnrolmentByConsentTokenDetailsToken: string) => {

axiosHttpClient.setAuthCaptcha({[CAPTCHA_HEADER]:captacha})
    const remoteCreateNewEnrollment: CreateNewEnrollment =
      new RemoteCreateNewEnrollmentByPatient(
        `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.CREATE_ENROLLMENT}`,
        axiosHttpClient
      );
    set(() => ({ createEnrolmentLoading: true }));
    let result = await remoteCreateNewEnrollment.create(payload);
    if (result.success) {
      set(() => ({ createEnrolmentLoading: false }));
      Swal.fire("Enrollment created successfully.", "", "success");
     useConsentStore.getState().getEnrolmentByConsentTokenDetails(result.enrolment.consent_token, getEnrolmentByConsentTokenDetailsToken)
    } else {
      set(() => ({ createEnrolmentLoading: false }));
      if (result.errors) {
        if(isString(result.errors)){
          Swal.fire(result.errors, "", "error");
        } else{
          const keys = Object.keys(result.errors);
          Swal.fire(result.errors[keys[0]], "", "error");
        }
     
      } else{
        Swal.fire("Enrollment creation failed.", "", "error");
      }
      
    }
  },
});
