import {
  Button,
  Dialog,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { CreateDemoPayload } from "../../../domain/models/demo-request";
import { useDemoRequestStore } from "../../../store/stockistPageStore/demoRequestStore/demoRequestStore";
import { Constant } from "../../../constants/constant";

type Props = {
  openCreateDemoRequestModal: boolean;
  handleClose: Function;
};

const CreateDemoRequestModal: React.FC<Props> = ({
  openCreateDemoRequestModal,
  handleClose,
}) => {
  const { createDemoRequest, toggleDemoModal } = useDemoRequestStore();
  const { handleSubmit, control } = useForm<CreateDemoPayload>();

  const handleClick = () => {
    handleSubmit(createDemoRequest)();
  };

  useEffect(() => {
    handleClose();
  }, [toggleDemoModal]);

  return (
    <Dialog
      onClose={() => handleClose()}
      open={openCreateDemoRequestModal}
      sx={{
        marginLeft: 20,
      }}
    >
      <DialogTitle fontWeight={550}>Create Demo Request</DialogTitle>
      <Stack padding={2}>
        <Typography>Please Fill The Below Fields!</Typography>
      </Stack>
      <Stack padding={2} gap={2}>
        <Controller
          name={"assisted_by"}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              fullWidth
              size="small"
              value={value}
              label="Presenter Name"
              error={error && true}
              helperText={error?.message}
              onChange={onChange}
            />
          )}
          rules={{
            required: { value: true, message: Constant.FIELD_REQUIRED },
          }}
        />
        <Stack direction={"row"} gap={2}>
          <Controller
            name={"assisted_by_mobile"}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                type="number"
                fullWidth
                size="small"
                value={value}
                label="Contact Number of the Presenter"
                error={error && true}
                helperText={error?.message}
                onChange={onChange}
              />
            )}
            rules={{
              required: { value: true, message: Constant.FIELD_REQUIRED },
              pattern: {
                value: Constant.CORRECT_MOBILE.VALUE,
                message: Constant.CORRECT_MOBILE.MESSAGE,
              },
            }}
          />
        </Stack>
      </Stack>
      <Stack direction={"row"} padding={2} justifyContent="center" gap={4}>
        <Button onClick={handleClick} color="secondary" variant="contained">
          Create Demo Request
        </Button>
        <Button onClick={() => handleClose()} color="error" variant="text">
          <Typography variant="button" fontWeight={550}>
            Close
          </Typography>
        </Button>
      </Stack>
    </Dialog>
  );
};
export default CreateDemoRequestModal;
