import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import {
  COMMON_AUTH_HEADER,
  COMMON_PATIENT_SERVICE_API_URL,
  PATIENT_TOKEN_KEY,
} from "../../../../base";
import Endpoints from "../../../../domain/endpoints";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { usePersonalDetailStore } from "./personalDetailsStore";
import { LocalJsonStorage } from "../../../../infra/http/local-json-storage";
import { RemoteUpdatePatientContacts } from "../../../../data/usecases/remote-update-patient-contacts";
import { UpdatePatientContacts } from "../../../../domain/usages/update-patient-contacts";
import { useEnrollmentMainStore } from "../../enrollmentListStore/enrollmentMainStore";
import { LocalLoggedInUser } from "../../../../data/usecases/local-logged-in-user";

interface UpdateContactDetailSliceType {
  updateContacts: Function;
}
const initialStates = {};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useUpdateContactDetailSlice: StateCreator<
  UpdateContactDetailSliceType
> = (set, get) => ({
  ...initialStates,
  updateContacts: async (
    patientId: string,
    contactId: string,
    payload: UpdatePatientContacts.Params,
    successCallback: Function
  ) => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getPatientToken();
    axiosHttpClient.setAuthHeaders({ [COMMON_AUTH_HEADER]: `Bearer ${(token)}` });

    const remoteUpdatePatientDetails: UpdatePatientContacts =
      new RemoteUpdatePatientContacts(
        `${COMMON_PATIENT_SERVICE_API_URL}${Endpoints.UPDATE_PATIENT_CONTACT_DETAILS}`,
        axiosHttpClient
      );
    successCallback();
    usePersonalDetailStore.getState().handleLoading(true);
    let result = await remoteUpdatePatientDetails.update(
      patientId,
      contactId,
      payload
    );
    if (result._id) {
      usePersonalDetailStore.getState().handleLoading(false);
      usePersonalDetailStore
        .getState()
        .getPatient(
          useEnrollmentMainStore.getState().selectedCard.patient_uuid
        );
      usePersonalDetailStore.getState().handleEditPersonalDetails(null);
      Swal.fire("Contact deactivated successfully", "", "success");
    } else {
      usePersonalDetailStore.getState().handleLoading(false);
      if (result.errors && result.errors.length > 0) {
        Swal.fire(result.errors[0].message, "", "error");
      } else {
        Swal.fire(result.message, "", "error");
      }
    }
  },
});
