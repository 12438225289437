import { Box, Container } from "@mui/material";
import React from "react";
import OrderReceiverDetails from "./OrderReceiverDetails";
import OrderSummary from "./OrderSummary";
import DispatchDetails from "./DispatchDetails";
import DeliveryDetails from "./DeliveryDetails";

const OrderDetails: React.FC = () => {
  return (
    <Container>
      <Box marginLeft={2}>
        <Box marginTop={4} marginBottom={2}>
          <OrderReceiverDetails />
        </Box>
        <Box margin={2}>
          <OrderSummary />
        </Box>
        <Box margin={2} marginTop={4}>
          <DispatchDetails />
        </Box>
        <Box margin={2}>
          <DeliveryDetails />
        </Box>
      </Box>
    </Container>
  );
};
export default OrderDetails;
