import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
  PSP_PROGRAM_SERVICE_API_URL,
} from "../../../base";
import { RemoteFetchDailyFollowupsCountForAMonth } from "../../../data/usecases/remote-fetch-daily-followups-count-for-a-month";
import Endpoints from "../../../domain/endpoints";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { FetchDailyFollowupsCountForAMonth } from "../../../domain/usages/fetch-daily-followups-count-for-a-month";
import { DailyAggregatedFollowUpsType } from "../../../domain/models/daily-aggregate-follow-ups";
import { LocalLoggedInUser } from "../../../data/usecases/local-logged-in-user";

interface fetchFollowUpsCountForMonthType {
  dailyFollowupCount: DailyAggregatedFollowUpsType[];
  fetchFollowUpState: FetchState;
  fetchDailyCount: Function;
}
const initialStates = {
  fetchFollowUpState: FetchState.DEFAULT,
  dailyFollowupCount: [],
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useFetchDailyCountSlice: StateCreator<
  fetchFollowUpsCountForMonthType
> = (set) => ({
  ...initialStates,
  fetchDailyCount: async (
    month_year: string,
    calander_view: number,
    scrolled?: boolean
  ) => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getPSPToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token });

    const remoteFetchDailyFollowUpsCountForAMonth: FetchDailyFollowupsCountForAMonth =
      new RemoteFetchDailyFollowupsCountForAMonth(
        `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.FETCH_FOLLOW_UPS_FOR_MONTH}`,
        axiosHttpClient
      );

    if (!scrolled) {
      set(() => ({ fetchFollowUpState: FetchState.LOADING }));
    }
    let result = await remoteFetchDailyFollowUpsCountForAMonth.fetch({
      month_year
    
    });
    try {
      if (result.success) {
        set(() => ({
          dailyFollowupCount: result.daily_aggregated_follow_ups,
          fetchFollowUpState: FetchState.SUCCESS,
        }));
      }
    } catch (error) {
      set(() => ({ fetchFollowUpState: FetchState.ERROR }));
    }
  },
});
