import { StateCreator } from "zustand";
import { LocalJsonStorage } from "../infra/http/local-json-storage";
import { AxiosHttpClient } from "../infra/http/axios-http-client";
import {
  COMMON_AUTH_HEADER,
  LOCATION_SERVICE_BASE_URL,
  LOCATION_SERVICE_TOKEN,
  PSP_AUTH_TOKEN_KEY,
} from "../base";
import { GetAddressBasedOnPincode } from "../domain/usages/get-address-based-on-pincode";
import Endpoints from "../domain/endpoints";
import { RemoteGetAddressBasedOnPincode } from "../data/usecases/remote-get-address-based-on-pincode";
import { AddressFormFields } from "../domain/models/addressFormFields";

interface GetAddressBasedOnPincodeSliceType {
  fetchAddressByPincode: Function;
  address: AddressFormFields;
}
const initialStates = {
  address: {} as AddressFormFields,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useGetAddressByPincode: StateCreator<
  GetAddressBasedOnPincodeSliceType
> = (set, get) => ({
  ...initialStates,
  fetchAddressByPincode: async (pincode: string) => {
    const token = storage.get(PSP_AUTH_TOKEN_KEY);
    axiosHttpClient.setAuthHeaders({
      [COMMON_AUTH_HEADER]: `Bearer ${LOCATION_SERVICE_TOKEN}`,
    });

    const remoteGetAddressBasedOnPincode: GetAddressBasedOnPincode =
      new RemoteGetAddressBasedOnPincode(
        `${LOCATION_SERVICE_BASE_URL}${Endpoints.GET_LOCATION}`,
        axiosHttpClient
      );

    let result = await remoteGetAddressBasedOnPincode.fetch(pincode);
    if (result.success) {
      set(() => ({
        address: result.data[0],
      }));
    }
  },
});
