import { useEffect } from "react";

interface Props {
  target: React.RefObject<Element>;
  onIntersect: () => void;
  enabled: boolean;
  rootMargin?: string;
  threshold?: number | number[];
}

const useIntersectionObserver = ({
  target,
  onIntersect,
  enabled,
  rootMargin = "0px",
  threshold = 1.0,
}: Props) => {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            onIntersect();
          }
        });
      },
      {
        rootMargin,
        threshold,
      }
    );

    const el = target?.current;
    if (!el) {
      return;
    }

    observer.observe(el);

    return () => {
      observer.unobserve(el);
    };
  }, [target, onIntersect, enabled, rootMargin, threshold]);
};

export default useIntersectionObserver;
