import { HttpConstants } from "../protocols/http/http-constants";
import { HttpGetClient } from "../protocols/http/http-get-client";
import { GetPatientDetails } from "../../domain/usages/get-patient-details";

export class RemoteGetPatientDetails implements GetPatientDetails {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async get(params: GetPatientDetails.Params): Promise<any> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(":id", params.id),
      query: {},
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (httpResponse.status == 200) {
      return httpResponse.data;
    }
  }
}
