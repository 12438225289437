import { StateCreator } from "zustand";
import { ADMIN_SECRET_KEY } from "../../../base";

interface BlurTextSliceType {
  handleTextBlur: Function;
  isBlurred: boolean;
  password: string;
  error: string;
  reset: Function
}

const initialStates = {
  password: "",
  isBlurred: true,
  error: "",
};

export const useBlurTextSlice: StateCreator<BlurTextSliceType> = (set) => ({
  ...initialStates,
  handleTextBlur: async (inputPassword: string) => {
    if (inputPassword == ADMIN_SECRET_KEY) {
      set(() => ({ isBlurred: false, error: "" }));
    } else {
      set(() => ({
        isBlurred: true,
        error:
          "Invalid password. Please ensure your password is correct and try again.",
      }));
    }
  },
  reset: ()=>{
    set(() => ({ isBlurred: true, error: "" }));
  }
});
