import { AddPatientAddresses } from "../../domain/usages/add-patient-address";
import { HttpConstants } from "../protocols/http/http-constants";
import { HttpPostClient } from "../protocols/http/http-post-client";

export class RemoteAddPatientAddresses implements AddPatientAddresses {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async add(patientId:string,  params: AddPatientAddresses.Params): Promise<any> {
    const httpResponse = await this.httpPostClient.post({
      url: this.url.replace(":patientId", patientId),
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
