import { StateCreator } from "zustand";
import { PSP_PROGRAM_SERVICE_API_URL } from "../../../base";
import { RemoteCreateDemoRequest } from "../../../data/usecases/remote-create-demo-request";
import Endpoints from "../../../domain/endpoints";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { CreateDemoPayload } from "../../../domain/models/demo-request";
import { useOrderDetailsStore } from "../orderStore/orderDetailsStore";
import Swal from "sweetalert2";
import { CreateDemoRequest } from "../../../domain/usages/create-demo-request";

interface CreateDemoRequestSliceType {
  createDemoRequest: (data: CreateDemoPayload) => void;
  showCreateDemo: boolean;
  toggleDemoModal: boolean;
}

const initialStates = {
  showCreateDemo: true,
  toggleDemoModal: false,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const remoteCreateDemoRequest: CreateDemoRequest = new RemoteCreateDemoRequest(
  `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.CREATE_DEMO_REQUEST}`,
  axiosHttpClient
);

export const useCreateDemoRequestSlice: StateCreator<
  CreateDemoRequestSliceType
> = (set, get) => ({
  ...initialStates,
  createDemoRequest: async (data: CreateDemoPayload) => {
    let payload = {
      assisted_by: data.assisted_by,
      assisted_by_mobile: data.assisted_by_mobile,
      enrolment_id: useOrderDetailsStore.getState().selectedOrder.enrolment_id,
    };
    let result = await remoteCreateDemoRequest.create(payload);
    if (result?.success) {
      set(() => ({
        showCreateDemo: false,
        toggleDemoModal: !get().toggleDemoModal,
      }));
      Swal.fire({ title: result?.message, icon: "success", timer: 4000 });
    } else {
      set(() => ({ toggleDemoModal: !get().toggleDemoModal }));
      Swal.fire({
        title: result?.errors?.enrolment_id,
        icon: "error",
        timer: 4000,
      });
    }
  },
});
