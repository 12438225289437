import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import ProgramDocs from "./program-documents/ProgramDocs";
import ProgramDetails from "./program-details/ProgramDetails";
import PersonalDetails from "./personal-details/PersonalDetails";
import { usePersonalDetailStore } from "../../../../store/adminPageStore/patientDetailsStore/personalDetailStore.ts/personalDetailsStore";
import { useProgramDetailStore } from "../../../../store/adminPageStore/patientDetailsStore/programDetailsStore/programDetailsStore";
import Orders from "./Orders";
import AppointmentModule from "./appointment-module/AppointmentModule";
import { useAppointmentDetailsStore } from "../../../../store/adminPageStore/patientDetailsStore/appointmentDetailsStore/appointmentDetailsStore";
import DomainName from "../../../../domain/domain";
import { getDomain } from "../../../../domain/Utils";
import { useEnrollmentMainStore } from "../../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";

interface PatientDetailsTabsInteface {
  activeTab: string;
  onTabChange: Function;
}

const PatientDetailsTabs: React.FC<PatientDetailsTabsInteface> = ({
  activeTab,
  onTabChange,
}) => {
  const { handleEdit } = useProgramDetailStore();
  const { selectedCard } = useEnrollmentMainStore();
  const { handleCloseEditPersonalDetails } = usePersonalDetailStore();
  const { handleAddAppointmentClick } = useAppointmentDetailsStore();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    onTabChange(newValue);
  };

  return (
    <div style={{ marginTop: "10px" }}>
      <TabContext value={activeTab}>
        <TabList
          onChange={handleChange}
          sx={{
            display: "flex",
            borderRadius: "45px",
            backgroundColor: "#F0F1FB",

            width:
              selectedCard.consent_acknowledgement == 1 ? "550px" : "450px",
            // width: "450px",

            justifyContent: "space-between",
            margin: "auto",
          }}
          indicatorColor="secondary"
          variant="scrollable"
          scrollButtons={false}
          TabIndicatorProps={{ style: { display: "none" } }}
        >
          <Tab
            onClick={() => handleCloseEditPersonalDetails()}
            label="Personal Details"
            value="1"
            sx={{
              textTransform: "unset !important",
              borderRadius: "45px",
              backgroundColor: activeTab == "1" ? "#3F51B7" : "",
              color:
                activeTab == "1" ? "#FFFFFF !important" : "#000000 !important",
            }}
          />
          <Tab
            onClick={() => handleEdit(0)}
            label="Program"
            value="2"
            sx={{
              textTransform: "unset !important",
              borderRadius: "45px",
              backgroundColor: activeTab == "2" ? "#3F51B7" : "",
              color:
                activeTab == "2" ? "#FFFFFF !important" : "#000000 !important",
            }}
          />
          <Tab
            label="Program Docs"
            value="3"
            sx={{
              textTransform: "unset !important",
              borderRadius: "45px",
              backgroundColor: activeTab == "3" ? "#3F51B7" : "",
              color:
                activeTab == "3" ? "#FFFFFF !important" : "#000000 !important",
            }}
          />
          <Tab
            label="Orders"
            value="4"
            sx={{
              textTransform: "unset !important",
              borderRadius: "45px",
              backgroundColor: activeTab == "4" ? "#3F51B7" : "",
              color:
                activeTab == "4" ? "#FFFFFF !important" : "#000000 !important",
            }}
          />

          {selectedCard.consent_acknowledgement == 1 &&
            getDomain() != DomainName.EISAI && (
              <Tab
                label="Appointments"
                onClick={() => {
                  handleAddAppointmentClick(true);
                }}
                value="5"
                sx={{
                  textTransform: "unset !important",
                  borderRadius: "45px",
                  backgroundColor: activeTab == "5" ? "#3F51B7" : "",
                  color:
                    activeTab == "5"
                      ? "#FFFFFF !important"
                      : "#000000 !important",
                }}
              />
            )}
        </TabList>
        <TabPanel value="1">
          <PersonalDetails />
        </TabPanel>
        <TabPanel value="2">
          <ProgramDetails />
        </TabPanel>
        <TabPanel value="3">
          <ProgramDocs />
        </TabPanel>
        <TabPanel value="4">
          <Orders />
        </TabPanel>

        {selectedCard.consent_acknowledgement == 1 &&
          getDomain() != DomainName.EISAI && (
            <TabPanel value="5">
              <AppointmentModule />
            </TabPanel>
          )}
      </TabContext>
    </div>
  );
};
export default PatientDetailsTabs;
