export const PSP_PROGRAM_SERVICE_API_URL =
  process.env.REACT_APP_PSP_PROGRAM_SERVICE_API_URL!;
export const AUTH_HEADER: string = process.env.REACT_APP_AUTH_HEADER!;
export const USER_LOGIN_API = process.env.REACT_APP_USER_LOGIN_API!;
export const ORDER_SERVICE = process.env.REACT_APP_COMMON_ORDER_SERVICE!;
export const AUTH_TOKEN_KEY: any = process.env.REACT_APP_TOKEN_KEY;
export const AUTH_USER_KEY: any = process.env.REACT_APP_ROLE_KEY;
export const COD_USER_SERVICE = process.env.REACT_APP_COD_USER_SERVICE!;
export const PSP_ORDER_SERVICE = process.env.REACT_APP_PSP_ORDER_SERVICE;
export const PSP_GENERATE_PDF = process.env.REACT_APP_GENERATE_PDF;
export const PSP_AUTH_TOKEN_KEY: any = process.env.REACT_APP_PSP_TOKEN_KEY;
export const USER_TOKEN_KEY:any=process.env.REACT_APP_USER_TOKEN_KEY;
export const PSP_FETCH_MASTER_API: any = process.env.REACT_APP_FETCH_MASTER_API;
export const COMMON_PATIENT_SERVICE_API_URL: any =
  process.env.REACT_APP_COMMON_PATIENT_SERVICE_API_URL;
export const COMMON_AUTH_HEADER: any = process.env.REACT_APP_COMMON_AUTH_HEADER;
export const LOCATION_SERVICE_BASE_URL =
  process.env.REACT_APP_LOCATION_SERVICE_BASE_URL;
export const LOCATION_SERVICE_TOKEN =
  process.env.REACT_APP_LOCATION_SERVICE_TOKEN;
export const PATIENT_TOKEN_KEY = process.env.REACT_APP_PATIENT_TOKEN_KEY!;
export const FULFILLMENT_SERVICE_TOKEN =
  process.env.REACT_APP_FULFILLMENT_SERVICE_TOKEN;
export const PRODUCT_SERVICE_API_URL: any =
  process.env.REACT_APP_PRODUCT_SERVICE_API_URL;
export const FULFILLMENT_SERVICE_API_URL: any =
  process.env.REACT_APP_FULFILLMENT_SERVICE_API_URL;
export const PRODUCT_SERVICE_TOKEN =
  process.env.REACT_APP_PRODUCT_SERVICE_TOKEN;
export const ORDER_SERVICE_TOKEN = process.env.REACT_APP_ORDER_SERVICE_TOKEN;
export const GOOGLE_RECAPTCHE_SITE_KEY =
  process.env.REACT_APP_GOOGLE_RECAPTCHE_SITE_KEY!;
export const GOOGLE_RECAPTCHE_URL = process.env.REACT_APP_GOOGLE_RECAPTCHE_URL!;
export const APPOINTMENT_SERVICE_STAGING_URL =
  process.env.REACT_APP_APPOINTMENT_API_URL;
  export const ADMIN_SECRET_KEY=process.env.REACT_APP_ADMIN_SECRET_KEY;
  export const ENCRYPTION_KEY=process.env.REACT_APP_ENCRYPTION_KEY!;
  export const CAPTCHA_HEADER=process.env.REACT_APP_CAPTCHA_HEADER!;
