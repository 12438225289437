import { StateCreator } from "zustand";
import {
  AUTH_TOKEN_KEY,
  COMMON_AUTH_HEADER,
  FULFILLMENT_SERVICE_API_URL,
  FULFILLMENT_SERVICE_TOKEN,
} from "../../../../base";
import { RemoteFetchSupplierList } from "../../../../data/usecases/remote-fetch-supplier-list";
import Endpoints from "../../../../domain/endpoints";
import { SupplierListType } from "../../../../domain/models/supplier-list-type";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { FetchSupplierList } from "../../../../domain/usages/fetch-supplier-list";

interface FetchSupplierSliceType {
  fetchSupplierOptions: Function;
  supplierListLoading: boolean;
  supplierList: SupplierListType[];
}

const initialStates = {
  supplierList: [],
  supplierListLoading: false,
};

const axiosHttpClient = AxiosHttpClient.getInstance();

export const useFetchSupplierSlice: StateCreator<FetchSupplierSliceType> = (
  set
) => ({
  ...initialStates,
  fetchSupplierOptions: async (params: FetchSupplierList.Params) => {
    axiosHttpClient.setAuthHeaders({
      [COMMON_AUTH_HEADER]: `${FULFILLMENT_SERVICE_TOKEN}`,
    });

    const remoteFetchSupplierList: FetchSupplierList =
      new RemoteFetchSupplierList(
        `${FULFILLMENT_SERVICE_API_URL}${Endpoints.FETCH_SUPPLIER_LIST}`,
        axiosHttpClient
      );
    set(() => ({ supplierListLoading: true }));
    let result = await remoteFetchSupplierList.fetch(params);
    if (result.success) {
      set(() => ({
        supplierList: result.suppliers.data,
        supplierListLoading: false,
      }));
    }
  },
});
