import { StateCreator } from "zustand";

import Swal from "sweetalert2";
import { FetchState } from "../../domain/models/fetch-state-type";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import Endpoints from "../../domain/endpoints";
import { RemoteConfirmFileUpload } from "../../data/usecases/fullfilment/remote-confirm-file-upload";
import { RemoteGenerateFileURL } from "../../data/usecases/fullfilment/remote-generate-url";
import { COMMON_AUTH_HEADER, FULFILLMENT_SERVICE_API_URL, FULFILLMENT_SERVICE_TOKEN } from "../../base";
import { RemoteUploadFileToBucket } from "../../data/usecases/fullfilment/remote-upload-to-bucket";
import { FileUpload } from "../../domain/models/fileUpload";


interface UploadFileInterface {
  generateUrl: Function;
  uploadToBucket: Function;
  ConfirmFileUpload: Function;
  fetchImageState: FetchState;
}

const initialStates = {
  fetchImageState: FetchState.DEFAULT,
};
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useUploadFileSlice: StateCreator<UploadFileInterface> = (set) => ({
  ...initialStates,
  generateUrl: async (fileUpload: FileUpload, category: string) => {
 
    axiosHttpClient.setAuthHeaders({
      [COMMON_AUTH_HEADER]: `${FULFILLMENT_SERVICE_TOKEN}`,
    });
    const remoteGenerateFileURL = new RemoteGenerateFileURL(
      `${FULFILLMENT_SERVICE_API_URL}${Endpoints.GENERATE_URL}`,
      axiosHttpClient
    );
    let payload = {
      name: fileUpload.file.name,
      size: fileUpload.file.size,
      type: fileUpload.file.type,
      document_type: category,
    };
    try {
      set(() => ({ fetchImageState: FetchState.LOADING }));
      let result = await remoteGenerateFileURL.generate(payload);
      if (result && result.data.uuid) {
        fileUpload.uuid = result.data.uuid;
        fileUpload.url = result.data.url;
        fileUpload.success = true;
        return fileUpload;
      } else {
        set(() => ({ fetchImageState: FetchState.ERROR }));
      }
    } catch (err) {
      set(() => ({ fetchImageState: FetchState.ERROR }));
    }
  },
  uploadToBucket: async (fileUpload: FileUpload) => {
    axiosHttpClient.setAuthHeaders({
      [COMMON_AUTH_HEADER]: `${FULFILLMENT_SERVICE_TOKEN}`,
    });
    const remoteUploadFileToBucket = new RemoteUploadFileToBucket(
      axiosHttpClient
    );
 
    try {
      set(() => ({ fetchImageState: FetchState.LOADING }));
      if (fileUpload.url) {
        let result = await remoteUploadFileToBucket.upload({
          file: fileUpload.file,
          url: fileUpload.url,
        });
        if (result) {
          fileUpload.success = true;
          return fileUpload;
        } else {
          set(() => ({ fetchImageState: FetchState.ERROR }));
        }
      }
    } catch (err) {
      set(() => ({ fetchImageState: FetchState.ERROR }));
    }
  },
  ConfirmFileUpload: async (fileUpload: FileUpload) => {
    axiosHttpClient.setAuthHeaders({
      [COMMON_AUTH_HEADER]: `${FULFILLMENT_SERVICE_TOKEN}`,
    });
    const remoteConfirmFileUpload = new RemoteConfirmFileUpload(
      `${FULFILLMENT_SERVICE_API_URL}${Endpoints.GENERATE_URL}`,
      axiosHttpClient
    );
    try {
      set(() => ({ fetchImageState: FetchState.LOADING }));
      if (fileUpload.uuid) {
        let result = await remoteConfirmFileUpload.confirm({
          uuid: fileUpload.uuid,
        });
        if (result) {
          if (result.success) {
            fileUpload.success = true;
            fileUpload.uuid = result.data.uuid;
            return fileUpload;
          } else {
            set(() => ({ fetchImageState: FetchState.ERROR }));
          }
        } else {
          set(() => ({ fetchImageState: FetchState.ERROR }));
        }
      }
    } catch (err) {
      set(() => ({ fetchImageState: FetchState.ERROR }));
    }
  },
});
