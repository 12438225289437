import { HttpConstants } from "../protocols/http/http-constants";
import { HttpGetClient } from "../protocols/http/http-get-client";
import { FetchPatientOrder } from "../../domain/usages/fetch-order-by-id";
import { FetchSubOrderDetails } from "../../domain/usages/fetch-suborder-details";

export class RemoteFetchSubOrderDetails implements FetchSubOrderDetails {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async fetch(
    orderId: string,
    subOrderId: string,
    params?: FetchSubOrderDetails.Params
  ): Promise<any> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url
        .replace(":orderId", orderId)
        .replace(":subOrderId", subOrderId),
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (httpResponse.status == 200) {
      return httpResponse.data;
    }
  }
}
