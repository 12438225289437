import { useEffect } from "react";
import { Grid, InputLabel, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Status } from "../../../../../constants/enums/status";
import { ActionButton } from "../../../../../custom-theme/ActionButton";
import { DeliverOrderResponse } from "../../../../../domain/models/deliver-order";
import { useOrderDetailsStore } from "../../../../../store/stockistPageStore/orderStore/orderDetailsStore";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Constant } from "../../../../../constants/constant";
import dayjs from "dayjs";

const DeliveryDetails = () => {
  const { selectedOrder, handleDeliverOrder } = useOrderDetailsStore();
  const { handleSubmit, control, setValue } = useForm<DeliverOrderResponse>();
  const handleClick = () => {
    handleSubmit(handleDeliverOrder)();
  };

  useEffect(() => {
    setValue(
      "delivery_date",
      selectedOrder?.delivery_date ? dayjs(selectedOrder?.delivery_date) : null
    );
    setValue(
      "received_by",
      selectedOrder?.received_by ? selectedOrder?.received_by : ""
    );
    setValue(
      "received_by_mobile",
      selectedOrder?.received_by_mobile ? selectedOrder?.received_by_mobile : ""
    );
  }, [selectedOrder.id]);

  return (
    <>
      {(selectedOrder.status === Status.DISPATCHED ||
        selectedOrder.status === Status.DELIVERED) && (
        <Grid
          justifyContent={"space-between"}
          container
          direction={"row"}
          gap={2}
        >
          <Grid item xs={12}>
            <Typography variant={"body1"}>Delivery Details:</Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={3}>
            <InputLabel>Receiver:</InputLabel>
            <Controller
              name="received_by"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  defaultValue={selectedOrder?.received_by}
                  size="small"
                  error={error && true}
                  helperText={error?.message}
                  onChange={onChange}
                  disabled={selectedOrder.status === Status.DELIVERED}
                />
              )}
              rules={{
                required: { value: true, message: Constant.FIELD_REQUIRED },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <InputLabel>Receiver Mobile Number:</InputLabel>
            <Controller
              name="received_by_mobile"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  value={value}
                  defaultValue={selectedOrder?.received_by_mobile}
                  error={error && true}
                  helperText={error?.message}
                  size="small"
                  onChange={onChange}
                  disabled={selectedOrder.status === Status.DELIVERED}
                />
              )}
              rules={{
                required: { value: true, message: Constant.FIELD_REQUIRED },
                pattern: {
                  value: Constant.CORRECT_MOBILE.VALUE,
                  message: Constant.CORRECT_MOBILE.MESSAGE,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <InputLabel>Delivery Date:</InputLabel>
            <Controller
              name="delivery_date"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    minDate={dayjs(selectedOrder.dispatch_date)}
                    format="DD/MM/YYYY"
                    disabled={selectedOrder.status === Status.DELIVERED}
                    value={value}
                    onChange={onChange}
                  />
                </LocalizationProvider>
              )}
              rules={{
                required: { value: true, message: Constant.FIELD_REQUIRED },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {selectedOrder.status === Status.DISPATCHED && (
              <ActionButton fullWidth onClick={handleClick}>
                <Typography textTransform={"capitalize"}>
                  Mark As Delivered
                </Typography>
              </ActionButton>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default DeliveryDetails;
