import {
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
  PSP_PROGRAM_SERVICE_API_URL,
} from "../base";
import { RemoteConfirmDocumentUpload } from "../data/usecases/remote-confirm-document-upload";
import { RemoteGenerateSignedUrlForDocumentUpload } from "../data/usecases/remote-generate-signed-url-for-document-upload";
import { RemoteUploadFile } from "../data/usecases/remote-upload-file";
import Endpoints from "../domain/endpoints";
import { AxiosHttpClient } from "../infra/http/axios-http-client";
import { LocalJsonStorage } from "../infra/http/local-json-storage";
import ProgramEnrolmentPage from "../presentation/pages/ProgramEnrolmentPage";

type Props = {};

export const ProgramEnrolmentFactory: React.FC<Props> = ({}) => {
  const storage = LocalJsonStorage.getInstance();
  const axiosHttpClient = AxiosHttpClient.getInstance();

  const token = storage.get(PSP_AUTH_TOKEN_KEY);
  axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: atob(token) });

  const remoteGenerateSignedUrlForDocumentUpload =
    new RemoteGenerateSignedUrlForDocumentUpload(
      `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.GENERATE_SIGNED_URL_FOR_DOCUMENT_UPLOAD}`,
      axiosHttpClient
    );

  const remoteUploadFile = new RemoteUploadFile(axiosHttpClient);

  const remoteConfirmFileUpload = new RemoteConfirmDocumentUpload(
    `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.CONFIRM_DOCUMENT_UPLOAD}`,
    axiosHttpClient
  );

  return (
    <ProgramEnrolmentPage
      generateUploadURL={remoteGenerateSignedUrlForDocumentUpload}
      uploadFile={remoteUploadFile}
      confirmFileUpload={remoteConfirmFileUpload}
    />
  );
};
