import { Controller, useForm } from "react-hook-form";
import { Box, Divider, Modal, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import DoneIcon from "@mui/icons-material/Done";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import "../patient-information/OncologyPageStyles.css";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import { useEnrollmentMainStore } from "../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import { useSendToCFAStore } from "../../../store/adminPageStore/patientDetailsStore/sendToCFAStore/sendToCFAStore";
import { DesktopDatePicker } from "@mui/x-date-pickers";

type FilterFeatures = {
  selectStatus: string;
  selectMonth: string;
  selectProducts: string;
};

const style = {
  position: "absolute" as "absolute",
  top: "15%",
  left: "17%",
  width: "18%",
  bgcolor: "background.paper",
  borderRadius: "22px",
  boxShadow: 24,
  p: 4,
};

const FilterComponent: React.FC = () => {
  const { filter, handleAppliedFilters, filterState, handleFilterClose } =
    useEnrollmentMainStore();
  const { productOptions } = useSendToCFAStore();
  const { handleSubmit, control, setValue } = useForm<FilterFeatures>({
    mode: "onChange",
  });

  const [date, setDate] = React.useState<Dayjs | null>(null);
  const [toDate, setToDate] = useState<Dayjs | null>(
    filter.endDate ? dayjs(filter.endDate) : null
  );
  const [fromDate, setFromDate] = useState<Dayjs | null>(
    filter.startDate ? dayjs(filter.startDate) : null
  );

  const handleCloseModal = () => {
    setValue("selectStatus", "");
    setValue("selectMonth", "");
    setValue("selectProducts", "");
    handleFilterClose();
  };

  const handleSearch = () => {
    filter.startDate = fromDate
      ? moment((fromDate as Dayjs).toString()).format("YYYY-MM-DD")
      : "";
    filter.endDate = toDate
      ? moment((toDate as Dayjs).toString()).format("YYYY-MM-DD")
      : "";
    handleAppliedFilters(filter);
    handleCloseModal();
  };

  const handleReset = () => {
    setFromDate(null);
    setToDate(null);
    filter.startDate = "";
    filter.endDate = "";
    handleAppliedFilters(filter);
    handleCloseModal();
  };
  return (
    <Modal open={filterState} onClose={() => handleCloseModal()}>
      <Box sx={style}>
        <Stack direction="row" justifyContent="space-between" marginBottom={2}>
          <Typography variant="caption" fontSize={20}>
            Filter
          </Typography>
          <CloseIcon color="action" onClick={() => handleCloseModal()} />
        </Stack>

        <Stack marginY={2} gap={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Enter From Date"
              disableFuture
              value={fromDate}
              onChange={(newDate) => {
                setFromDate(newDate);
              }}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Enter To Date"
              disableFuture
              minDate={fromDate ? fromDate : undefined}
              disabled={fromDate == null}
              value={toDate}
              onChange={(newDate) => {
                setToDate(newDate);
              }}
              format="DD/MM/YYYY"
            />
          </LocalizationProvider>
        </Stack>

        <Stack
          direction={"row"}
          spacing={2}
          justifyContent="center"
          marginTop={2}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit(handleSearch)}
          >
            APPLY FILTER
          </Button>
          <Button color="secondary" onClick={() => handleReset()}>
            RESET
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
export default FilterComponent;
