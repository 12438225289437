import {
  Card,
  CardHeader,
  Chip,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useOrderDetailsStore } from "../../../../../store/stockistPageStore/orderStore/orderDetailsStore";

const OrderReceiverDetails: React.FC = () => {
  const { selectedOrder } = useOrderDetailsStore();

  const handleAddress = () => {
    const filteredAddress = Object.entries(selectedOrder.address).reduce(
      (acc: any, [key, value]) => {
        if (
          value !== "" &&
          value !== null &&
          (key === "line" ||
            key === "landmark" ||
            key === "zip_code" ||
            key === "district" ||
            key === "state" ||
            key === "country")
        ) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );
    return Object.values(filteredAddress)
      .map((value) => `${value}`)
      .join(", ");
  };

  return (
    <CardContent>
      <Card sx={{ borderRadius: "12px" }} variant="outlined">
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <CardHeader
            title={
              <Typography fontWeight={550} variant="subtitle1">
                Order For {selectedOrder.order_for}
              </Typography>
            }
            subheader={
              selectedOrder.address && (
                <Typography textTransform={"capitalize"} variant="subtitle2">
                  {handleAddress()}
                </Typography>
              )
            }
          />
          <Stack marginRight={3}>
            <Chip
              color="secondary"
              label={
                <Typography
                  fontWeight={550}
                  color={"secondary"}
                  variant="subtitle2"
                  textTransform={"capitalize"}
                >
                  {selectedOrder?.status}
                </Typography>
              }
              variant="outlined"
            />
          </Stack>
        </Stack>
      </Card>
    </CardContent>
  );
};
export default OrderReceiverDetails;
