import { InputAdornment, Stack, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";
import { useOrderDetailsStore } from "../../../store/stockistPageStore/orderStore/orderDetailsStore";

const OrderFilter = () => {
  const { handleAppliedFilter, orderFilter } = useOrderDetailsStore();

  const handleSearch = (e: any) => {
    orderFilter.q = e.target.value;
    handleAppliedFilter(orderFilter);
  };

  const fieldChange = debounce(handleSearch, 2000);
  return (
    <Stack bgcolor={"white"}>
      <TextField
        onChange={fieldChange}
        size="small"
        sx={{
          "&.MuiOutlinedInput-root": {
            borderRadius: "8px",
            height: "25px",
          },
          width: "100%",
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{<SearchIcon />}</InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};
export default OrderFilter;
