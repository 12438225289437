import { HttpConstants } from "../protocols/http/http-constants";
import { HttpPostClient } from "../protocols/http/http-post-client";
import { SendConsentSMS } from "../../domain/usages/send-consent-sms";

export class RemoteSendConsentSMS implements SendConsentSMS {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async send(id: string, params: SendConsentSMS.Params): Promise<any> {
    const httpResponse = await this.httpPostClient.post({
      url: this.url.replace(":enrolmentId", id),
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

    return httpResponse.data;
  }
}
