import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import {
  COMMON_AUTH_HEADER,
  COMMON_PATIENT_SERVICE_API_URL,
  PATIENT_TOKEN_KEY,
} from "../../../../base";
import { RemoteUpdatePatientDetails } from "../../../../data/usecases/remote-update-patient-details";
import Endpoints from "../../../../domain/endpoints";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { usePersonalDetailStore } from "./personalDetailsStore";
import { LocalJsonStorage } from "../../../../infra/http/local-json-storage";
import { UpdatePatientDetails } from "../../../../domain/usages/update-patient-details";
import { Caretaker } from "../../../../domain/models/personal-details-type";
import EditableTypes from "../../../../domain/enum";
import { useEnrollmentMainStore } from "../../enrollmentListStore/enrollmentMainStore";
import { LocalLoggedInUser } from "../../../../data/usecases/local-logged-in-user";

interface UpdatePatientDetailSliceType {
  editDetails: boolean;
  editPersonalDetails: Function;
  reloadPersonalDetails: Function;
  handleEditPersonalDetails: Function;
  handleCloseEditPersonalDetails: Function;
  handleCloseUpdateStatusForm: Function;
  handleOpenUpdateForm: Function;
  toggleUpdateStatus: boolean;
  caretakerDetails: Caretaker[];
  selectedToEdit: EditableTypes | null;
}
const initialStates = {
  editDetails: false,
  toggleUpdateStatus: false,
  caretakerDetails: [],
  selectedToEdit: null,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useUpdatePatientDetailSLice: StateCreator<
  UpdatePatientDetailSliceType
> = (set, get) => ({
  ...initialStates,
  editPersonalDetails: async (id: string, payload: UpdatePatientDetails.Params, isConsent: boolean) => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getPatientToken();
    axiosHttpClient.setAuthHeaders({ [COMMON_AUTH_HEADER]: `Bearer ${(token)}` });

    const remoteUpdatePatientDetails: UpdatePatientDetails =
      new RemoteUpdatePatientDetails(
        `${COMMON_PATIENT_SERVICE_API_URL}${Endpoints.UPDATE_BASIC_PATIENT_DETAILS}`,
        axiosHttpClient
      );
    usePersonalDetailStore.getState().handleLoading(true);
    let result = await remoteUpdatePatientDetails.update(id, payload);
    if (result.success) {
      usePersonalDetailStore.getState().handleLoading(false);
      if(!isConsent){
        get().reloadPersonalDetails();
        Swal.fire("Updated successfully.", "", "success");
      }
    
     
    } else {
      usePersonalDetailStore.getState().handleLoading(false);

      if (result.errors && result.errors.length > 0) {
        Swal.fire(result.errors[0].message, "", "error");
      } else {
        Swal.fire(result.message, "", "error");
      }
    }
  },
  reloadPersonalDetails: () => {
    usePersonalDetailStore
      .getState()
      .getPatient(useEnrollmentMainStore.getState().selectedCard.patient_uuid);

    set(() => ({ editDetails: !get().editDetails, selectedToEdit: null }));
  },
  handleEditPersonalDetails: (selectedValue: EditableTypes | null) => {
    set(() => ({
      editDetails: !get().editDetails,
      selectedToEdit: selectedValue,
    }));
  },
  handleCloseUpdateStatusForm: () => {
    set(() => ({ toggleUpdateStatus: false }));
  },
  handleOpenUpdateForm: () => {
    set(() => ({ toggleUpdateStatus: true }));
  },
  handleCloseEditPersonalDetails: () => {
    set(() => ({ editDetails: false }));
  },
});
