import { CancelAppointmentResponse } from "../../../domain/models/appointment-module/cancel-appointment-response";
import { ConfirmAppointment } from "../../../domain/usages/appointment-module/confirm-appointment";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpPutClient } from "../../protocols/http/http-put-client";

export class RemoteConfirmAppointment implements ConfirmAppointment {
  constructor(
    private readonly url: string,
    private readonly httpPutClient: HttpPutClient
  ) {}
  async confirm(
    appointment_id: number,
    params: ConfirmAppointment.Params
  ): Promise<CancelAppointmentResponse> {
    const httpResponse = await this.httpPutClient.put({
      url: this.url.replace(":appointment_id", appointment_id.toString()),
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

    return httpResponse.data;
  }
}
