import { StateCreator } from "zustand";
import { PSP_PROGRAM_SERVICE_API_URL } from "../../../base";
import { RemoteGetDemoRequest } from "../../../data/usecases/remote-get-demo-request";
import Endpoints from "../../../domain/endpoints";
import { DemoData } from "../../../domain/models/demo-request";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { Constant } from "../../../constants/constant";
import { GetDemoRequest } from "../../../domain/usages/get-demo-request";

interface FetchDemoRequestSliceType {
  demoRequestData: DemoData[];
  getDemoRequest: Function;
  totalPage: number;
  presentPage: number;
}

const initialStates = {
  demoRequestData: [],
  totalPage: 1,
  presentPage: 0,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const remoteGetDemoRequest: GetDemoRequest = new RemoteGetDemoRequest(
  `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.GET_DEMO_REQUEST}`,
  axiosHttpClient
);

export const useFetchDemoRequestSlice: StateCreator<
  FetchDemoRequestSliceType
> = (set, get) => ({
  ...initialStates,
  getDemoRequest: async (page: number) => {
    let params = {
      length: Constant.PAGE_LIMIT.toString(),
    };
    let result = await remoteGetDemoRequest.get(page, params);
    if (result?.success) {
      set(() => ({
        demoRequestData: result.demo_requests,
        totalPage: Math.ceil(
          get().demoRequestData.length / Constant.PAGE_LIMIT
        ),
        presentPage: page,
      }));
    }
  },
});
