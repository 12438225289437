import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { ChangeEventHandler } from "react";

type Props = {
  value: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  error?: boolean;
  errorMessage?: string;
  minRows?: number;
  maxRows?: number;
  placeholder: string;
};

const Textarea = styled(BaseTextareaAutosize)`
  box-sizing: border-box;
  width: 100%;
  font-family: "DM Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 12px 12px 0 12px;
`;

const GaTextArea: React.FC<Props> = ({
  value,
  onChange,
  error,
  errorMessage,
  minRows = 3,
  placeholder,
  maxRows = 6,
}) => {
  return (
    <>
      <Textarea
        minRows={minRows}
        maxRows={maxRows}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      {error && (
        <Typography variant="caption" color="error.main">
          {errorMessage}
        </Typography>
      )}
    </>
  );
};

export default GaTextArea;
