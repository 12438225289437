import { Button, ButtonProps, styled } from "@mui/material";

export const ActionButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "white !important",
  backgroundColor: theme.palette.primary.dark,
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
  borderRadius: "30px",
  ":disabled": { backgroundColor: "lightgrey" },
}));
