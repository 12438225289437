import {
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { FollowupThreadType } from "../../../../../../domain/models/followup-thread-type";
import moment from "moment";
import { toTitleCase } from "../../../../../../domain/Utils";

type Props = {
  handleViewHistory: Function;
  followupThread: FollowupThreadType;
  handleOpenConfirmModal: Function;
};

const ViewFollowUpThread: React.FC<Props> = ({
  handleViewHistory,
  followupThread,
  handleOpenConfirmModal,
}) => {
  return (
    <Card variant="outlined" sx={{ borderRadius: "24px" }}>
      <CardHeader
        title={
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            marginBottom={1}
          >
            <Typography variant="subtitle2" fontWeight={660}>
              {toTitleCase(followupThread.title)}
            </Typography>
            <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
              {followupThread.status != "closed" && (
                <IconButton
                  sx={{ border: "1px solid lightGrey", borderRadius: 2 }}
                  onClick={() =>
                    handleOpenConfirmModal(followupThread.id, true)
                  }
                >
                  <CancelRoundedIcon fontSize="small" color="error" />
                </IconButton>
              )}
              {followupThread.status == "closed" && (
                <Typography
                  variant="caption"
                  color={"error.main"}
                  fontWeight={660}
                >
                  Closed
                </Typography>
              )}
            </Stack>
          </Stack>
        }
        subheader={
          <Stack>
            <Divider />
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              marginTop={1}
            >
              <Stack>
                <Typography variant="caption">
                  Name:{" "}
                  {toTitleCase(followupThread.enrolment.patient_full_name)}
                </Typography>
                <Typography variant="caption">
                  Code: {followupThread.enrolment.patient_code}
                </Typography>
                <Typography variant="caption">
                  Created Date: {moment(followupThread.created_at).format("ll")}
                </Typography>
              </Stack>

              <Button
                onClick={() => handleViewHistory(true, followupThread.id)}
              >
                <Stack flexDirection={"row"} alignItems={"center"} gap={0.5}>
                  <Typography
                    variant="caption"
                    textTransform={"none"}
                    fontWeight={660}
                  >
                    View Details
                  </Typography>
                  <Stack
                    border={"1px solid lightGrey"}
                    borderRadius={"50%"}
                    padding={0.5}
                    alignItems="center"
                    justifyContent="center"
                    width={15}
                    height={15}
                  >
                    <Typography variant="caption">
                      {followupThread.follow_ups.length}
                    </Typography>
                  </Stack>
                  <ArrowForwardIosRoundedIcon
                    sx={{ fontSize: "10px" }}
                    color="primary"
                  />
                </Stack>
              </Button>
            </Stack>
          </Stack>
        }
      />
    </Card>
  );
};

export default ViewFollowUpThread;
