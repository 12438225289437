import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
  PSP_PROGRAM_SERVICE_API_URL,
} from "../../../base";
import { RemoteCreateNewEnrollment } from "../../../data/usecases/remote-create-new-enrollment";
import Endpoints from "../../../domain/endpoints";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { useEnrollmentMainStore } from "./enrollmentMainStore";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { CreateNewEnrollment } from "../../../domain/usages/create-new-enrollment";
import { LocalLoggedInUser } from "../../../data/usecases/local-logged-in-user";

interface CreateEnrollmentSliceType {
  createEnrollment: (data: CreateNewEnrollment.Params) => void;
  handleCloseEnrollmentModal: Function;
  addNewEnrollment: boolean;
  handleAddNewEnrollment: Function;
  handleAddNewEnrollmentLoading: Function;
}

const initialStates = {
  addNewEnrollment: false,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useCreateEnrollmentSlice: StateCreator<
  CreateEnrollmentSliceType
> = (set, get) => ({
  ...initialStates,
  createEnrollment: async (payload: CreateNewEnrollment.Params) => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getPSPToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token });
    const remoteCreateNewEnrollment: CreateNewEnrollment =
      new RemoteCreateNewEnrollment(
        `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.CREATE_ENROLLMENT}`,
        axiosHttpClient
      );

    get().handleCloseEnrollmentModal();
    get().handleAddNewEnrollmentLoading(true);
    let result = await remoteCreateNewEnrollment.create(payload);
    if (result.success) {
      get().handleAddNewEnrollmentLoading(false);
      useEnrollmentMainStore
        .getState()
        .fetchEnrollments(1, useEnrollmentMainStore.getState().filter, false);
      Swal.fire("Enrollment created successfully.", "", "success");
    } else {
     
      Swal.fire("Enrollment creation failed.", "", "error");
    }
  },
  handleAddNewEnrollmentLoading: (value: boolean) => {
    if (value) {
      Swal.fire({
        title: "Response Loading...",
        didOpen: () => {
          Swal.showLoading();
        },
      });
    }
  },
  handleAddNewEnrollment: () => {
    set(() => ({ addNewEnrollment: true }));
  },
  handleCloseEnrollmentModal: () => {
    set(() => ({ addNewEnrollment: false }));
  },
});
