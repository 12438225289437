import React, { useState } from "react";
import { Divider } from "@mui/material";

type Props = {
  imageLink: string;
};

const ImagePreview: React.FC<Props> = ({ imageLink = "" }) => {
  const [flip, setFlip] = useState(false);
  const [rotateImage, setRotateImage] = useState(0);

  const rotate = () => {
    if (rotateImage < 360) {
      setRotateImage(rotateImage + 90);
    } else {
      setRotateImage(90);
    }
  };

  return (
    <>
      <div
        style={
          flip
            ? { transform: "scaleX(-1)" }
            : {
                transform: "scaleX(1)",
                display: "flex",
                justifyContent: "center",
              }
        }
      >
        <img
          style={{
            transform: `rotate(${rotateImage}deg)`,
            width: "100%",
            height: "100%",
          }}
          src={imageLink}
          alt=""
        ></img>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            backgroundColor: "white",
            justifyContent: "space-around",
            border: "1px solid gray",
            marginTop: 10,
            borderRadius: "5px",
          }}
        >
          <div
            style={{ padding: 2, cursor: "pointer" }}
            onClick={() => {
              setFlip(!flip);
            }}
          >
            <span>FLIP IMAGE</span>
          </div>
          <Divider orientation="vertical" flexItem />
          <div style={{ padding: 2, cursor: "pointer" }} onClick={rotate}>
            <span>ROTATE IMAGE</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImagePreview;
