import { FetchUsersResponse } from "../../../domain/models/appointment-module/fetch-users-response";
import { FetchDoctorsForAppointment } from "../../../domain/usages/appointment-module/fetch-doctor";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpGetClient } from "../../protocols/http/http-get-client";

export class RemoteFetchDoctorsForAppointment
  implements FetchDoctorsForAppointment
{
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async fetch(
    params: FetchDoctorsForAppointment.Params
  ): Promise<FetchUsersResponse> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url,
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

    return httpResponse.data;
  }
}
