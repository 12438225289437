import { UpdatePatientDetails } from "../../domain/usages/update-patient-details";
import { HttpConstants } from "../protocols/http/http-constants";
import { HttpPutClient } from "../protocols/http/http-put-client";

export class RemoteUpdatePatientDetails implements UpdatePatientDetails {
  constructor(
    private readonly url: string,
    private readonly httpPutClient: HttpPutClient
  ) {}

  async update(id:string, params: UpdatePatientDetails.Params): Promise<any> {
    const httpResponse = await this.httpPutClient.put({
      url: this.url.replace(":id", id),
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
