import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../../infra/http/local-json-storage";
import { AUTH_HEADER, PSP_PROGRAM_SERVICE_API_URL } from "../../../../base";
import { LocalLoggedInUser } from "../../../../data/usecases/local-logged-in-user";
import { RemoteStoreEnrolmentDocument } from "../../../../data/usecases/remote-store-enrolment-document";
import Endpoints from "../../../../domain/endpoints";

interface StoreEnrolmentDocumentSliceType {
  storeDocument: Function;
}

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useStoreEnrolmentDocumnetSlice: StateCreator<
  StoreEnrolmentDocumentSliceType
> = () => ({
  storeDocument: async (
    id: number,
    docUuid: string,
    docType: string,
    docGroup: string
  ) => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getPSPToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token });

    const remoteStoreEnrolmentDocument = new RemoteStoreEnrolmentDocument(
      `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.STORE_ENROLMENT_DOCUMENT}`,
      axiosHttpClient
    );

    let payload = {
      type: docType,
      group: docGroup,
      uuid: docUuid,
    };

    let result = await remoteStoreEnrolmentDocument.store(id, payload);
    if (result.success) {
      return result
    } else {
      throw new Error("Couldn't upload document.  please try again");
    }
  },
});
