import { FetchCalendarCountResponse } from "../../../domain/models/appointment-module/fetch-calendar-count-response";
import { FetchCalendarCount } from "../../../domain/usages/appointment-module/fetch-calendar-count";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpGetClient } from "../../protocols/http/http-get-client";

export class RemoteFetchCalendarCount implements FetchCalendarCount {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async fetch(
    params: FetchCalendarCount.Params
  ): Promise<FetchCalendarCountResponse> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url,
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

    return httpResponse.data;
  }
}
