import { StateCreator } from "zustand";
import {
  COMMON_AUTH_HEADER,
  ORDER_SERVICE,
  ORDER_SERVICE_TOKEN,
  PSP_AUTH_TOKEN_KEY,
} from "../../../base";
import { RemoteGetOrders } from "../../../data/usecases/remote-get-orders";
import Endpoints from "../../../domain/endpoints";
import { EnrollmentFilterType } from "../../../domain/models/enrollment-filter.type";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { Order } from "../../../domain/models/get-order-type";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { useOrderDetailsStore } from "./orderDetailsStore";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { Constant } from "../../../constants/constant";
import { GetOrders } from "../../../domain/usages/get-orders";
import { PatientOrderType } from "../../../domain/models/patient-order-type";

interface FetchPatientOrderSliceType {
  fetchPatientOrders: Function;
  patientOrderList: PatientOrderType[];
  selectedPatientOrder: PatientOrderType;
  fetchPatientOrderState: FetchState;
  handleCardClick: Function;
  reloadPage: Function;
  totalOrder: number;
  currentPage: number;
  lastPage: number;
  handleSelectedCard: Function;
}
const initialStates = {
  totalOrder: 0,
  fetchPatientOrderState: FetchState.DEFAULT,
  selectedPatientOrder: {} as PatientOrderType,
  patientOrderList: [],
  orderFilter: [],
  currentPage: 0,
  lastPage: 1,
};
const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useFetchPatientOrderSlice: StateCreator<
  FetchPatientOrderSliceType
> = (set, get) => ({
  ...initialStates,
  fetchPatientOrders: async (
    page: number,
    orderFilter: EnrollmentFilterType,
    customerUuid: string,
    scrolled: boolean
  ) => {
    axiosHttpClient.setAuthHeaders({
      [COMMON_AUTH_HEADER]: `${ORDER_SERVICE_TOKEN}`,
    });

    const remoteGetOrders: GetOrders = new RemoteGetOrders(
      `${ORDER_SERVICE}${Endpoints.FETCH_ORDERS}`,
      axiosHttpClient
    );
    let filter = {
      q: orderFilter.q,
      length: Constant.PAGE_LIMIT,
      customer_uuid: customerUuid,
      app_code: "PSP",
      order_by: "DESC",
    };

    try {
      if (!scrolled) {
        set(() => ({ fetchPatientOrderState: FetchState.LOADING }));
      }
      let result = await remoteGetOrders.get(page, filter);
      if (result.success) {
        set(() => ({
          fetchPatientOrderState: FetchState.SUCCESS,
          currentPage: page,
          lastPage: Math.ceil(result.total / Constant.PAGE_LIMIT),
        }));
        if (scrolled) {
          set(() => ({
            patientOrderList: [
              ...get().patientOrderList,
              ...result.orders[0].data.orders,
            ],
          }));
        } else {
          set(() => ({
            patientOrderList: result.orders[0].data.orders,
          }));
          get().handleSelectedCard(result.orders[0].data.orders);
        }
      } else {
        set(() => ({ fetchPatientOrderState: FetchState.LOADING }));
      }
    } catch (error) {
      set(() => ({ fetchPatientOrderState: FetchState.ERROR }));
    }
  },

  handleSelectedCard: (orders: Order[]) => {
    if (get().selectedPatientOrder.id) {
      const foundOrder = orders.find(
        (order: Order) => order?.id === get().selectedPatientOrder?.id
      );
    } else {
      const sortedOrderList = orders.sort((a, b) => (a.id < b.id ? 1 : -1));
    }
  },

  handleCardClick: (index: number) => {
    set(() => ({ selectedPatientOrder: get().patientOrderList[index] }));
  },
  reloadPage: () => {
    get().fetchPatientOrders(1, useOrderDetailsStore.getState().orderFilter);
  },
});
