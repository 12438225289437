import { create } from "zustand";
import { useCreateDemoRequestSlice } from "./createDemoRequestSlice";
import { useFetchDemoRequestSlice } from "./fetchDemoRequestSlice";

interface DemoRequestStore
  extends ReturnType<typeof useFetchDemoRequestSlice>,
    ReturnType<typeof useCreateDemoRequestSlice> {}

export const useDemoRequestStore = create<DemoRequestStore>((...demo) => ({
  ...useFetchDemoRequestSlice(...demo),
  ...useCreateDemoRequestSlice(...demo),
}));
