import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";

import { useForm, Controller } from "react-hook-form";

import { AddressFormFields } from "../../../../../domain/models/addressFormFields";
import { PersonalDetailsType } from "../../../../../domain/models/personal-details-type";
import { usePersonalDetailStore } from "../../../../../store/adminPageStore/patientDetailsStore/personalDetailStore.ts/personalDetailsStore";

type Props = {
  open: boolean;
  handleClose: Function;
  addressType: string;
  selectedPatientPersonalDetails: PersonalDetailsType;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const EditAddressDetailsModal: React.FC<Props> = ({
  open,
  handleClose,
  selectedPatientPersonalDetails,
  addressType,
}) => {
  const { handleSubmit, control, setValue, watch } = useForm<AddressFormFields>(
    {
      mode: "onChange",
    }
  );

  const { fetchAddressByPincode, address, addAddress } =
    usePersonalDetailStore();

  let regexPincode = /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/;
  useEffect(() => {
    setValue("type", addressType ? addressType : "");
  }, [open]);

  useEffect(() => {
    if (address && watch("pincode")) {
      setValue("district", address.district);
      setValue("state", address.state);
      setValue("city", address.city);
      setValue("country", address.country);
    } else {
      setValue("district", "");
      setValue("state", "");
      setValue("city", "");
      setValue("country", "");
    }
  }, [address]);

  const handleAddAddress = (data: AddressFormFields) => {
    let payload = {
      addresses: [
        {
          type: data.type,
          line: data.line,
          landmark: data.landmark,
          area: data.area,
          district: data.district,
          city: data.city,
          state: data.state,
          pincode: data.pincode,
        },
      ],
    };
    addAddress(selectedPatientPersonalDetails._id, payload, handleClose, false);
  };

  return (
    <Modal open={open} onClose={() => handleClose()}>
      <Box sx={style}>
        <Typography fontWeight={660} variant="body1">
          Address:
        </Typography>
        <Stack spacing={2} marginTop={2}>
          <Controller
            name="type"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl fullWidth size="small">
                <InputLabel>Address Type</InputLabel>
                <Select
                  label="Address Type"
                  value={value}
                  disabled={addressType != ""}
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  error={error ? true : false}
                >
                  <MenuItem value={"primary"}>Primary</MenuItem>
                  <MenuItem value={"secondary"}>Secondary</MenuItem>
                </Select>
              </FormControl>
            )}
            rules={{
              required: { value: true, message: "Field Required" },
            }}
          />
          <Controller
            name="line"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                size="small"
                label="Line"
                placeholder="Enter line..."
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error?.message}
              />
            )}
            rules={{
              required: { value: true, message: "Field Required" },
            }}
          />
          <Controller
            name="landmark"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                size="small"
                label="Landmark"
                placeholder="Enter landmark..."
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error?.message}
              />
            )}
            rules={{
              required: { value: true, message: "Field Required" },
            }}
          />
          <Controller
            name="area"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                size="small"
                label="Area"
                placeholder="Enter Area..."
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error?.message}
              />
            )}
            rules={{
              required: { value: true, message: "Field Required" },
            }}
          />
          <Controller
            name="pincode"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                size="small"
                label="Pincode"
                placeholder="Enter Pincode"
                type="number"
                value={value}
                onChange={(e: any) => {
                  onChange(e.target.value);
                  if (e.target.value.length == 6) {
                    fetchAddressByPincode(e.target.value);
                  } else {
                    setValue("state", "");
                    setValue("district", "");
                    setValue("country", "");
                  }
                }}
                error={!!error}
                helperText={error?.message}
              />
            )}
            rules={{
              pattern: {
                value: regexPincode,
                message: "Invalid Pincode",
              },
              required: { value: true, message: "Field Required" },
            }}
          />
          <Controller
            name="city"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                size="small"
                label="City"
                disabled={true}
                value={value ? value : ""}
              />
            )}
            rules={{
              required: { value: true, message: "Field Required" },
            }}
          />
          <Controller
            name="district"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                size="small"
                label="District"
                disabled={true}
                value={value ? value : ""}
              />
            )}
            rules={{
              required: { value: true, message: "Field Required" },
            }}
          />
          <Controller
            name="state"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                size="small"
                label="State"
                value={value ? value : ""}
                disabled={true}
              />
            )}
            rules={{
              required: { value: true, message: "Field Required" },
            }}
          />
          <Controller
            name="country"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                size="small"
                label="Country"
                value={value ? value : ""}
                disabled={true}
                onChange={onChange}
              />
            )}
            rules={{
              required: { value: true, message: "Field Required" },
            }}
          />

          <LoadingButton
            loading={false}
            variant="contained"
            color="secondary"
            onClick={handleSubmit(handleAddAddress)}
          >
            <Typography fontWeight={550} color={"white"} textTransform={"none"}>
              Add
            </Typography>
          </LoadingButton>

          <Button
            variant="contained"
            color="error"
            onClick={() => handleClose()}
          >
            <Typography fontWeight={550} color={"white"} textTransform={"none"}>
              Close
            </Typography>
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default EditAddressDetailsModal;
