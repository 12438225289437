import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { ConsentTokenEnrolmentData } from "../../domain/models/consent-token-enrolment-data";
import { CAPTCHA_HEADER, PSP_PROGRAM_SERVICE_API_URL } from "../../base";
import Endpoints from "../../domain/endpoints";
import { RemoteGetEnrolmentByConsentToken } from "../../data/usecases/remote-get-enrolment-by-consent-token";
import { GetEnrolmentByConsentToken } from "../../domain/usages/getEnrolmentByConsentToken";

interface GetEnrolmentByConsentTokenDetailsSliceType {
  getEnrolmentByConsentTokenDetails: Function;
  consentTokenEnrolmentDetails: ConsentTokenEnrolmentData;
}
const initialStates = {
    consentTokenEnrolmentDetails: {} as ConsentTokenEnrolmentData,
};

const axiosHttpClient = AxiosHttpClient.getInstance();

export const useGetEnrolmentByConsentTokenDetails: StateCreator<
GetEnrolmentByConsentTokenDetailsSliceType
> = (set, get) => ({
  ...initialStates,
  getEnrolmentByConsentTokenDetails: async (consentToken: string, captchaToken: string) => {
   
    axiosHttpClient.setAuthCaptcha({[CAPTCHA_HEADER]:captchaToken})

    const remoteGetEnrolmentByConsentToken: GetEnrolmentByConsentToken =
      new RemoteGetEnrolmentByConsentToken(
        `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.GET_ENROLMENT_BY_CONSENT_TOKEN_URL}`,
        axiosHttpClient
      );

    let result = await remoteGetEnrolmentByConsentToken.get(consentToken);
    if (result.success) {
      set(() => ({
        consentTokenEnrolmentDetails: result.enrolment,
      }));
    } else if(result==404){
      get().getEnrolmentByConsentTokenDetails(consentToken, captchaToken)
    }
  },
});
