import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useOrderDetailsStore } from "../../../store/stockistPageStore/orderStore/orderDetailsStore";
import { Status } from "../../../constants/enums/status";
import CheckIcon from "@mui/icons-material/Check";

type Props = {
  openConfirmationModal: boolean;
  handleCloseConfirmationModal: Function;
  handlePacking: Function;
};

const style = {
  borderRadius: "12px",
  display: "flex",
  gap: 2,
  flexDirection: "column",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ConfirmationModal: React.FC<Props> = ({
  openConfirmationModal,
  handleCloseConfirmationModal,
  handlePacking,
}) => {
  const { showSaveButton, handleAcceptOrder, selectedOrder } =
    useOrderDetailsStore();
  return (
    <Modal
      open={openConfirmationModal}
      onClose={() => handleCloseConfirmationModal()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack alignItems={"center"}>
          <Typography id="modal-modal-title" variant="h6">
            {selectedOrder.status === Status.PENDING &&
              "This action will accept the order!"}
            {selectedOrder.status === Status.ACCEPTED &&
              !showSaveButton &&
              "This action will pack the order!"}
          </Typography>
        </Stack>
        <Stack justifyContent={"center"} direction={"row"} gap={5}>
          <Button
            color="success"
            startIcon={<CheckIcon />}
            onClick={() => {
              selectedOrder.status === Status.PENDING && handleAcceptOrder();
              selectedOrder.status === Status.ACCEPTED &&
                !showSaveButton &&
                handlePacking();
            }}
          >
            Confirm
          </Button>
          <Button
            startIcon={<CloseIcon />}
            color="error"
            onClick={() => handleCloseConfirmationModal()}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
export default ConfirmationModal;
