import { create } from "zustand";
import { useUploadFileSlice } from "./uploadFileSlice";
import { useUploadFilestoDBSlice } from "./uploadFileToDB";

interface LoginStoreType
  extends ReturnType<typeof useUploadFileSlice>,
    ReturnType<typeof useUploadFilestoDBSlice> {}

export const useFulfillmentStore = create<LoginStoreType>()((...data) => ({
  ...useUploadFileSlice(...data),
  ...useUploadFilestoDBSlice(...data),
}));
