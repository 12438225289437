import { Card, CardHeader, Stack, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import React from "react";
import { Order } from "../../../../domain/models/get-order-type";
import { useOrderDetailsStore } from "../../../../store/stockistPageStore/orderStore/orderDetailsStore";
import InfiniteScroll from "react-infinite-scroll-component";
import { FetchState } from "../../../../domain/models/fetch-state-type";

const OrderCardList: React.FC = () => {
  const {
    orderList,
    selectedOrder,
    handleCardClick,
    fetchOrders,
    lastPage,
    currentPage,
    orderFilter,
    fetchOrderState,
  } = useOrderDetailsStore();

  const hasMoreData = () => {
    return fetchOrderState === FetchState.SUCCESS
      ? currentPage < lastPage
      : false;
  };

  const sortedOrderList = orderList.sort((a, b) => (a.id < b.id ? 1 : -1));

  return (
    <Stack id="box" height="60vh" overflow="auto" marginTop={2}>
      <InfiniteScroll
        scrollableTarget="box"
        dataLength={orderList?.length}
        hasMore={hasMoreData()}
        next={() => {
          fetchOrders(currentPage + 1, orderFilter, true);
        }}
        loader={<b>Loading...</b>}
        endMessage={
          <Typography display={"flex"} justifyContent="center">
            <b>End...</b>
          </Typography>
        }
      >
        <Stack gap={2}>
          {sortedOrderList.map((data: Order, index: number) => (
            <Card
              key={data.id}
              onClick={() => {
                handleCardClick(index);
              }}
              variant="outlined"
              sx={{
                background:
                  selectedOrder?.id === orderList[index]?.id
                    ? "rgba(149, 157, 165, 0.2)"
                    : "#FFF",
                "&:hover": {
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                },
                border: " 1px solid #D0CFCF",
                borderRadius: " 12px",
                cursor: "pointer",
              }}
            >
              <Stack
                marginRight={1}
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
              >
                <CardHeader
                  title={
                    <Typography fontWeight={550} variant="body2">
                      {data.order_for}
                    </Typography>
                  }
                  subheader={
                    <Typography variant="caption" color={"caption"}>
                      {data.order_date}
                    </Typography>
                  }
                />
                <Chip
                  color="secondary"
                  variant="outlined"
                  label={
                    <Typography
                      padding={1}
                      variant="subtitle2"
                      textTransform={"capitalize"}
                      color={"secondary"}
                    >
                      {data.status}
                    </Typography>
                  }
                />
              </Stack>
            </Card>
          ))}
        </Stack>
      </InfiniteScroll>
    </Stack>
  );
};
export default OrderCardList;
