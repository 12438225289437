import { Button, MenuItem, Modal, Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { UploadFileType } from "../../../../../domain/models/upload-file-type";
import { useDocumentStore } from "../../../../../store/adminPageStore/patientDetailsStore/documentStore/documentStore";
import { DocumentGroupType } from "../../../../../domain/models/master";
import Swal from "sweetalert2";
import { useEnrollmentMainStore } from "../../../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  maxHeight: "80%",
  bgcolor: "white",
  borderRadius: "22px",
  boxShadow: 24,
  p: 4,
};

const UpdateDocumentModal: React.FC = () => {
  const {
    generateSignedUrl,
    programDocuments,
    handleCloseDocumentModal,
    showUpdateDocumentModal,
    storeDocument,
  } = useDocumentStore();
  const { selectedCard, reloadPage } = useEnrollmentMainStore();
  const [documentGroup, setDocumentGroup] = useState("");
  const [documentType, setDocumentType] = useState<DocumentGroupType[]>([]);
  const [document, setDocument] = useState("");
  const [imageFile, setImageFile] = useState<UploadFileType>(
    {} as UploadFileType
  );
  const [preview, setPreview] = useState("");

  const handleSelectDocumentGroup = (e: any) => {
    setDocumentGroup(e.target.value);
    if (programDocuments.length > 0) {
      for (let i = 0; i < programDocuments.length; i++) {
        if (programDocuments[i].code == e.target.value)
          setDocumentType(programDocuments[i].document_types);
      }
    } else {
      setDocumentType([]);
    }
  };

  const handleSelectDocument = (e: any) => {
    setDocument(e.target.value);
  };

  const handleUploadDocument = (e: any) => {
    setImageFile(e.target.files[0]);
    // uploadToBucket(e.target.files[0], document, documentGroup, );
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreview(objectUrl);
  };

  const handleSubmit = () => {
    generateSignedUrl(imageFile, document, documentGroup)
      .then((res: any) =>
        storeDocument(selectedCard.id, res.uuid, document, documentGroup)
      )
      .then((res: any) => {
        if (res.success) {
          Swal.fire({
            icon: "success",
            title: "Document uploaded sucessfully",
            timer: 2000,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          });
          reloadPage();
        } else {
          Swal.fire("Document Upload Failed.", "", "error");
        }
      })

      .catch((error: any) => {
        Swal.fire("Document Upload Failed.", "", "error");
      });
  };

  useEffect(() => {
    setDocumentGroup("");
    setDocumentType([]);
    setPreview("");
    setDocument("");
  }, [showUpdateDocumentModal]);

  return (
    <Modal
      open={showUpdateDocumentModal}
      onClose={() => handleCloseDocumentModal()}
    >
      <Box sx={style}>
        <Stack direction="column" spacing={2}>
          <TextField
            select
            fullWidth
            value={documentGroup}
            onChange={handleSelectDocumentGroup}
            label="Select Document Group"
          >
            {programDocuments.map((option) => (
              <MenuItem key={option.id} value={option.code}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          {documentType.length > 0 && (
            <TextField
              select
              fullWidth
              value={document}
              onChange={handleSelectDocument}
              label="Select Document Type"
            >
              {documentType.map((option) => (
                <MenuItem key={option.id} value={option.code}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          )}
          {document && (
            <Button variant="contained" component="label">
              Upload Document
              <input
                hidden
                accept="image/*"
                multiple
                type="file"
                onChange={handleUploadDocument}
              />
            </Button>
          )}
          <Box>
            {preview && imageFile && (
              <>
                <Box textAlign={"center"}>
                  <img
                    src={preview}
                    width="200px"
                    height="200px"
                    style={{ border: "1px solid", padding: 4 }}
                    alt=""
                  />
                </Box>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                  marginTop={3}
                >
                  <Button variant="text" color="success" onClick={handleSubmit}>
                    Approve
                  </Button>
                  <Button
                    variant="text"
                    color="error"
                    onClick={() => handleCloseDocumentModal()}
                  >
                    Reject
                  </Button>
                </Stack>
              </>
            )}
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
};

export default UpdateDocumentModal;
