import { EditAppointmentResponse } from "../../../domain/models/appointment-module/edit-appointment-response";
import { EditAppointment } from "../../../domain/usages/appointment-module/edit-appointment";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpPutClient } from "../../protocols/http/http-put-client";

export class RemoteEditAppointment implements EditAppointment {
  constructor(
    private readonly url: string,
    private readonly httpPutClient: HttpPutClient
  ) {}
  async edit(
    appointment_id: number,
    params: EditAppointment.Params
  ): Promise<EditAppointmentResponse> {
    const httpResponse = await this.httpPutClient.put({
      url: this.url.replace(":appointment_id", appointment_id.toString()),
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

    return httpResponse.data;
  }
}
