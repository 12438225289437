import { Divider, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { FetchState } from "../../domain/models/fetch-state-type";
import { useOrderDetailsStore } from "../../store/stockistPageStore/orderStore/orderDetailsStore";
import Header from "../components/header/Header";
import DetailsLoading from "../components/Loading/DetailsLoading";
import DemoRequestDetails from "../components/stockist-information/demo-request/DemoRequestDetails";
import OrderDetails from "../components/stockist-information/order-page/order-details/OrderDetails";
import OrderDemoRequestTab from "../components/stockist-information/OrderDemoRequestTab";
import { useActiveAddressStore } from "../../store/loginPageStore/getActiveAddressStore";

const StockistPage = () => {
  const { fetchOrders, fetchOrderState, orderFilter, fetchVendor } =
    useOrderDetailsStore();
  const { getActiveAddress } = useActiveAddressStore();

  const [showOrder, setShowOrder] = useState<boolean>(true);

  const handleShowOrder = () => {
    setShowOrder(true);
  };
  const handleShowDemoRequest = () => {
    setShowOrder(false);
  };
  useEffect(() => {
    getActiveAddress();
    fetchOrders(1, orderFilter, false);
    fetchVendor();
  }, []);

  return (
    <>
      <Grid container position={"fixed"} textOverflow="scroll">
      <Grid item xs={12}>
        <Header />
      </Grid>
        <Grid item xs={3} sm={3} md={3}>
          <OrderDemoRequestTab
            handleShowOrder={handleShowOrder}
            handleShowDemoRequest={handleShowDemoRequest}
          />
        </Grid>
        <Grid item ml={0} mr={4}>
          <Divider sx={{ height: 800 }} orientation="vertical" />
        </Grid>
        {showOrder ? (
          <Grid item xs={8} sm={8} md={8}>
            <Box overflow={"scroll"} height={"80vh"}>
              {fetchOrderState === FetchState.LOADING && <DetailsLoading />}
              {fetchOrderState === FetchState.SUCCESS && <OrderDetails />}
            </Box>
          </Grid>
        ) : (
          <Grid item xs={8} sm={8} md={8} mt={5}>
            <DemoRequestDetails />
          </Grid>
        )}
      </Grid>
    </>
  );
};
export default StockistPage;
