import { create } from "zustand";
import { useGetAddressBasedOnPincode } from "./getAddressBasedOnPincodeSlice";
import { useGetPatientSlice } from "./getPatientDetailSlice";
import { useUpdatePatientDetailSLice } from "./updatePatientDetailsSlice";
import { useFetchPatientOrderSlice } from "../patientOrderSlice";
import { useGetAddressByPincode } from "../../../getAddressByPincodeSlice";
import { useAddPatientAddressDetailSlice } from "./addPatientAddressDetailsSlice";
import { useAddPatientContactDetailSlice } from "./addPatientContactDetailsSlice";
import { useUpdateAddressDetailSlice } from "./updateAddressDetailsSlice";
import { useUpdateContactDetailSlice } from "./updateContactDetailsSlice";
import { useUpdatePatientStatusSlice } from "./updatePatientStatusSlice";

interface PersonalDetailStoreType
  extends ReturnType<typeof useGetPatientSlice>,
    ReturnType<typeof useUpdatePatientDetailSLice>,
    ReturnType<typeof useGetAddressBasedOnPincode>,
    ReturnType<typeof useGetAddressByPincode>,
    ReturnType<typeof useAddPatientAddressDetailSlice>,
    ReturnType<typeof useAddPatientContactDetailSlice>,
    ReturnType<typeof useUpdateAddressDetailSlice>,
    ReturnType<typeof useUpdateContactDetailSlice>,
    ReturnType<typeof useFetchPatientOrderSlice>,
    ReturnType<typeof useUpdatePatientStatusSlice> {}

export const usePersonalDetailStore = create<PersonalDetailStoreType>()(
  (...personalDetail) => ({
    ...useGetPatientSlice(...personalDetail),
    ...useUpdatePatientDetailSLice(...personalDetail),
    ...useGetAddressBasedOnPincode(...personalDetail),
    ...useGetAddressByPincode(...personalDetail),
    ...useAddPatientAddressDetailSlice(...personalDetail),
    ...useAddPatientContactDetailSlice(...personalDetail),
    ...useUpdateContactDetailSlice(...personalDetail),
    ...useUpdateAddressDetailSlice(...personalDetail),
    ...useFetchPatientOrderSlice(...personalDetail),
    ...useUpdatePatientStatusSlice(...personalDetail)
  })
);
