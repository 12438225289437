import { Typography } from "@mui/material";
import parse from 'html-react-parser'

const InformationText = ({ text }) => {
  if (text=="") return <></>;


  if (Array.isArray(text))
    return (
      <ol style={{ marginTop: 0, paddingLeft: "15px", fontSize: "12px" }}>
        {text.map((item, index) => {
          return (
            <li style={{ marginBottom: 10 }}>
              <Typography style={{ fontSize: 12, textAlign: "justify" }}>
                {item}
              </Typography>
            </li>
          );
        })}
      </ol>
    );

  return (
    parse(text)

  );
};

export default InformationText;
