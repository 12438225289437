import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import AppointmentCard from "../appointment-module/AppointmentCard";
import { useAppointmentDetailsStore } from "../../../../../store/adminPageStore/patientDetailsStore/appointmentDetailsStore/appointmentDetailsStore";
import dayjs from "dayjs";
import ListLoading from "../../../Loading/ListLoading";
import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { AppointmentStatus } from "../../../../../constants/enums/appointmentStatus";
import { FetchState } from "../../../../../domain/models/fetch-state-type";
import moment from "moment";
import { Constant } from "../../../../../constants/constant";
import IMAGES from "../../../../../assets/images";

export const AllAppointmsntsList: React.FC = () => {
  const {
    appointmentDate,
    appointmentStatus,
    setAppointmentStatus,
    fetchAppointments,
    appointmentData,
    fetchAppointmentsLoading,
    fetchCalendarCount,
    appointmentFilter,
    page,
    totalAppointments,
  } = useAppointmentDetailsStore();

  useEffect(() => {
    fetchAppointments(1, false, {
      status: appointmentStatus,
      date: dayjs(appointmentDate).format(Constant.YEAR_MONTH_DATE_FORMAT),
    });
  }, []);

  const hasMoreData = () => {
    return appointmentData.length < totalAppointments;
  };

  return (
    <Box p={2} height={"85vh"}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        mb={1}
        mr={"1%"}
      >
        <Typography fontSize={"24px"} fontWeight={500}>
          {appointmentStatus
            ? appointmentStatus.charAt(0).toUpperCase() +
              appointmentStatus.slice(1)
            : ""}{" "}
          Appointments of{" "}
          {appointmentDate
            ? dayjs(appointmentDate).format("DD MMM YYYY")
            : "all doctors"}
        </Typography>
        <FormControl sx={{ width: "150px" }} size="small">
          <InputLabel id="filter">Status filter</InputLabel>
          <Select
            labelId="filter"
            id="demo-simple-select"
            value={appointmentStatus}
            label="Status filter"
            onChange={(e) => {
              setAppointmentStatus(e.target.value);
              fetchCalendarCount(
                moment(appointmentDate?.toString()).format(
                  Constant.YEAR_MONTH_FORMAT
                ),
                e.target.value
              );
              fetchAppointments(1, false, {
                status: e.target.value,
                date: dayjs(appointmentDate).format(
                  Constant.YEAR_MONTH_DATE_FORMAT
                ),
              });
            }}
          >
            {Object.values(AppointmentStatus).map((status) => (
              <MenuItem key={status} value={status}>
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Box overflow={"scroll"} id="appointments_div" height={"90%"} mb={2}>
        {fetchAppointmentsLoading === FetchState.LOADING && <ListLoading />}
        {fetchAppointmentsLoading === FetchState.SUCCESS &&
          (appointmentData.length > 0 ? (
            <InfiniteScroll
              scrollableTarget="appointments_div"
              dataLength={appointmentData.length}
              next={() => {
                fetchAppointments(page + 1, true, appointmentFilter);
              }}
              hasMore={hasMoreData()}
              loader={<ListLoading />}
              endMessage={
                <Typography display={"flex"} justifyContent="center">
                  <b>End...</b>
                </Typography>
              }
            >
              {appointmentData.map((appointment, index) => (
                <AppointmentCard key={index} appointment={appointment} />
              ))}
            </InfiniteScroll>
          ) : (
            <Stack alignItems={"center"} justifyContent={"center"}>
              <img src={IMAGES.EmptyBox} width={"50%"} height={"50%"} />
              <Typography fontWeight={660} variant="body1">
                No Appointments yet
              </Typography>
            </Stack>
          ))}
      </Box>
    </Box>
  );
};
