import { HttpConstants } from "../protocols/http/http-constants";
import { HttpGetClient } from "../protocols/http/http-get-client";
import { GetDemoRequest } from "../../domain/usages/get-demo-request";

export class RemoteGetDemoRequest implements GetDemoRequest {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async get(page: number, params?: GetDemoRequest.Params): Promise<any> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(":pageNumber", page.toString()),
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (httpResponse.status === 200) {
      return httpResponse.data;
    }
  }
}
