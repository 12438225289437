import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import GaButton from "../../../GaButton";
import { AppointmentType } from "../../../../../domain/models/appointment-module/fetch-appointment-response";
import { useState } from "react";
import { useAppointmentDetailsStore } from "../../../../../store/adminPageStore/patientDetailsStore/appointmentDetailsStore/appointmentDetailsStore";
import Swal from "sweetalert2";
import { Constant } from "../../../../../constants/constant";
import ImageCard from "../../../ImageCard";

interface AppointmentCardDescriptionProps {
  appointment: AppointmentType;
  onEditClick: Function;
}

const AppointmentCardDescription: React.FC<AppointmentCardDescriptionProps> = ({
  appointment,
  onEditClick,
}) => {
  const { cancelAppointment } = useAppointmentDetailsStore();

  const [openCancelAppointmentModal, setOpenCancelAppointmentModal] =
    useState(false);
  const [selectedReason, setSelectedReason] = useState<string>("");

  const handleCancelAppointmentModalClose = () => {
    setOpenCancelAppointmentModal(false);
    setSelectedReason("");
  };

  return (
    <>
      {appointment.status !== "completed" ? (
        <Stack padding={"10px 15px 10px 15px"}>
          <Typography fontWeight={600} fontSize={"12px"} color="#344054" mb={1}>
            Reason for Appointment
          </Typography>
          <Typography
            fontWeight={600}
            fontStyle="italic"
            fontSize={"12px"}
            color={Constant.APPOINTMENT_DESC_COLOR}
            mb={1}
          >
            {appointment.description}
          </Typography>
          <Stack direction={"row"} flexWrap={"wrap"} spacing={1} mb={1}>
            {appointment.documents.map((fileItem) => (
              <ImageCard
                key={fileItem.id}
                uuid={fileItem.uuid}
                title={fileItem.title}
              />
            ))}
          </Stack>
          {appointment.status !== "canceled" && (
            <Stack direction={"row"} spacing={2}>
              <GaButton
                text="Edit"
                onClick={() => {
                  onEditClick(true);
                }}
                startIcon={
                  <AddCircleRoundedIcon
                    sx={{ fontSize: "14px", color: "#416BC6" }}
                  />
                }
                buttonBackgroundColor="#F0F1FB"
                textColor="#416BC6"
              />
              <GaButton
                text="Cancel"
                onClick={() => {
                  setOpenCancelAppointmentModal(true);
                }}
                startIcon={
                  <CancelRoundedIcon
                    sx={{ fontSize: "14px", color: "#F63D68" }}
                  />
                }
                buttonBackgroundColor="#FEF3F2"
                textColor="#F63D68"
              />
            </Stack>
          )}
        </Stack>
      ) : (
        <Stack direction={"column"} justifyContent={"space-between"}>
          <Stack
            borderRight={"1px solid #E8E6E3"}
            padding={"10px 15px 10px 15px"}
          >
            <Typography
              fontWeight={600}
              fontSize={"12px"}
              color="#344054"
              mb={1}
            >
              Reason for Appointment
            </Typography>
            <Typography
              fontWeight={600}
              fontStyle="italic"
              fontSize={"12px"}
              color={Constant.APPOINTMENT_DESC_COLOR}
              mb={1}
            >
              {appointment.description}
            </Typography>
          </Stack>
          <Stack
            padding={"10px 15px 10px 15px"}
            direction={"row"}
            flexWrap={"wrap"}
            spacing={1}
            mb={1}
          >
            {appointment.documents.map((fileItem) => (
              <ImageCard
                key={fileItem.id}
                uuid={fileItem.uuid}
                title={fileItem.title}
              />
            ))}
          </Stack>
        </Stack>
      )}
      {openCancelAppointmentModal && (
        <Dialog
          sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
          maxWidth="xs"
          open={openCancelAppointmentModal}
        >
          <DialogTitle>Cancel Appointment</DialogTitle>
          <DialogContent dividers>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <FormControl fullWidth>
                <InputLabel id="cancellation-reason-label">
                  Reason for Cancellation
                </InputLabel>
                <Select
                  labelId="cancellation-reason-label"
                  value={selectedReason}
                  onChange={(e) => setSelectedReason(e.target.value)}
                  label="Reason for Cancellation"
                >
                  {Constant.APPOINTMENT_CANCELLATION_REASON.map((reasonObj) => (
                    <MenuItem key={reasonObj.id} value={reasonObj.reason}>
                      {reasonObj.reason}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  Please select a reason for cancelling the appointment
                </FormHelperText>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => handleCancelAppointmentModalClose()}
            >
              Cancel
            </Button>
            {selectedReason && (
              <Button
                onClick={() => {
                  cancelAppointment(selectedReason, appointment.id);
                  handleCancelAppointmentModalClose();
                  Swal.fire({
                    title: "Cancelling Appointment...",
                    timerProgressBar: true,
                    didOpen: () => {
                      Swal.showLoading();
                    },
                  });
                }}
              >
                Ok
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
export default AppointmentCardDescription;
