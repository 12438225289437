import { StateCreator } from "zustand";
import Swal from "sweetalert2";
import {
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
  APPOINTMENT_SERVICE_STAGING_URL,
} from "../../base";
import { RemoteUploadFile } from "../../data/usecases/remote-upload-file";
import { RemoteGenerateSignedUrlForDocumentUpload } from "../../data/usecases/remote-generate-signed-url-for-document-upload";
import { FileUpload } from "../../domain/models/upload-file-type";
import { FetchState } from "../../domain/models/fetch-state-type";
import { Constant } from "../../constants/constant";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import Endpoints from "../../domain/endpoints";
import { RemoteConfirmFileUpload } from "../../data/usecases/doctor-service/remote-confirm-file-upload";
import { LocalLoggedInUser } from "../../data/usecases/local-logged-in-user";

interface UploadFileInterface {
  generateUrlUsingDoctorService: Function;
  uploadToBucketUsingDoctorService: Function;
  ConfirmFileUploadUsingDoctorService: Function;
  fetchImageState: FetchState;
}

const initialStates = {
  fetchImageState: FetchState.DEFAULT,
};
const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useUploadFileSlice: StateCreator<UploadFileInterface> = (set) => ({
  ...initialStates,
  generateUrlUsingDoctorService: async (
    fileUpload: FileUpload,
    category: string
  ) => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token });
    const remoteGenerateFileURL = new RemoteGenerateSignedUrlForDocumentUpload(
      `${APPOINTMENT_SERVICE_STAGING_URL}${Endpoints.GENERATE_URL}`,
      axiosHttpClient
    );
    let payload = {
      name: fileUpload.file.name,
      size: fileUpload.file.size,
      type: fileUpload.file.type,
      document_type: category,
    };
    try {
      set(() => ({ fetchImageState: FetchState.LOADING }));
      let result = await remoteGenerateFileURL.generate(payload);
      if (result && result.data.uuid) {
        fileUpload.uuid = result.data.uuid;
        fileUpload.url = result.data.url;
        fileUpload.success = true;
        return fileUpload;
      } else {
        set(() => ({ fetchImageState: FetchState.ERROR }));
        Swal.fire(Constant.SOMETHING_WENT_WRONG, "", "error");
      }
    } catch (err) {
      set(() => ({ fetchImageState: FetchState.ERROR }));
    }
  },
  uploadToBucketUsingDoctorService: async (fileUpload: FileUpload) => {
    const token = storage.get(PSP_AUTH_TOKEN_KEY);
    axiosHttpClient.setAuthHeaders({ ["Authorization"]: `Bearer ${token}` });
    const remoteUploadFileToBucket = new RemoteUploadFile(axiosHttpClient);

    try {
      set(() => ({ fetchImageState: FetchState.LOADING }));
      if (fileUpload.url) {
        let result = await remoteUploadFileToBucket.upload({
          file: fileUpload.file,
          url: fileUpload.url,
        });
        if (result) {
          fileUpload.success = true;
          return fileUpload;
        } else {
          set(() => ({ fetchImageState: FetchState.ERROR }));
          Swal.fire(Constant.SOMETHING_WENT_WRONG, "", "error");
        }
      }
    } catch (err) {
      set(() => ({ fetchImageState: FetchState.ERROR }));
    }
  },
  ConfirmFileUploadUsingDoctorService: async (fileUpload: FileUpload) => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token });
    const remoteConfirmFileUpload = new RemoteConfirmFileUpload(
      `${APPOINTMENT_SERVICE_STAGING_URL}${Endpoints.GENERATE_URL}`,
      axiosHttpClient
    );
    try {
      set(() => ({ fetchImageState: FetchState.LOADING }));
      if (fileUpload.uuid) {
        let result = await remoteConfirmFileUpload.confirm({
          uuid: fileUpload.uuid,
        });
        if (result.success) {
          fileUpload.success = true;
          fileUpload.uuid = result.data.uuid;
          return fileUpload;
        } else {
          set(() => ({ fetchImageState: FetchState.ERROR }));
          Swal.fire(Constant.SOMETHING_WENT_WRONG, "", "error");
        }
      }
    } catch (err) {
      set(() => ({ fetchImageState: FetchState.ERROR }));
    }
  },
});
