const IMAGES = {
  OncologyLogo: require("./headerlogo.svg").default,
  LoginFormImage: require("./loginForm_image.svg").default,
  AddDocumentImage: require("./add.svg").default,
  EmptyRecords: require("./empty-box.svg").default,
  CallCenter: require("./call-center.svg").default,
  DeliveryBoxImage: require("./delivery-box.svg").default,
  CreatedBoxImage: require("./box.svg").default,
  SelectedTruck: require("./truck.svg").default,
  OrderPacking: require("./packing-tape.svg").default,
  OrderAccepted: require("./confirm-order.svg").default,
  OrderDelivered: require("./delivery-boy.svg").default,
  EmptyBox: require("./empty.svg").default,
  CanceledOrder: require("./cancle-order.svg").default,
  HoldOrder: require("./cant-take-order.svg").default,
  ReturnedOrder: require("./return.svg").default,
  PendingLR: require("./hourglass.svg").default,
  RTOOrder: require("./delivery-truck.svg").default,
  PatientReport: require("./report.svg").default,
  PDFIcon: require("./pdfIcon.png").default
};

export default IMAGES;
