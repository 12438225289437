import { Box, CircularProgress, Modal, Stack, Typography } from "@mui/material";
import { useOrderDetailsStore } from "../../../store/stockistPageStore/orderStore/orderDetailsStore";

type Props = {
  openModal: boolean;
};

const style = {
  borderRadius: "12px",
  display: "flex",
  gap: 2,
  flexDirection: "column",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const LoadingModal: React.FC<Props> = ({ openModal }) => {
  return (
    <>
      <Modal open={openModal}>
        <Box sx={style}>
          <Stack alignItems={"center"} gap={5}>
            <Typography variant="h5">Please Wait!</Typography>
            <Stack direction={"row"} gap={4} alignItems={"center"}>
              <CircularProgress color="success" />
              <Typography>Preparing to download....</Typography>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
export default LoadingModal;
