import { CreateAppointmentResponse } from "../../../domain/models/appointment-module/create-appointment-response";
import { CreateAppointment } from "../../../domain/usages/appointment-module/create-appointment";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpPostClient } from "../../protocols/http/http-post-client";

export class RemoteCreateAppointment implements CreateAppointment {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}
  async create(
    params: CreateAppointment.Params
  ): Promise<CreateAppointmentResponse> {
    const httpResponse = await this.httpPostClient.post({
      url: this.url,
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

    return httpResponse.data;
  }
}
