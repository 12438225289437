import React from "react";
import { Button } from "@mui/material";
import GaDialogBox from "../GaDialogBox";
import ImagePreview from "../ImagePreview";

type Props = {
  imageLink: string;
  handleClose: Function;
  showImagePreview: boolean;
};

const ImagePreviewModal: React.FC<Props> = ({
  imageLink,
  handleClose,
  showImagePreview,
}) => {
  return (
    <GaDialogBox
      openDialog={showImagePreview}
      title="IMAGE PREVIEW"
      content={<ImagePreview imageLink={imageLink} />}
      handleClose={handleClose}
      actionItem={
        <>
          <Button variant="contained" onClick={() => handleClose()} fullWidth>
            OK
          </Button>
        </>
      }
    />
  );
};

export default ImagePreviewModal;
