import { Skeleton, Stack } from "@mui/material";

export const DoctorViewLoading = () => {
  return (
    <Stack width={"100%"}>
      <Stack direction={"column"} p={2} spacing={1}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={20}
          width={"100%"}
          sx={{
            borderRadius: "12px",
          }}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={20}
          width={"100%"}
          sx={{
            borderRadius: "12px",
          }}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={20}
          width={"100%"}
          sx={{
            borderRadius: "12px",
          }}
        />
      </Stack>
      <Stack direction={"row"} spacing={1} pl={2}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={100}
          width={100}
          sx={{
            borderRadius: "12px",
          }}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={100}
          width={100}
          sx={{
            borderRadius: "12px",
          }}
        />
      </Stack>
      <Stack p={2}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={40}
          width={"100%"}
          sx={{
            borderRadius: "12px",
          }}
        />
      </Stack>
    </Stack>
  );
};
