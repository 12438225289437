import { ConfirmFileUpload } from "../../../domain/usages/fullfilment/confirm-file-upload";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpPutClient } from "../../protocols/http/http-put-client";

export class RemoteConfirmFileUpload implements ConfirmFileUpload {
  constructor(
    private readonly url: string,
    private readonly httpPutClient: HttpPutClient
  ) {}

  async confirm(params: ConfirmFileUpload.Params): Promise<any> {
    const httpResponse = await this.httpPutClient.put({
      url: this.url,
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    const statusCode = httpResponse.status.toString();
    if (
      !statusCode.startsWith("2") &&
      !statusCode.startsWith("4") &&
      statusCode !== "500"
    ) {
      return null;
    }

    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
