import { UploadFile } from "../../domain/usages/upload-file";
import { HttpConstants } from "../protocols/http/http-constants";
import { HttpPutClient } from "../protocols/http/http-put-client";

export class RemoteUploadFile implements UploadFile {
  constructor(private readonly httpPutClient: HttpPutClient) {}

  async upload(params: UploadFile.Params): Promise<any> {
    const httpResponse = await this.httpPutClient.put({
      url: params.url,
      body: params.file,
      headers: {
        [HttpConstants.CONTENT_TYPE]: params.file.type,
        [HttpConstants.ACCEPT]: "*/*",
      },
      authHeaders: false,
    });
    if (httpResponse.status == 200) {
      return true;
    } else {
      return false;
    }
  }
}
