import React, { useEffect, useState } from "react";
import { GenerateSignedUrlForDocumentUpload } from "../../domain/usages/generate-signed-url-for-document-upload";
import { UploadFile } from "../../domain/usages/upload-file";
import { ConfirmDocumentUpload } from "../../domain/usages/confirm-document-upload";
import { useEnrollmentMainStore } from "../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import { CreateNewEnrollment } from "../../domain/usages/create-new-enrollment";
import FormLoading from "../components/Loading/FormLoading";
import { getDomain } from "../../domain/Utils";
import DomainName from "../../domain/domain";
import EnrollmentForm from "../components/program/EnrollmentForm";
import { Program } from "../../constants/enums/program";
import { useSearchParams } from "react-router-dom";
import { useConsentStore } from "../../store/patientConsentStore/consentStore";
import { AcceptPatientConsent } from "../../domain/usages/accept-patient-consent";
import useRecaptcha from "../hooks/reCaptcha";
import { GOOGLE_RECAPTCHE_SITE_KEY } from "../../base";

type Props = {
  generateUploadURL: GenerateSignedUrlForDocumentUpload;
  uploadFile: UploadFile;
  confirmFileUpload: ConfirmDocumentUpload;
};

const ProgramEnrolmentPage: React.FC<Props> = ({
  generateUploadURL,
  uploadFile,
  confirmFileUpload,
}) => {
  const { createEnrollmentByPatient } = useEnrollmentMainStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const [token, setToken] = useState<String | null>(null);
  const {
    captchaToken: getEnrolmentByConsentTokenDetailsToken,
    executeRecaptcha: executeRecaptchaGetEnrolmentByConsentTokenDetails,
    isCaptchaLoading,
  } = useRecaptcha(
    GOOGLE_RECAPTCHE_SITE_KEY,
    "getEnrolmentByConsentTokenDetails"
  );
  const handleCreateEnrollment = (
    data: CreateNewEnrollment.Params,
    captcha: string,
    enrollmentByConsentToken: string
  ) => {
    if (enrollmentByConsentToken && captcha) {
      createEnrollmentByPatient(data, captcha, enrollmentByConsentToken);
    }
  };
  const {
    getEnrolmentByConsentTokenDetails,
    consentTokenEnrolmentDetails,
    acceptPatientConsentSliceType,
  } = useConsentStore();

  useEffect(() => {
    if (searchParams.get("token")) {
      setToken(searchParams.get("token"));
      executeRecaptchaGetEnrolmentByConsentTokenDetails();
    } else {
      setToken(null);
    }
  }, []);

  useEffect(() => {
    if (getEnrolmentByConsentTokenDetailsToken) {
      getEnrolmentByConsentTokenDetails(
        searchParams.get("token"),
        getEnrolmentByConsentTokenDetailsToken
      );
    }
  }, [getEnrolmentByConsentTokenDetailsToken]);

  const handleAcceptConsent = (
    payload: AcceptPatientConsent.Params,
    captcha: string
  ) => {
    if (token && payload) {
      acceptPatientConsentSliceType(token, payload, captcha);
    }
  };

  if (
    getDomain() == DomainName.ASTELLAS ||
    getDomain() == DomainName.SECURITY
  ) {
    return (
      <EnrollmentForm
        generateUploadURL={generateUploadURL}
        uploadFile={uploadFile}
        confirmFileUpload={confirmFileUpload}
        programId={
          consentTokenEnrolmentDetails &&
          token &&
          consentTokenEnrolmentDetails.program_id
            ? consentTokenEnrolmentDetails.program_id.toString()
            : Program.ASTELLAS
        }
        successCallback={
          consentTokenEnrolmentDetails &&
          token &&
          consentTokenEnrolmentDetails.program_id
            ? handleAcceptConsent
            : handleCreateEnrollment
        }
        title={
          consentTokenEnrolmentDetails &&
          token &&
          consentTokenEnrolmentDetails.program_id
            ? "Program Consent Form"
            : "Program Enrolment Form"
        }
      />
    );
  } else if (getDomain() == DomainName.OSTEO) {
    return (
      <EnrollmentForm
        generateUploadURL={generateUploadURL}
        uploadFile={uploadFile}
        confirmFileUpload={confirmFileUpload}
        programId={
          consentTokenEnrolmentDetails &&
          token &&
          consentTokenEnrolmentDetails.program_id
            ? consentTokenEnrolmentDetails.program_id.toString()
            : Program.OSTEO
        }
        successCallback={
          consentTokenEnrolmentDetails &&
          token &&
          consentTokenEnrolmentDetails.program_id
            ? handleAcceptConsent
            : handleCreateEnrollment
        }
        title={
          consentTokenEnrolmentDetails &&
          token &&
          consentTokenEnrolmentDetails.program_id
            ? "Program Consent Form"
            : "Program Enrolment Form"
        }
      />
    );
  } else if (
    getDomain() == DomainName.EISAI ||
    getDomain() == DomainName.locahost
  ) {
    return (
      <EnrollmentForm
        generateUploadURL={generateUploadURL}
        uploadFile={uploadFile}
        confirmFileUpload={confirmFileUpload}
        programId={
          consentTokenEnrolmentDetails &&
          token &&
          consentTokenEnrolmentDetails.program_id
            ? consentTokenEnrolmentDetails.program_id.toString()
            : Program.EISAI
        }
        successCallback={
          consentTokenEnrolmentDetails &&
          token &&
          consentTokenEnrolmentDetails.program_id
            ? handleAcceptConsent
            : handleCreateEnrollment
        }
        title={
          consentTokenEnrolmentDetails &&
          token &&
          consentTokenEnrolmentDetails.program_id
            ? "Program Consent Form"
            : "Program Enrolment Form"
        }
      />
    );
  } else if (getDomain() == DomainName.DRUSHTI) {
    return (
      <EnrollmentForm
        generateUploadURL={generateUploadURL}
        uploadFile={uploadFile}
        confirmFileUpload={confirmFileUpload}
        programId={
          consentTokenEnrolmentDetails &&
          token &&
          consentTokenEnrolmentDetails.program_id
            ? consentTokenEnrolmentDetails.program_id.toString()
            : Program.DRUSHTI
        }
        successCallback={
          consentTokenEnrolmentDetails &&
          token &&
          consentTokenEnrolmentDetails.program_id
            ? handleAcceptConsent
            : handleCreateEnrollment
        }
        title={
          consentTokenEnrolmentDetails &&
          token &&
          consentTokenEnrolmentDetails.program_id
            ? "Program Consent Form"
            : "Program Enrolment Form"
        }
      />
    );
  } else {
    return <FormLoading />;
  }
};

export default ProgramEnrolmentPage;
