import {
  Box,
  Button,
  Divider,
  Modal,
  Stack,
  Typography,
  Slide,
  TextField,
  MenuItem,
} from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CollectionsIcon from "@mui/icons-material/Collections";
import CancelIcon from "@mui/icons-material/Cancel";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import PermMediaOutlinedIcon from "@mui/icons-material/PermMediaOutlined";
import { useDocumentStore } from "../../../store/adminPageStore/patientDetailsStore/documentStore/documentStore";
import { useState } from "react";

type Props = {
  openCaptureImageModal: boolean;
  handleCaptureFromGallery: Function;
  handleCaptureFromCamera: Function;
  handleCaptureImageModal: Function;
  selectedDocumentGroup: string;
};

const style = {
  position: "absolute" as "absolute",
  bottom: 0,

  width: "100%",
  bgcolor: "background.paper",
  paddingY: 2,
  paddingBottom: 10,
  textAlign: "center",
  borderRadius: "20px 20px 0px 0px",
};

const CaptureImageModal: React.FC<Props> = ({
  openCaptureImageModal,
  handleCaptureFromCamera,
  handleCaptureFromGallery,
  handleCaptureImageModal,
  selectedDocumentGroup,
}) => {
  const { programDocuments } = useDocumentStore();
  const [selectedDocumentType, setSelectedDocumentType] = useState<string>("");

  const getDocumentTypes = () => {
    const document = programDocuments.find(
      (doc) => doc.code === selectedDocumentGroup
    );
    return document ? document.document_types : [];
  };

  return (
    <Modal
      open={openCaptureImageModal}
      onClose={() => handleCaptureImageModal()}
    >
      <Slide direction="up" in={openCaptureImageModal} timeout={700}>
        <Box sx={style}>
          <Stack alignItems={"center"}>
            <Typography variant="subtitle2" fontWeight={660} marginBottom={2}>
              Please select type of document you want to upload
            </Typography>
            <TextField
              select
              size="small"
              sx={{ width: "70%" }}
              onChange={(e) => setSelectedDocumentType(e.target.value)}
            >
              {getDocumentTypes().map((type) => {
                return (
                  <MenuItem key={type.id} value={type.code}>
                    <Typography>{type.name}</Typography>
                  </MenuItem>
                );
              })}
            </TextField>
            {selectedDocumentType != "" && (
              <Stack flexDirection={"row"} justifyContent={"center"} gap={1}>
                <Button
                  onClick={() => handleCaptureFromCamera(selectedDocumentType)}
                  color={"secondary"}
                >
                  <Stack alignItems={"center"}>
                    <Box
                      sx={{
                        width: 50,
                        height: 50,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "1px solid",
                        borderRadius: 2,
                        padding: 1.5,
                      }}
                    >
                      <CameraAltOutlinedIcon />
                    </Box>
                    <Typography
                      variant="caption"
                      textTransform={"none"}
                      color={"secondary"}
                    >
                      Camera
                    </Typography>
                  </Stack>
                </Button>
                <Button
                  onClick={() => handleCaptureFromGallery(selectedDocumentType)}
                  color={"secondary"}
                >
                  <Stack alignItems={"center"}>
                    <Box
                      sx={{
                        width: 50,
                        height: 50,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "1px solid",
                        borderRadius: 2,
                        padding: 1.5,
                      }}
                    >
                      <PermMediaOutlinedIcon />
                    </Box>
                    <Typography
                      variant="caption"
                      textTransform={"none"}
                      color={"secondary"}
                    >
                      Gallery
                    </Typography>
                  </Stack>
                </Button>
              </Stack>
            )}
          </Stack>
        </Box>
      </Slide>
    </Modal>
  );
};
export default CaptureImageModal;
