import { Box, Stack, Typography } from "@mui/material";
import {
  DateCalendar,
  DayCalendarSkeleton,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import Badge from "@mui/material/Badge";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useAppointmentDetailsStore } from "../../../../../store/adminPageStore/patientDetailsStore/appointmentDetailsStore/appointmentDetailsStore";
import moment from "moment";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { useEffect, useRef, useState } from "react";
import { Constant } from "../../../../../constants/constant";

const wrapperStyle: React.CSSProperties = {
  overflow: "scroll",
};

export const AppointmentsCalender: React.FC = () => {
  const {
    appointmentDate,
    setAppointmentDate,
    fetchCalendarCount,
    calendarCountData,
    fetchCalendarCountLoading,
    fetchAppointments,
    appointmentStatus,
  } = useAppointmentDetailsStore();

  const requestAbortController = useRef<AbortController | null>(null);

  const [highlightedDays, setHighlightedDays] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchDays = ({ signal }: { signal: AbortSignal }) => {
    return new Promise<{ daysToHighlight: number[] }>((resolve, reject) => {
      const timeout = setTimeout(() => {
        const daysToHighlight = calendarCountData.map((item) =>
          parseInt(item.date.split("-")[2])
        );

        resolve({ daysToHighlight });
      }, 500);

      signal.onabort = () => {
        clearTimeout(timeout);
        reject(new DOMException("aborted", "AbortError"));
      };
    });
  };

  const fetchHighlightedDays = () => {
    const controller = new AbortController();
    fetchDays({
      signal: controller.signal,
    })
      .then(({ daysToHighlight }) => {
        setHighlightedDays(daysToHighlight);
        setIsLoading(false);
      })
      .catch((error) => {
        setHighlightedDays([]);
        setIsLoading(false);
        // ignore the error if it's caused by `controller.abort`
        if (error.name !== "AbortError") {
          throw error;
        }
      });

    requestAbortController.current = controller;
  };

  const handleMonthChange = (date: Dayjs) => {
    if (requestAbortController.current) {
      requestAbortController.current.abort();
    }
    fetchCalendarCount(
      moment(date.toString()).format(Constant.YEAR_MONTH_FORMAT),
      appointmentStatus
    );
    fetchAppointments(1, false, {
      date: dayjs(date).format(Constant.YEAR_MONTH_DATE_FORMAT),
      status: appointmentStatus,
    });
    setHighlightedDays([]);
    setAppointmentDate(date);
    setIsLoading(true);
    fetchHighlightedDays();
  };

  useEffect(() => {
    fetchHighlightedDays();
    return () => requestAbortController.current?.abort();
  }, [calendarCountData]);

  useEffect(() => {
    fetchCalendarCount(
      moment(appointmentDate.toString()).format(Constant.YEAR_MONTH_FORMAT),
      appointmentStatus
    );
  }, []);

  const MonthCounts = (yearMonth: string) => {
    // Extract year and month from the prop
    const year = parseInt(yearMonth.slice(0, 4));
    const month = parseInt(yearMonth.slice(4));

    // Calculate the number of days in the given month
    const daysInMonth = new Date(year, month, 0).getDate();

    // Initialize counts for each day in the month
    const dailyCounts: number[] = Array(daysInMonth).fill(0);

    // Populate counts from the provided data
    calendarCountData.forEach((entry) => {
      const entryDate = new Date(
        moment(entry.date, Constant.YEAR_MONTH_DATE_FORMAT).format(
          Constant.YEAR_MONTH_DATE_FORMAT
        )
      );

      if (
        entryDate.getFullYear() === year &&
        entryDate.getMonth() + 1 === month
      ) {
        const dayOfMonth = entryDate.getDate();
        dailyCounts[dayOfMonth - 1] += entry.count;
      }
    });
    return dailyCounts;
  };

  const ServerDay = (
    props: PickersDayProps<Dayjs> & { highlightedDays?: number[] }
  ) => {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const isSelected =
      !props.outsideCurrentMonth &&
      highlightedDays.indexOf(props.day.date()) >= 0;

    return (
      <Badge
        key={props.day.toString()}
        overlap="circular"
        badgeContent={
          isSelected ? (
            <Box
              bgcolor="red"
              p={0.5}
              borderRadius="50%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              width={10}
              height={10}
            >
              <Typography
                variant="caption"
                fontWeight={660}
                color="white"
                textAlign="center"
              >
                {appointmentDate &&
                  MonthCounts(
                    moment(appointmentDate.toString()).format(
                      Constant.YEAR_MONTH_FORMAT
                    )
                  )[props.day.date() - 1]}
              </Typography>
            </Box>
          ) : undefined
        }
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  };

  const initialValue = dayjs(new Date());

  return (
    <Stack p={2} overflow={"scroll"} height={"85vh"}>
      <Typography fontSize={"24px"} fontWeight={500}>
        Calender
      </Typography>
      <div style={wrapperStyle}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            defaultValue={initialValue}
            loading={isLoading || fetchCalendarCountLoading}
            value={appointmentDate && appointmentDate}
            onChange={(newValue) => {
              setAppointmentDate(newValue);
              fetchAppointments(1, false, {
                date: dayjs(newValue).format(Constant.YEAR_MONTH_DATE_FORMAT),
                status: appointmentStatus,
              });
            }}
            onMonthChange={handleMonthChange}
            renderLoading={() => <DayCalendarSkeleton />}
            slots={{
              day: ServerDay,
            }}
            slotProps={{
              day:
                highlightedDays.length > 0
                  ? ({
                      highlightedDays,
                    } as any)
                  : ({} as any),
            }}
          />
        </LocalizationProvider>
      </div>
    </Stack>
  );
};
