import { Skeleton, Stack } from "@mui/material";
import React from "react";

type Props = {};

const DetailsLoading = (props: Props) => {
  return (
    <Stack spacing={2} margin={3}>
      <Skeleton variant="rectangular" animation="wave" height={80} />
      <Skeleton variant="rectangular" animation="wave" height={80} />
      <Stack direction={"row"} spacing={5}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={80}
          width={"50%"}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={80}
          width={"50%"}
        />
      </Stack>
      <Stack direction={"row"} spacing={5}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={80}
          width={"50%"}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={80}
          width={"50%"}
        />
      </Stack>
      <Stack direction={"row"} spacing={5}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={80}
          width={"50%"}
        />
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={80}
          width={"50%"}
        />
      </Stack>
    </Stack>
  );
};

export default DetailsLoading;
