import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
  PSP_PROGRAM_SERVICE_API_URL,
} from "../../../base";

import Endpoints from "../../../domain/endpoints";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { FetchFollowUpThreadsForEnrolmentId } from "../../../domain/usages/follow-ups/fetch-follow-ups-for-enrolment-id";
import { FollowupThreadType } from "../../../domain/models/followup-thread-type";
import { RemoteFetchFollowUpThreadsForEnrolmentId } from "../../../data/usecases/follow-ups/remote-fetch-followups-for-enrolmentid";
import { LocalLoggedInUser } from "../../../data/usecases/local-logged-in-user";

interface FetchFollowUpThreadForEnrollmemtIdType {
  followUpThreadForEnrolmentId: Function;
  followUpThreads: FollowupThreadType[];
  fetchFollowUpThreadState: FetchState;
  followupThreadCount: number;
}

const initialStates = {
  followUpThreads: [],
  followupThreadCount: 0,
  fetchFollowUpThreadState: FetchState.DEFAULT,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useFetchFollowUpThreadForEnrollmentIdSlice: StateCreator<
FetchFollowUpThreadForEnrollmemtIdType
> = (set) => ({
  ...initialStates,
  followUpThreadForEnrolmentId: async (enrolment_id?: number, scrolled?: boolean, followUpDate?:string) => {
    
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getPSPToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token });

    const remoteFetchFollowUpThreadsForEnrolmentId: FetchFollowUpThreadsForEnrolmentId =
      new RemoteFetchFollowUpThreadsForEnrolmentId(
        `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.FETCH_FOLLOW_UP_THREAD_FOR_ENROLMENT_ID}`,
        axiosHttpClient
      );

    if (!scrolled) {
      set(() => ({ fetchFollowUpThreadState: FetchState.LOADING }));
    }
    let payload = { enrolment_id: enrolment_id, with: ["follow_ups", "enrolment"], follow_up_date: followUpDate };
    let result = await remoteFetchFollowUpThreadsForEnrolmentId.fetch(payload);
    try {
      if (result.success) {
        set(() => ({
          followUpThreads: result.follow_up_threads,
          followupThreadCount: result.total,
          fetchFollowUpThreadState: FetchState.SUCCESS,
        }));
      }
    } catch (error) {
      set(() => ({ fetchFollowUpThreadState: FetchState.ERROR }));
    }
  },
});
