import { UploadFileToBucket } from "../../../domain/usages/fullfilment/upload-to-bucket";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpPutClient } from "../../protocols/http/http-put-client";

export class RemoteUploadFileToBucket implements UploadFileToBucket {
  constructor(private readonly httpPutClient: HttpPutClient) {}

  async upload(params: UploadFileToBucket.Params): Promise<any> {
    const httpResponse = await this.httpPutClient.put({
      url: params.url,
      body: params.file,
      headers: {
        [HttpConstants.CONTENT_TYPE]: params.file.type,
        [HttpConstants.ACCEPT]: "*/*",
      },
      authHeaders: false,
    });
    const statusCode = httpResponse.status.toString();
    if (!statusCode.startsWith('2') && !statusCode.startsWith('4') && statusCode !== '500') {
      return null
    }
    if(httpResponse.status===200){
      return 200;
    }else{
      return ""
    }
  }
}
