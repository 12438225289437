import { Grid, Box } from "@mui/material";
import "../../../patient-information/OncologyPageStyles.css";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { usePersonalDetailStore } from "../../../../../store/adminPageStore/patientDetailsStore/personalDetailStore.ts/personalDetailsStore";
import BasicDetailsCard from "./BasicDetailsCard";
import ContactDetails from "./ContactDetails";
import AddressDetails from "./AddressDetails";
import EditBasicPersonalDetails from "./EditBasicPersonalDetails";
import EditableTypes from "../../../../../domain/enum";
import EditAddressDetailsModal from "./EditAddressDetailsModal";

type detailItems = {
  fullname: string;
  age: number;
  gender: string;
  dateOfBirth: string;
  noOfFamilyMembers: number;
  noOfEarningFamilyMembers: number;
  annualIncomeOfFamily: number;
  emailid: string;
  mobileno: string;
  altmobileno: string;
  primaryaddress: string;
  secondaryaddress: string;
  comments: string;
};

const StaticPersonalDetails: React.FC = () => {
  const { selectedPatientPersonalDetails, selectedToEdit } =
    usePersonalDetailStore();
  const { control, setValue } = useForm<detailItems>({
    mode: "onChange",
  });
  const [openAddressModal, setOpenAddressModal] = useState<boolean>(false);
  const [addressType, setAddressType] = useState<string>("");

  useEffect(() => {
    const filteredMobileNumber = selectedPatientPersonalDetails.contacts.filter(
      (mobile) => {
        return mobile.type === "mobile" && mobile.status === "active";
      }
    );
    const filteredEmail = selectedPatientPersonalDetails.contacts.filter(
      (mobile) => {
        return mobile.type === "email" && mobile.status === "active";
      }
    );
    const filterPrimaryAddress =
      selectedPatientPersonalDetails.addresses.filter((mobile) => {
        return mobile.type === "primary" && mobile.status === "active";
      });

    const filterSecondaryAddress =
      selectedPatientPersonalDetails.addresses.filter((mobile) => {
        return mobile.type === "secondary" && mobile.status === "active";
      });

    const keysToExclude = ["status", "type", "_id"];

    const filteredPrimaryAddress = Object.values(
      filterPrimaryAddress.length > 0 && filterPrimaryAddress[0]
        ? Object.entries(filterPrimaryAddress[0]).reduce(
            (acc: any, [key, value]) => {
              if (
                !keysToExclude.includes(key) &&
                value !== "" &&
                value !== null
              ) {
                acc[key] = value;
              }
              return acc;
            },
            {}
          )
        : {}
    );

    const filteredSecondaryAddress = Object.values(
      filterSecondaryAddress.length > 0 && filterSecondaryAddress[0]
        ? Object.entries(filterSecondaryAddress[0]).reduce(
            (acc: any, [key, value]) => {
              if (
                !keysToExclude.includes(key) &&
                value !== "" &&
                value !== null
              ) {
                acc[key] = value;
              }
              return acc;
            },
            {}
          )
        : {}
    );

    setValue("fullname", selectedPatientPersonalDetails.fullName);
    setValue("dateOfBirth", selectedPatientPersonalDetails.dateOfBirth);
    setValue(
      "annualIncomeOfFamily",
      selectedPatientPersonalDetails.annualIncomeOfFamily
    );
    setValue(
      "noOfFamilyMembers",
      selectedPatientPersonalDetails.noOfFamilyMembers
    );
    setValue(
      "noOfEarningFamilyMembers",
      selectedPatientPersonalDetails.noOfEarningFamilyMembers
    );
    setValue("gender", selectedPatientPersonalDetails.gender);
    setValue(
      "emailid",
      filteredEmail.length > 0 ? filteredEmail[0].value : "-"
    );
    setValue("age", parseInt(selectedPatientPersonalDetails.age));
    setValue(
      "mobileno",
      filteredMobileNumber.length > 0 ? filteredMobileNumber[0].value : "-"
    );
    setValue(
      "altmobileno",
      filteredMobileNumber.length > 1 ? filteredMobileNumber[1].value : "-"
    );
    setValue("primaryaddress", Object.values(filteredPrimaryAddress).join(","));
    setValue(
      "secondaryaddress",
      Object.values(filteredSecondaryAddress).join(",")
    );
  }, [selectedPatientPersonalDetails._id]);

  const handleEditAddressModalOpen = (type: string, open: boolean) => {
    setAddressType(type);
    setOpenAddressModal(open);
  };

  return (
    <>
      <Box overflow="scroll" height="60vh" paddingBottom={5}>
        <Grid container spacing={4} mt={1}>
          <Grid item xs={12}>
            {" "}
            {selectedToEdit == EditableTypes.BASIC ? (
              <EditBasicPersonalDetails />
            ) : (
              <BasicDetailsCard />
            )}
          </Grid>
          <Grid item xs={12}>
            {" "}
            <ContactDetails />
          </Grid>
          <Grid item xs={12}>
            {" "}
            <AddressDetails successCallBack={handleEditAddressModalOpen} />
          </Grid>
        </Grid>
      </Box>
      <EditAddressDetailsModal
        addressType={addressType}
        open={openAddressModal}
        handleClose={() => setOpenAddressModal(false)}
        selectedPatientPersonalDetails={selectedPatientPersonalDetails}
      />
    </>
  );
};
export default StaticPersonalDetails;
