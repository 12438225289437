import { StateCreator } from "zustand";
import { RemoteGenerateDeliveryChallan } from "../../../../data/usecases/remote-generate-delivery-challan";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { useOrderDetailsStore } from "../orderDetailsStore";
import {
  PSP_GENERATE_PDF,
} from "../../../../base";
import Endpoints from "../../../../domain/endpoints";
import { GenerateDeliveryChallan } from "../../../../domain/usages/generate-delivery-challan";
import { useActiveAddressStore } from "../../../loginPageStore/getActiveAddressStore";
import { LocalJsonStorage } from "../../../../infra/http/local-json-storage";

interface GenerateDeliveryChallanSliceType {
  generateDeliveryChallan: Function;
  loadingDeliveryChallan: boolean;
}

const initialStates = {
  loadingDeliveryChallan: false,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useGenerateDeliveryChallanSlice: StateCreator<
  GenerateDeliveryChallanSliceType
> = (set) => ({
  ...initialStates,
  generateDeliveryChallan: async () => {
    const remoteGenerateDeliveryChallan: GenerateDeliveryChallan =
      new RemoteGenerateDeliveryChallan(
        `${PSP_GENERATE_PDF}${Endpoints.GENERATE_DELIVERY_LABEL}`,
        axiosHttpClient
      );
    const filteredShipToAddress = Object.entries(
      useOrderDetailsStore.getState().selectedOrder.address
    ).reduce((acc: any, [key, value]) => {
      if (
        value !== "" &&
        value !== null &&
        key !== "created_at" &&
        key !== "updated_at"
      ) {
        acc[key] = value;
      }
      return acc;
    }, {});
    const filteredShipFromAddress = Object.entries(
      useActiveAddressStore.getState().address
    ).reduce((acc: any, [key, value]) => {
      if (
        value !== "" &&
        value !== null &&
        (key === "line" ||
          key === "landmark" ||
          key === "district" ||
          key === "state" ||
          key === "country")
      ) {
        acc[key] = value;
      }
      return acc;
    }, {});
    set(() => ({ loadingDeliveryChallan: true }));
    let orderDetail = useOrderDetailsStore.getState();
    let payload = {
      invoice_date: orderDetail.selectedOrder.order_date,
      invoice_no: orderDetail.selectedOrder.id.toString(),
      paragraphs: {
        first:
          "This is to certify that the box contains Promotional items and is being sent to the following address.",
        second:
          "We further declare that this is not for sale and there is no commercial value.",
      },
      ship_to: {
        name: orderDetail.selectedOrder.order_for,
        address: Object.values(filteredShipToAddress).join(","),
        contact: orderDetail.selectedOrder.contact_no,
      },
      ship_from: {
        name: useActiveAddressStore.getState().address.user.full_name,
        address: Object.values(filteredShipFromAddress).join(","),
        contact: useActiveAddressStore.getState().address.user.mobile,
      },
      items: orderDetail.selectedOrder.items.map((oi) => {
        return {
          item: oi.item,
          quantity: oi.quantity,
          batch_no: oi.batch_no,
          amount: (parseInt(oi.quantity) * parseInt(oi.unit_price)).toString(),
        };
      }),
      total: {
        gross_amt: "0",
        gst_amt: "0",
        due_amt: "0",
      },
    };
    try {
      const response = await remoteGenerateDeliveryChallan.generate(payload);
      if (response) {
        set(() => ({ loadingDeliveryChallan: false }));
      }
      const pdfBlob = new Blob([response], { type: "application/pdf" });
      const url = window.URL.createObjectURL(pdfBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `ORDER-${
        useOrderDetailsStore.getState().selectedOrder.id
      }-DELIVERY-CHALLAN.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error(error);
    }
  },
});
