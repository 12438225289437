import { UpdatePatientContacts } from "../../domain/usages/update-patient-contacts";
import { HttpConstants } from "../protocols/http/http-constants";
import { HttpPutClient } from "../protocols/http/http-put-client";

export class RemoteUpdatePatientContacts implements UpdatePatientContacts {
  constructor(
    private readonly url: string,
    private readonly httpPutClient: HttpPutClient
  ) {}

  async update(patientId:string, contactId:string,  params: UpdatePatientContacts.Params): Promise<any> {
    const httpResponse = await this.httpPutClient.put({
      url: this.url.replace(":patientId", patientId).replace(":contactId", contactId),
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
