import { Box, Button, Stack, Typography, Dialog } from "@mui/material";
import GaButton from "../GaButton";
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";
import { UploadFileType } from "../../../domain/models/upload-file-type";
import { useState } from "react";
import { useImageUploadToDoctorServiceStore } from "../../../store/doctor-service/DoctorServiceStore";
import Swal from "sweetalert2";
import { useAppointmentDetailsStore } from "../../../store/adminPageStore/patientDetailsStore/appointmentDetailsStore/appointmentDetailsStore";

type Props = {
  openUploadPrsecriptionModal: boolean;
  handleUploadPrescriptionModal: Function;
  appointmetId: number;
};

const UploadPrecriptionModal: React.FC<Props> = ({
  openUploadPrsecriptionModal,
  handleUploadPrescriptionModal,
  appointmetId,
}) => {
  const [imageFile, setImageFile] = useState<UploadFileType>(
    {} as UploadFileType
  );
  const [preview, setPreview] = useState("");

  const {
    generateUrlUsingDoctorService,
    uploadToBucketUsingDoctorService,
    ConfirmFileUploadUsingDoctorService,
  } = useImageUploadToDoctorServiceStore();

  const { uploadPrescrtiption } = useAppointmentDetailsStore();

  const handleUploadDocument = (e: any) => {
    setImageFile(e.target.files[0]);
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreview(objectUrl);
  };

  const handleSubmit = () => {
    handleUploadPrescriptionModal();
    Swal.fire({
      title: "Uploading Image...",
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    generateUrlUsingDoctorService(
      {
        file: imageFile,
        success: false,
      },
      "prescription"
    )
      .then((res: any) => uploadToBucketUsingDoctorService(res))
      .then((res: any) => ConfirmFileUploadUsingDoctorService(res))
      .then((res: any) => {
        uploadPrescrtiption(appointmetId, {
          type: "APPOINTMENT_PRESCRIPTION",
          uuid: res.uuid,
          title: "prescription",
        });
      })
      .catch((error: any) => {
        Swal.fire("Document Upload Failed.", "", "error");
      });
  };

  return (
    <Dialog
      open={openUploadPrsecriptionModal}
      onClose={() => handleUploadPrescriptionModal()}
    >
      <Stack direction={"column"} spacing={1} p={2} alignItems={"center"}>
        <Typography fontWeight={600}>Upload Prescriptions?</Typography>
        <Stack width={"100%"}>
          <Stack
            border={"1px dashed #B2BCC9"}
            borderRadius={"8px"}
            justifyContent={"center"}
            alignItems={"center"}
            bgcolor={"white"}
            p={1}
            boxShadow={"0px 1px 2px 0px #1018280F"}
          >
            <label htmlFor="fileUpload">
              <Stack
                width={"50px"}
                height={"50px"}
                borderRadius={"25px"}
                bgcolor={"#E9FAEF"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <CloudUploadRoundedIcon
                  sx={{ fontSize: "30px", color: "#22C55E" }}
                />
              </Stack>
            </label>
            <input
              type="file"
              accept="image/*,application/pdf"
              id="fileUpload"
              onChange={handleUploadDocument}
              style={{ display: "none" }}
            />
            <Typography fontSize={"14px"}>
              Click to upload prescription
            </Typography>
            <Typography fontSize={"14px"} color={"#7D8A95"}>
              PNG, JPG OR PDF (Max. 2MB)
            </Typography>
          </Stack>
        </Stack>

        <Box>
          {preview && imageFile && (
            <>
              <Box textAlign={"center"}>
                <img
                  width={"250px"}
                  height={"250px"}
                  src={preview}
                  style={{ border: "1px solid", padding: 4 }}
                  alt=""
                />
              </Box>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                marginTop={3}
              >
                <GaButton
                  text={"Finish Upload"}
                  onClick={() => {
                    handleSubmit();
                  }}
                  buttonBackgroundColor="#4DB8C8"
                  buttonSx={{
                    padding: "10px",
                  }}
                />
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => handleUploadPrescriptionModal()}
                >
                  Close
                </Button>
              </Stack>
            </>
          )}
        </Box>
      </Stack>
    </Dialog>
  );
};
export default UploadPrecriptionModal;
