import { useEffect, useState } from "react";
import * as React from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import "../../../../components/patient-information/OncologyPageStyles.css";
import { Card, CardHeader } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { FetchState } from "../../../../../domain/models/fetch-state-type";
import ListLoading from "../../../Loading/ListLoading";
import moment from "moment";

import { useFollowUpsStore } from "../../../../../store/adminPageStore/followUpStore/followUpsStore";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import Badge from "@mui/material/Badge";
import ViewFollowUpThread from "./sub-components/ViewFollowUpThread";
import ViewFollowupHistory from "./sub-components/ViewFollowupHistory";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import { toTitleCase } from "../../../../../domain/Utils";
import { FollowupThreadType } from "../../../../../domain/models/followup-thread-type";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { theme } from "antd";
import ConfirmModal from "../../../modals/ConfirmModal";

const AllFollowUpsPanel: React.FC = () => {
  const {
    fetchDailyCount,
    fetchFollowUpsForThreadState,
    followUpThreadForEnrolmentId,
    followUpThreads,
    fetchFollowUpThreadState,
    followupThreadCount,
    fetchFollowUpForThread,
    dailyFollowupCount,
    closeFollowUpThreadLoading,
    closeFollowUpThread,
  } = useFollowUpsStore();
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
  const [showCalendar, setShowCalendar] = useState<boolean>(true);
  const { token } = theme.useToken();
  const requestAbortController = React.useRef<AbortController | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [highlightedDays, setHighlightedDays] = useState<number[]>([]);
  const [viewHistory, setViewHistory] = useState<boolean>(false);
  const [selectedThread, setSelectedThread] = useState<number | null>(null);
  const [addFollowupForThread, setAddFollowupForThread] =
    useState<boolean>(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  const wrapperStyle: React.CSSProperties = {
    width: 300,
    border: `1px solid lightgrey`,
    borderRadius: token.borderRadiusLG,
    overflow: "scroll",
    height: "44vh",
  };

  const MonthCounts = (yearMonth: string) => {
    // Extract year and month from the prop
    const year = parseInt(yearMonth.slice(0, 4));
    const month = parseInt(yearMonth.slice(4));

    // Calculate the number of days in the given month
    const daysInMonth = new Date(year, month, 0).getDate();

    // Initialize counts for each day in the month
    const dailyCounts: number[] = Array(daysInMonth).fill(0);

    // Populate counts from the provided data
    dailyFollowupCount.forEach((entry) => {
      const entryDate = new Date(
        moment(entry.date, "YYYY-MM-DD").format("YYYY-MM-DD")
      );

      if (
        entryDate.getFullYear() === year &&
        entryDate.getMonth() + 1 === month
      ) {
        const dayOfMonth = entryDate.getDate();
        dailyCounts[dayOfMonth - 1] += entry.count;
      }
    });
    return dailyCounts;
  };

  const ServerDay = (
    props: PickersDayProps<Dayjs> & { highlightedDays?: number[] }
  ) => {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const isSelected =
      !props.outsideCurrentMonth &&
      highlightedDays.indexOf(props.day.date()) >= 0;

    return (
      <Badge
        key={props.day.toString()}
        overlap="circular"
        badgeContent={
          isSelected ? (
            <Box
              bgcolor="red"
              p={0.5}
              borderRadius="50%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              width={10}
              height={10}
            >
              <Typography
                variant="caption"
                fontWeight={660}
                color="white"
                textAlign="center"
              >
                {selectedDate &&
                  MonthCounts(moment(selectedDate.toString()).format("YYYYMM"))[
                    props.day.date() - 1
                  ]}
              </Typography>
            </Box>
          ) : undefined
        }
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  };
  const initialValue = dayjs(new Date());

  const fetchDays = ({ signal }: { signal: AbortSignal }) => {
    return new Promise<{ daysToHighlight: number[] }>((resolve, reject) => {
      const timeout = setTimeout(() => {
        const daysToHighlight = dailyFollowupCount.map((item) =>
          parseInt(item.date.split("-")[2])
        );

        resolve({ daysToHighlight });
      }, 500);

      signal.onabort = () => {
        clearTimeout(timeout);
        reject(new DOMException("aborted", "AbortError"));
      };
    });
  };

  const handleChange = (date: Dayjs) => {
    setSelectedDate(date);
    setShowCalendar(false);
    followUpThreadForEnrolmentId(
      null,
      false,
      moment(date.toString()).format("YYYY-MM-DD")
    );
  };

  useEffect(() => {
    fetchDailyCount(moment(initialValue.toString()).format("YYYYMM"), 1);
    followUpThreadForEnrolmentId(
      null,
      false,
      moment(initialValue.toString()).format("YYYY-MM-DD")
    );
  }, []);

  const fetchHighlightedDays = () => {
    const controller = new AbortController();
    fetchDays({
      signal: controller.signal,
    })
      .then(({ daysToHighlight }) => {
        setHighlightedDays(daysToHighlight);
        setIsLoading(false);
      })
      .catch((error) => {
        setHighlightedDays([]);
        setIsLoading(false);
        // ignore the error if it's caused by `controller.abort`
        if (error.name !== "AbortError") {
          throw error;
        }
      });

    requestAbortController.current = controller;
  };

  useEffect(() => {
    // if (dailyFollowupCount.length > 0) {
    fetchHighlightedDays();
    // abort request on unmount
    return () => requestAbortController.current?.abort();
    // }
  }, [dailyFollowupCount]);

  const handleMonthChange = (date: Dayjs) => {
    if (requestAbortController.current) {
      requestAbortController.current.abort();
    }
    followUpThreadForEnrolmentId(
      null,
      false,
      moment(date.toString()).format("YYYY-MM-DD")
    );
    fetchDailyCount(moment(date.toString()).format("YYYYMM"), 1);
    setHighlightedDays([]);
    setSelectedDate(date);
    setIsLoading(true);

    fetchHighlightedDays();
  };

  const handleViewFollowupHistory = (value: boolean, id: number) => {
    setViewHistory(value);
    setSelectedThread(id);
    fetchFollowUpForThread(id, false);
  };
  const getThreadDetails = (id: number) => {
    const threadDetails = followUpThreads.find((thread) => thread.id == id);
    return threadDetails ? threadDetails : ({} as FollowupThreadType);
  };
  const handleOpenConfirmModal = (id: number | null, value: boolean) => {
    setOpenConfirmModal(value);
    setSelectedThread(id);
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-around">
        <Typography marginTop="-10px" variant="subtitle1" fontWeight={550}>
          Date:{" "}
          {selectedDate && moment(selectedDate.toString()).format("DD-MM-YYYY")}
        </Typography>
        <Typography marginTop="-10px" variant="subtitle1" fontWeight={550}>
          Count: {followupThreadCount}
        </Typography>
        <IconButton
          sx={{ marginTop: "-10px" }}
          onClick={() => setShowCalendar(!showCalendar)}
        >
          <CalendarMonthIcon color="secondary" />
        </IconButton>
      </Stack>
      {showCalendar && (
        <div style={wrapperStyle}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              defaultValue={initialValue}
              loading={isLoading}
              value={selectedDate && selectedDate}
              onChange={(newValue) => handleChange(newValue)}
              onMonthChange={handleMonthChange}
              renderLoading={() => <DayCalendarSkeleton />}
              slots={{
                day: ServerDay,
              }}
              slotProps={{
                day:
                  highlightedDays.length > 0
                    ? ({
                        highlightedDays,
                      } as any)
                    : ({} as any),
              }}
            />
          </LocalizationProvider>
        </div>
      )}
      <div style={{ overflow: "scroll", height: "70vh" }}>
        {fetchFollowUpThreadState === FetchState.LOADING && <ListLoading />}
        {fetchFollowUpThreadState === FetchState.SUCCESS && !viewHistory && (
          <Stack gap={2} marginTop={3}>
            {followUpThreads.length > 0 ? (
              followUpThreads.map((followupThread) => {
                return (
                  <ViewFollowUpThread
                    handleViewHistory={handleViewFollowupHistory}
                    followupThread={followupThread}
                    handleOpenConfirmModal={handleOpenConfirmModal}
                  />
                );
              })
            ) : (
              <Typography fontWeight={660} variant="subtitle2">
                No records found
              </Typography>
            )}
          </Stack>
        )}
        {fetchFollowUpsForThreadState === FetchState.LOADING && <ListLoading />}
        {viewHistory && fetchFollowUpsForThreadState == FetchState.SUCCESS && (
          <>
            <Stack
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              marginBottom={2}
            >
              <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
                <IconButton size="small" onClick={() => setViewHistory(false)}>
                  <ArrowBackIosRoundedIcon sx={{ fontSize: "15px" }} />
                </IconButton>

                <Typography variant="subtitle1" fontWeight={660}>
                  {selectedThread &&
                    getThreadDetails(selectedThread) &&
                    toTitleCase(getThreadDetails(selectedThread).title)}
                </Typography>
              </Stack>
              <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                {addFollowupForThread && (
                  <IconButton
                    sx={{ border: "1px solid lightGrey", borderRadius: 2 }}
                    onClick={() => setAddFollowupForThread(false)}
                  >
                    <CancelRoundedIcon fontSize="small" color="error" />
                  </IconButton>
                )}
                {!addFollowupForThread &&
                  selectedThread &&
                  getThreadDetails(selectedThread).status == "active" && (
                    <IconButton
                      sx={{ border: "1px solid lightGrey", borderRadius: 2 }}
                      onClick={() => setAddFollowupForThread(true)}
                    >
                      <AddCircleIcon fontSize="small" color="primary" />
                    </IconButton>
                  )}
              </Stack>
            </Stack>

            <ViewFollowupHistory
              addFollowup={addFollowupForThread}
              handleOpenAddFollowup={() => setAddFollowupForThread(false)}
            />
          </>
        )}
        {openConfirmModal && (
          <ConfirmModal
            openConfirmationModal={openConfirmModal}
            handleCloseConfirmationModal={() =>
              handleOpenConfirmModal(null, false)
            }
            message="Are you sure you want to close this followup thread?"
            handleSuccessCallback={() =>
              closeFollowUpThread(
                selectedThread,
                handleOpenConfirmModal,
                moment(selectedDate?.toString()).format("YYYY-MM-DD")
              )
            }
            loading={closeFollowUpThreadLoading}
          />
        )}
      </div>
    </>
  );
};
export default AllFollowUpsPanel;
