import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import React from "react";

import { PatientAddressType } from "../../../../../domain/models/personal-details-type";

type Props = {
  open: boolean;
  handleClose: Function;
  data: PatientAddressType;
  successCallback: Function;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const DeactivateAddressConfirmModal: React.FC<Props> = ({
  open,
  handleClose,
  data,
  successCallback,
}) => {
  return (
    <Modal open={open} onClose={() => handleClose()}>
      <Box sx={style}>
        <Typography>
          {`Are you sure you want to deactivate ${data.type} address`}
        </Typography>

        <Stack
          marginTop={2}
          direction={"row"}
          justifyContent={"end"}
          alignItems={"center"}
          spacing={2}
        >
          <Button
            variant="contained"
            color={"error"}
            onClick={() => handleClose()}
          >
            <Typography textTransform={"none"} fontSize={"caption"}>
              Close
            </Typography>
          </Button>
          <Button
            variant="contained"
            color={"secondary"}
            onClick={() => successCallback(data)}
          >
            <Typography textTransform={"none"} fontSize={"caption"}>
              Confirm
            </Typography>
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default DeactivateAddressConfirmModal;
