import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useOrderDetailsStore } from "../../../store/stockistPageStore/orderStore/orderDetailsStore";
import { Status } from "../../../constants/enums/status";
import CheckIcon from "@mui/icons-material/Check";
import { LoadingButton } from "@mui/lab";

type Props = {
  openConfirmationModal: boolean;
  handleCloseConfirmationModal: Function;
  handleSuccessCallback: Function;
  message: string;
  loading: boolean;
};

const style = {
  borderRadius: "12px",
  display: "flex",
  gap: 2,
  flexDirection: "column",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ConfirmModal: React.FC<Props> = ({
  openConfirmationModal,
  handleCloseConfirmationModal,
  handleSuccessCallback,
  message,
  loading,
}) => {
  return (
    <Modal
      open={openConfirmationModal}
      onClose={() => handleCloseConfirmationModal()}
    >
      <Box sx={style}>
        <Typography>{message}</Typography>
        <Stack
          flexDirection={"row"}
          gap={1}
          alignItems={"center"}
          justifyContent={"end"}
        >
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={() => handleSuccessCallback()}
          >
            <Typography textTransform={"none"}>Confirm</Typography>
          </LoadingButton>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleCloseConfirmationModal()}
          >
            <Typography textTransform={"none"}>Cancel</Typography>
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
export default ConfirmModal;
