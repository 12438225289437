import { StateCreator } from "zustand";
import {
  COMMON_AUTH_HEADER,
  FULFILLMENT_SERVICE_API_URL,
  FULFILLMENT_SERVICE_TOKEN,
} from "../../../../base";
import Endpoints from "../../../../domain/endpoints";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import Swal from "sweetalert2";
import { LocalJsonStorage } from "../../../../infra/http/local-json-storage";
import { RemoteCancelOrder } from "../../../../data/usecases/remote-cancel-order";
import { CancelOrder } from "../../../../domain/usages/cancel-order";

interface CancelOrderSliceType {
  cancelOrder: Function;
  cancelOrderLoading: boolean;
  handleCancelOrderLoading: Function;
}

const initialStates = {
  cancelOrderLoading: false,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useCancelOrderSlice: StateCreator<CancelOrderSliceType> = (
  set,
  get
) => ({
  ...initialStates,
  handleCancelOrderLoading: (value: boolean) => {
    if (value) {
      Swal.fire({
        didOpen: () => {
          Swal.showLoading();
        },
        title:
          "Please wait... Your request is getting processed. This might take a few moments. Thank you for your patience! 😊",
        timerProgressBar: true,
      });
    }
  },
  cancelOrder: async (payload: CancelOrder.Params) => {
    axiosHttpClient.setAuthHeaders({
      [COMMON_AUTH_HEADER]: `${FULFILLMENT_SERVICE_TOKEN}`,
    });

    const remoteCancelOrder: CancelOrder = new RemoteCancelOrder(
      `${FULFILLMENT_SERVICE_API_URL}${Endpoints.CHANGE_ORDER_STATUS}`,
      axiosHttpClient
    );
    get().handleCancelOrderLoading(true);
    let result = await remoteCancelOrder.cancel(payload);
    if (result.success) {
      get().handleCancelOrderLoading(false);
      Swal.fire({
        title: "Order Canceled Successfully",
        icon: "success",
        timer: 3000,
        customClass: {
          container: "my-custom-zindex",
        },
      });
      return result;
    } else {
      get().handleCancelOrderLoading(false);
      if (result.message) {
        Swal.fire({
          title: result.message,
          icon: "error",
          customClass: {
            container: "my-custom-zindex",
          },
        });
      } else {
        Swal.fire({
          title: "Order Cancelation Failed",
          icon: "error",

          customClass: {
            container: "my-custom-zindex",
          },
        });
      }
    }
  },
});
