import { PackOrder } from "../../domain/usages/pack-order";
import { HttpConstants } from "../protocols/http/http-constants";
import { HttpPutClient } from "../protocols/http/http-put-client";

export class RemotePackOrder implements PackOrder {
  constructor(
    private readonly url: string,
    private readonly httpPutClient: HttpPutClient
  ) {}
  async put(id: number, params?: PackOrder.Params): Promise<any> {
    const httpResponse = await this.httpPutClient.put({
      url: this.url.replace(":id", id.toString()),
      body: {},
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (httpResponse.status === 200) {
      return httpResponse.data;
    }
  }
}
