import { Stack, Typography } from "@mui/material";
import React from "react";
import { Constant } from "../../../../../constants/constant";

interface AppointmentCardHeaderProps {
  name: string;
  id: string;
  date: string;
}

const AppointmentCardHeader: React.FC<AppointmentCardHeaderProps> = ({
  name,
  id,
  date,
}) => {
  return (
    <Stack
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      padding={"10px 15px 10px 15px"}
      borderBottom={"1px solid #E8E6E3"}
      minHeight={"30px"}
    >
      <Stack display={"flex"} justifyContent={"center"}>
        <Typography fontSize={"16px"} fontWeight={600} lineHeight={1}>
          {name}
        </Typography>
        <Typography
          fontSize={"10px"}
          color={Constant.TEXT_GREY_COLOR}
          lineHeight={1}
          fontWeight={"400"}
        >
          #{id}
        </Typography>
      </Stack>
      <Stack>
        <Typography
          fontSize={"14px"}
          fontWeight={500}
          color={Constant.TEXT_GREY_COLOR}
        >
          {date}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default AppointmentCardHeader;
