import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { toTitleCase } from "../../../../../domain/Utils";
import { useProgramDetailStore } from "../../../../../store/adminPageStore/patientDetailsStore/programDetailsStore/programDetailsStore";

type TacTestData = { type: string; value: number };
type Props = {
  handleAddTacData: Function;
};
const AddNewTacTestData: React.FC<Props> = ({ handleAddTacData }) => {
  const { handleUpdateTacTestData } = useProgramDetailStore();
  const [testData, setTestData] = useState<TacTestData[]>([
    { type: "foc", value: 0 },
    { type: "discounted", value: 0 },
  ]);

  const handleChangeTestData = (name: string, value: string) => {
    const newValue = parseFloat(value);
    setTestData((prevTestData) =>
      prevTestData.map((item) =>
        item.type === name ? { ...item, value: newValue ? newValue : 0 } : item
      )
    );
  };

  const saveTacTestData = () => {
    let payload = {
      meta_data: {
        tac_test: testData,
      },
    };
    handleUpdateTacTestData(payload);
  };

  return (
    <>
      <Stack
        flexDirection={"row"}
        gap={1}
        alignItems={"center"}
        justifyContent={"end"}
        marginBottom={1}
      >
        <Button
          variant="outlined"
          color="error"
          size="small"
          onClick={() => handleAddTacData(false)}
        >
          <Typography textTransform={"none"} color={"error.main"}>
            Close
          </Typography>
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => saveTacTestData()}
        >
          <Typography textTransform={"none"} color={"white"}>
            Save Details
          </Typography>
        </Button>
      </Stack>
      <TableContainer>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                sx={{
                  border: "1px solid lightGrey",
                  backgroundColor: "secondary.main",
                }}
              >
                <Typography fontWeight={660} variant="caption" color={"white"}>
                  Type
                </Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  border: "1px solid lightGrey",
                  backgroundColor: "secondary.main",
                }}
              >
                <Typography fontWeight={660} variant="caption" color={"white"}>
                  Count{" "}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {testData.map((data, index) => {
              return (
                <TableRow key={index + 1}>
                  <TableCell
                    align="center"
                    sx={{ border: "1px solid lightGrey" }}
                  >
                    <Typography variant="caption">
                      {toTitleCase(data.type)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ border: "1px solid lightGrey" }}
                  >
                    <TextField
                      inputProps={{
                        min: 0,
                        type: "number",
                      }}
                      onWheel={(e: React.WheelEvent<HTMLInputElement>) => {
                        (e.target as HTMLInputElement).blur();
                      }}
                      value={data.value ? data.value : ""}
                      size="small"
                      onChange={(e) =>
                        handleChangeTestData(data.type, e.target.value)
                      }
                    />
                  </TableCell>
                </TableRow>
              );
            })}

            {/* <TableRow>
              <TableCell align="center" sx={{ border: "1px solid lightGrey" }}>
                <Typography variant="caption">Discounted</Typography>
              </TableCell>
              <TableCell align="center" sx={{ border: "1px solid lightGrey" }}>
                <TextField
                  size="small"
                  onChange={(e) =>
                    handleChangeTestData("discounted", e.target.value)
                  }
                />
              </TableCell>
            </TableRow> */}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AddNewTacTestData;
