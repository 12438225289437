import dayjs, { Dayjs } from "dayjs";
import { StateCreator } from "zustand";

interface CommonSliceType {
  handleAddAppointmentClick: Function;
  openCreateAppointment: boolean;
  showAllAppointments: boolean;
  handleShowAllAppointments: Function;
  appointmentDate: Dayjs;
  setAppointmentDate: Function;
  appointmentStatus: string;
  setAppointmentStatus: Function;
}

const initialStates = {
  openCreateAppointment: false,
  showAllAppointments: false,
  appointmentDate: dayjs(new Date()),
  appointmentStatus: "booked",
};

export const useCommonSlice: StateCreator<CommonSliceType> = (set, get) => ({
  ...initialStates,
  handleAddAppointmentClick: (value: boolean) => {
    set(() => ({ openCreateAppointment: !value }));
  },
  handleShowAllAppointments: (value?: boolean) => {
    set(() => ({
      showAllAppointments: value ? value : !get().showAllAppointments,
    }));
  },
  setAppointmentDate: (date: Dayjs) => {
    set(() => ({
      appointmentDate: date,
    }));
  },
  setAppointmentStatus: (status: string) => {
    set(() => ({
      appointmentStatus: status,
    }));
  },
});
