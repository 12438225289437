import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab, Typography } from "@mui/material";
import React from "react";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { useOrderDetailsStore } from "../../../store/stockistPageStore/orderStore/orderDetailsStore";
import ListLoading from "../Loading/ListLoading";
import OrderCardList from "./order-page/OrderCardList";
import OrderFilter from "../filters/OrderFIlter";

type Props = {
  handleShowDemoRequest: Function;
  handleShowOrder: Function;
};

const OrderDemoRequestTab: React.FC<Props> = ({
  handleShowDemoRequest,
  handleShowOrder,
}) => {
  const { fetchOrderState } = useOrderDetailsStore();
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <TabList onChange={handleChange} centered>
        <Tab
          onClick={() => handleShowOrder()}
          label={
            <Typography variant="button" fontWeight={550}>
              Orders
            </Typography>
          }
          value="1"
        />
        <Tab
          onClick={() => handleShowDemoRequest()}
          label={
            <Typography variant="button" fontWeight={550}>
              DEMO REQUESTS
            </Typography>
          }
          value="2"
        />
      </TabList>
      <TabPanel value="1">
        <OrderFilter />
        {fetchOrderState === FetchState.LOADING && <ListLoading />}
        {fetchOrderState === FetchState.SUCCESS && <OrderCardList />}
      </TabPanel>
      <TabPanel value="2">
        <Typography marginLeft={4} marginTop={2} variant="h6">
          Demo Request Details
        </Typography>
      </TabPanel>
    </TabContext>
  );
};
export default OrderDemoRequestTab;
