import { StateCreator } from "zustand";
import { CAPTCHA_HEADER, PSP_PROGRAM_SERVICE_API_URL } from "../../../../base";
import Endpoints from "../../../../domain/endpoints";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { RemoteMasterDropdownData } from "../../../../data/usecases/remote-master-dropdown-data";
import { MasterDropdownData } from "../../../../domain/usages/master-dropdown-data";
import { MasterDropdownDataType } from "../../../../domain/models/masterDropdownData";

interface MasterDropdownDataSliceType {
  fetchMasterDropdownData: Function;
  masterDropdownData: MasterDropdownDataType;
  masterDropdownDataLoading: boolean;
}
const initialStates = {
  masterDropdownData: {} as MasterDropdownDataType,
  masterDropdownDataLoading: false,
};

const axiosHttpClient = AxiosHttpClient.getInstance();

export const useMasterDropdownDataSlice: StateCreator<
  MasterDropdownDataSliceType
> = (set, get) => ({
  ...initialStates,
  fetchMasterDropdownData: async (id: number, captcha: string) => {
    axiosHttpClient.setAuthCaptcha({ [CAPTCHA_HEADER]: captcha });

    const remoteMasterDropdownData: MasterDropdownData =
      new RemoteMasterDropdownData(
        `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.MASTER_DROPDOWN_DATA}`,
        axiosHttpClient
      );
    set(() => ({ masterDropdownDataLoading: true }));
    let result = await remoteMasterDropdownData.fetch(id.toString());
    if (result.success) {
      set(() => ({
        masterDropdownData: result.master_data,
        masterDropdownDataLoading: false,
      }));
    } else if (result == 404) {
      get().fetchMasterDropdownData(id.toString());
    }
  },
});
