import { GenerateDeliveryChallan } from "../../domain/usages/generate-delivery-challan";
import { GenerateSignedUrlForDocumentUpload } from "../../domain/usages/generate-signed-url-for-document-upload";
import { HttpConstants } from "../protocols/http/http-constants";
import { HttpDownloadClient } from "../protocols/http/http-download-client";
import { HttpPostClient } from "../protocols/http/http-post-client";

export class RemoteGenerateDeliveryChallan implements GenerateDeliveryChallan {
  constructor(
    private readonly url: string,
    private readonly httpDownloadClient: HttpDownloadClient
  ) {}

  async generate(params: GenerateDeliveryChallan.Params): Promise<any> {
    const httpResponse = await this.httpDownloadClient.download({
      url: this.url,
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: false,
    });
    if (httpResponse.status == 200) {
      return httpResponse.data;
    }
  }
}
