import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
  PSP_ORDER_SERVICE,
} from "../../../base";
import { RemoteDispatchOrder } from "../../../data/usecases/remote-dispatch-order";
import Endpoints from "../../../domain/endpoints";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { useOrderDetailsStore } from "./orderDetailsStore";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { DispatchOrder } from "../../../domain/usages/dispatch-order";
import { LocalLoggedInUser } from "../../../data/usecases/local-logged-in-user";

interface DispatchOrderSliceType {
  onDispatch: Function;
}
const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useDispatchOrderSlice: StateCreator<
  DispatchOrderSliceType
> = () => ({
  onDispatch: async (payload: DispatchOrder.Params) => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getPSPToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token });

    const remoteDispatchOrder: DispatchOrder = new RemoteDispatchOrder(
      `${PSP_ORDER_SERVICE}${Endpoints.DISPATCH_ORDER}`,
      axiosHttpClient
    );

    let result = await remoteDispatchOrder.put(
      useOrderDetailsStore.getState().selectedOrder?.id,
      payload
    );
    if (result.success) {
      useOrderDetailsStore.getState().reloadPage();
      Swal.fire({ title: result?.message, icon: "success", timer: 3000 });
    } else {
      Swal.fire({ title: result?.errors?.message, icon: "error", timer: 3000 });
    }
  },
});
