import { StateCreator } from "zustand";
import { FetchState } from "../../../../domain/models/fetch-state-type";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import Endpoints from "../../../../domain/endpoints";
import { COMMON_AUTH_HEADER, FULFILLMENT_SERVICE_API_URL, FULFILLMENT_SERVICE_TOKEN } from "../../../../base";
import { RemoteGetReadLink } from "../../../../data/usecases/remote-get-read-link";
import { GetReadLink } from "../../../../domain/usages/get-read-link";


interface GetReadLinkSliceInterface {
  getImage: Function;
  fetchImageState: FetchState;
  imageUrl: string
}

const initialStates = {
  fetchImageState: FetchState.DEFAULT,
  imageUrl:""
};

const axiosHttpClient = AxiosHttpClient.getInstance();

export const useGetReadLinkSlice: StateCreator<GetReadLinkSliceInterface> = (
  set
) => ({
  ...initialStates,
  getImage: async (
    uuid: string,
  ) => {
    set(() => ({ fetchImageState: FetchState.LOADING }));
    axiosHttpClient.setAuthHeaders({
      [COMMON_AUTH_HEADER]: `${FULFILLMENT_SERVICE_TOKEN}`,
    });

    const remoteGetReadLink: GetReadLink =
      new RemoteGetReadLink(
        `${FULFILLMENT_SERVICE_API_URL}${Endpoints.FULLFILLMENT_GET_READ_LINK}`,
        axiosHttpClient
      );
      try {
        set(() => ({ fetchImageState: FetchState.LOADING }));
        if (uuid) {
          let result = await remoteGetReadLink.get(uuid);
          if (result.status) {
       
            set(() => ({
              imageUrl: result.data.url,
              fetchImageState: FetchState.SUCCESS,
            }));
          }else{
            set(() => ({
              fetchImageState: FetchState.ERROR,
            }));
          }
        } 
      } catch (err) {
        set(() => ({ fetchImageState: FetchState.ERROR }));
      }
    
  },
});
