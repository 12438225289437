import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    secondary: {
      main: "#3F51B7",
    },
    info: {
      main: "#667085",
      light: "#6B6B6B",
    },
    warning: {
      main: "#FFE9CE",
      dark: "#FF9600",
    },
  },
});

export default theme;
