import React, { useState, useRef, useEffect } from "react";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";

interface CustomImageCropperProps {
  imageUrl: string;
  onCrop: (croppedImageUrl: string) => void;
  onClose: () => void;
  loading: boolean;
}

const CustomImageCropper: React.FC<CustomImageCropperProps> = ({
  imageUrl,
  onCrop,
  onClose,
  loading,
}) => {
  const [crop, setCrop] = useState<Crop>({
    unit: "%",
    width: 50,
    height: 50,
    x: 0,
    y: 0,
  });

  const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [cropDone, setCropDone] = useState(false);

  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (imgRef.current) {
      setImageRef(imgRef.current);
    }
  }, [imgRef.current]);

  const getCroppedImg = () => {
    if (imageRef && crop.width && crop.height) {
      const canvas = document.createElement("canvas");
      const scaleX = imageRef.naturalWidth / imageRef.width;
      const scaleY = imageRef.naturalHeight / imageRef.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");

      if (!ctx) return;

      ctx.drawImage(
        imageRef,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      canvas.toBlob((blob) => {
        if (blob) {
          const croppedImageUrl = URL.createObjectURL(blob);
          setCroppedImage(croppedImageUrl);
          setCropDone(true);
        }
      });
    }
  };

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>
        Crop Image
        <IconButton
          style={{ position: "absolute", right: 16, top: 16 }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" fontWeight={660}>
          Original Image
        </Typography>
        <ReactCrop
          crop={crop}
          onChange={(newCrop) => setCrop(newCrop)}
          aspect={undefined} // Allow free dragging and resizing
        >
          <img
            ref={imgRef}
            src={imageUrl}
            alt="Crop"
            style={{ maxWidth: "100%" }}
          />
        </ReactCrop>
        {croppedImage && (
          <div style={{ marginTop: 16 }}>
            <Typography variant="subtitle1" fontWeight={660}>
              Cropped Image
            </Typography>
            <img
              src={croppedImage}
              alt="Cropped"
              style={{ maxWidth: "100%" }}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {!cropDone && <Button onClick={onClose}>Cancel</Button>}

        <Button onClick={getCroppedImg} color="primary">
          Crop
        </Button>
        {cropDone && (
          <>
            <Button onClick={onClose} disabled={loading}>
              Close
            </Button>
            <LoadingButton
              loading={loading}
              onClick={() => onCrop(croppedImage!)}
              color="primary"
            >
              Submit
            </LoadingButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CustomImageCropper;
