import { create } from "zustand";
import { useFetchProgramSlice } from "./fetchProgramSlice";
import { useUpdateProgramSlice } from "./updateProgramSlice";
import { useUpdateTacTestSlice } from "./updateTacTestDataSlice";
import { useMasterDropdownDataSlice } from "./masterDropdownDataSlice";


interface ProgramDetailStoreInterface
  extends ReturnType<typeof useFetchProgramSlice>,
    ReturnType<typeof useUpdateProgramSlice>,
    ReturnType<typeof useUpdateTacTestSlice>,
    ReturnType<typeof useMasterDropdownDataSlice> {}

export const useProgramDetailStore = create<ProgramDetailStoreInterface>(
  (...programDetail) => ({
    ...useFetchProgramSlice(...programDetail),
    ...useUpdateProgramSlice(...programDetail),
    ...useUpdateTacTestSlice(...programDetail),
    ...useMasterDropdownDataSlice(...programDetail)
  })
);
