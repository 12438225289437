import { StateCreator } from "zustand";
import { FetchState } from "../../domain/models/fetch-state-type";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import {
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
  APPOINTMENT_SERVICE_STAGING_URL,
} from "../../base";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import Endpoints from "../../domain/endpoints";
import { GetReadLink } from "../../domain/usages/get-read-link";
import { RemoteGetReadLink } from "../../data/usecases/remote-get-read-link";
import { LocalLoggedInUser } from "../../data/usecases/local-logged-in-user";

interface GetReadLinkSliceInterface {
  getImageFromDoctorService: Function;
  fetchImageState: FetchState;
}

const initialStates = {
  fetchImageState: FetchState.DEFAULT,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useDoctorServiceGetReadLinkSlice: StateCreator<
  GetReadLinkSliceInterface
> = (set) => ({
  ...initialStates,
  getImageFromDoctorService: async (uuid: string) => {
    set(() => ({ fetchImageState: FetchState.LOADING }));

    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token });

    const remoteGetReadLink: GetReadLink = new RemoteGetReadLink(
      `${APPOINTMENT_SERVICE_STAGING_URL}${Endpoints.GET_READ_LINK}`,
      axiosHttpClient
    );
    try {
      if (uuid) {
        let result = await remoteGetReadLink.get(uuid);
        if (result.status) {
          set(() => ({
            fetchImageState: FetchState.SUCCESS,
          }));

          return result.data.url;
        } else {
          set(() => ({
            fetchImageState: FetchState.ERROR,
          }));
          return "";
        }
      }
    } catch (err) {
      set(() => ({ fetchImageState: FetchState.ERROR }));
      return "";
    }
  },
});
