import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Modal,
  Popper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";
import { OrderItems } from "../../../../../domain/models/get-order-type";
import { useOrderDetailsStore } from "../../../../../store/stockistPageStore/orderStore/orderDetailsStore";
import { ActionButton } from "../../../../../custom-theme/ActionButton";
import { Status } from "../../../../../constants/enums/status";
import CreateDemoRequestModal from "../../../modals/CreateDemoRequestModal";
import { useDemoRequestStore } from "../../../../../store/stockistPageStore/demoRequestStore/demoRequestStore";
import { Constant } from "../../../../../constants/constant";
import ConfirmationModal from "../../../modals/ConfirmationModal";
import LoadingModal from "../../../modals/LoadingModal";

import dayjs from "dayjs";

type FormError = {
  batch_no: string[];
  quantity: string[];
};

const initialProductError = {
  batch_no: [],
  quantity: [],
};

const OrderSummary: React.FC = () => {
  const {
    selectedOrder,
    handleSaveChange,
    handleShowSaveButton,
    showSaveButton,
    handlePackOrder,
    toBePacked,
  } = useOrderDetailsStore();
  const { showCreateDemo } = useDemoRequestStore();
  const {
    generateAddressLabel,
    generateDeliveryChallan,
    loadingDeliveryChallan,
    loadingAddressLabel,
  } = useOrderDetailsStore();

  const [openCreateDemoRequestModal, setOpenCreateDemoRequestModal] =
    useState(false);
  const handleOpen = () => setOpenCreateDemoRequestModal(true);
  const handleClose = () => setOpenCreateDemoRequestModal(false);
  const [productData, setProductData] = useState<OrderItems[]>([]);
  const [productFormErrors, setProductFormErrors] =
    useState<FormError>(initialProductError);
  const [openConfirmationModal, setOpenConfirmationModal] =
    React.useState(false);
  const handleOpenConfirmationModal = () => setOpenConfirmationModal(true);
  const handleCloseConfirmationModal = () => setOpenConfirmationModal(false);
  const handleChangeInput = (index: number, name: string, value: string) => {
    if (name == "batch_no") {
      productFormErrors.batch_no[index] = "";
    } else if (name == "quantity") {
      productFormErrors.quantity[index] = "";
    }
    handleShowSaveButton(true);
    const values: any[] = [...productData];
    values[index][name] = value;
    setProductData(values);
  };

  const handlePacking = () => {
    let isValid = true;
    productData.forEach((row, index) => {
      const { batch_no, quantity } = row;
      const newOrderItem = { batch_no, quantity };
      Object.entries(newOrderItem).forEach(([key, value]) => {
        switch (key) {
          case "batch_no":
            if (value == "" || value == null) {
              isValid = false;
              productFormErrors.batch_no[index] = Constant.FIELD_REQUIRED;
            }
            break;
          case "quantity":
            if (value == "" || value == null) {
              isValid = false;
              productFormErrors.quantity[index] = Constant.FIELD_REQUIRED;
            }
            break;
        }
      });
    });
    setProductFormErrors({ ...productFormErrors });
    if (isValid) {
      handlePackOrder();
    } else {
      setOpenConfirmationModal(false);
    }
  };

  useEffect(() => {
    setProductData(
      selectedOrder?.items?.map((item: OrderItems) => ({ ...item }))
    );
  }, [selectedOrder.items]);

  useEffect(() => {
    if (productFormErrors) {
      document
        .querySelector(".Mui-error")
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [productFormErrors]);

  const tableHeaders = [
    "Product",
    "Qty",
    "Batch Number",
    "Unit Price",
    "Amount",
  ];

  return (
    <Box>
      <Stack
        mb={3}
        alignItems={"center"}
        direction={"row"}
        justifyContent="space-between"
      >
        <Typography variant="subtitle1">Order Details</Typography>
        <Stack alignItems={"center"} direction={"row"} gap={5}>
          {selectedOrder.status === Status.PENDING && (
            <ActionButton size="small" onClick={handleOpenConfirmationModal}>
              <Typography
                variant="body2"
                padding={0.2}
                textTransform={"capitalize"}
              >
                Accept Order
              </Typography>
            </ActionButton>
          )}
          {selectedOrder.status === Status.ACCEPTED && !showSaveButton && (
            <ActionButton
              size="small"
              onClick={() => handleOpenConfirmationModal()}
            >
              <Typography
                variant="body2"
                padding={0.2}
                textTransform={"capitalize"}
              >
                Pack Order
              </Typography>
            </ActionButton>
          )}
          <ConfirmationModal
            openConfirmationModal={openConfirmationModal}
            handleCloseConfirmationModal={handleCloseConfirmationModal}
            handlePacking={handlePacking}
          />
          {selectedOrder.status === Status.ACCEPTED && showSaveButton && (
            <ActionButton
              size="small"
              onClick={() => {
                handleSaveChange(productData);
              }}
            >
              <Typography
                variant="body2"
                padding={0.2}
                textTransform={"capitalize"}
              >
                Save Changes
              </Typography>
            </ActionButton>
          )}
          {selectedOrder.status === Status.DISPATCHED && (
            <ActionButton
              size="small"
              onClick={() => {
                generateDeliveryChallan();
              }}
            >
              <Typography
                variant="body2"
                padding={0.2}
                textTransform={"capitalize"}
              >
                Generate Delivery Challan
              </Typography>
            </ActionButton>
          )}
          {selectedOrder.status !== Status.PENDING && showCreateDemo && (
            <ActionButton
              onClick={() => handleOpen()}
              variant="contained"
              size="small"
            >
              <Typography
                variant="body2"
                padding={0.2}
                textTransform={"capitalize"}
              >
                Create Demo Request
              </Typography>
            </ActionButton>
          )}
          <Button
            onClick={() => {
              generateAddressLabel();
            }}
            size="small"
            variant="text"
            color="secondary"
          >
            <Typography variant="button" fontWeight={550}>
              Address Label
            </Typography>
          </Button>
          <LoadingModal openModal={loadingDeliveryChallan} />
          <LoadingModal openModal={loadingAddressLabel} />
        </Stack>
      </Stack>
      <Card
        variant="outlined"
        sx={{
          borderRadius: " 12px",
        }}
      >
        <Box>
          <Stack
            direction={"row"}
            borderBottom={"1px solid rgba(221, 221, 221, 0.5)"}
            padding={"0.625rem 0 .2rem 1.5rem"}
            gap={15}
          >
            <Stack direction={"column"} marginBottom={3}>
              <Typography variant="subtitle2" color={"text.secondary"}>
                Order ID
              </Typography>
              <Typography>{selectedOrder.id}</Typography>
            </Stack>
            <Stack direction={"column"}>
              <Typography variant="subtitle2" color={"text.secondary"}>
                Date
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD/MM/YYYY"
                  onChange={() => {}}
                  value={dayjs(selectedOrder.order_date)}
                  readOnly={true}
                />
              </LocalizationProvider>
            </Stack>
          </Stack>
          <Box mb={2}>
            <TableContainer
              sx={{ height: 200, overFlow: "scroll", position: "relative" }}
            >
              <Table>
                <TableHead
                  sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    bgcolor: "white",
                  }}
                >
                  <TableRow>
                    {tableHeaders.map((item: string, index: number) => (
                      <TableCell key={index} sx={{ border: "none" }}>
                        <Typography
                          variant="subtitle2"
                          color={"text.secondary"}
                        >
                          {item}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productData &&
                    productData.map((row: OrderItems, index: number) => (
                      <TableRow key={row.id}>
                        <TableCell sx={{ border: "none" }} align="left">
                          <TextField
                            size="small"
                            value={row.item ? row.item : ""}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </TableCell>
                        <TableCell sx={{ border: "none" }} align="left">
                          <TextField
                            name={"quantity"}
                            sx={{ width: "10rem" }}
                            size="small"
                            value={row.quantity ? row.quantity : ""}
                            error={
                              productFormErrors.quantity[index] ? true : false
                            }
                            helperText={productFormErrors.quantity[index]}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleChangeInput(
                                index,
                                event.target.name,
                                event.target.value
                              );
                            }}
                            disabled={
                              selectedOrder.status === Status.ACCEPTED &&
                              toBePacked
                                ? false
                                : true
                            }
                          />
                        </TableCell>
                        <TableCell sx={{ border: "none" }} align="left">
                          <TextField
                            name="batch_no"
                            sx={{ width: "10rem" }}
                            size="small"
                            error={
                              productFormErrors?.batch_no[index] ? true : false
                            }
                            helperText={productFormErrors?.batch_no[index]}
                            value={row.batch_no ? row.batch_no : ""}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleChangeInput(
                                index,
                                event.target.name,
                                event.target.value
                              );
                            }}
                            disabled={
                              selectedOrder.status === Status.ACCEPTED &&
                              toBePacked
                                ? false
                                : true
                            }
                          />
                        </TableCell>
                        <TableCell sx={{ border: "none" }} align="center">
                          {row.unit_price}
                        </TableCell>
                        <TableCell sx={{ border: "none" }} align="center">
                          {row.total_price}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Divider />
          <Stack
            marginRight={3}
            direction={"row"}
            justifyContent={"end"}
            padding={1}
            gap={5}
          >
            <Typography>Sub Total:0</Typography>
            <Typography>Tax:&nbsp;0 </Typography>
            <Typography>
              Grand&nbsp;Total:&nbsp; {selectedOrder?.total_amount}
            </Typography>
          </Stack>
        </Box>
      </Card>
      <CreateDemoRequestModal
        openCreateDemoRequestModal={openCreateDemoRequestModal}
        handleClose={handleClose}
      />
    </Box>
  );
};
export default OrderSummary;
