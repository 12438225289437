import { FetchDoctorSlotsResponse } from "../../../domain/models/appointment-module/fetch-doctor-slots";
import { FetchDoctorSlots } from "../../../domain/usages/appointment-module/fetch-doctor-slots";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpGetClient } from "../../protocols/http/http-get-client";

export class RemoteFetchDoctorSlots implements FetchDoctorSlots {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async fetch(
    doctor_id: number,
    params: FetchDoctorSlots.Params
  ): Promise<FetchDoctorSlotsResponse> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(":doctor_id", doctor_id.toString()),
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

    return httpResponse.data;
  }
}
