import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { SxProps, Theme } from "@mui/system";

interface GaButtonProps {
  text: string;
  onClick: () => void;
  startIcon?: React.ReactNode;
  buttonSx?: SxProps<Theme>;
  textSx?: SxProps<Theme>;
  buttonBackgroundColor?: string;
  textColor?: string;
  isDisabled?: boolean;
}

const GaButton: React.FC<GaButtonProps> = ({
  text,
  onClick,
  textColor,
  startIcon,
  buttonSx,
  textSx,
  buttonBackgroundColor,
  isDisabled,
}) => {
  return (
    <Button
      variant="contained"
      size="small"
      startIcon={startIcon}
      onClick={onClick}
      disabled={isDisabled}
      sx={{
        backgroundColor: buttonBackgroundColor
          ? buttonBackgroundColor
          : "white",
        "&:hover": {
          backgroundColor: buttonBackgroundColor
            ? buttonBackgroundColor
            : "white",
        },
        ...buttonSx,
      }}
    >
      <Typography
        color={textColor}
        textTransform="none"
        fontSize="14px"
        sx={{
          ...textSx,
        }}
      >
        {text}
      </Typography>
    </Button>
  );
};

export default GaButton;
