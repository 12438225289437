import { Grid, List, ListItem } from "@mui/material";
import React from "react";
import { useEnrollmentMainStore } from "../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import EnrollmentCard from "./EnrollmentCard";
import { EnrollmentType } from "../../../domain/models/enrollment-type";

const EnrollmentList: React.FC = () => {
  const { enrollmentList, handleCardClick, selectedCard } =
    useEnrollmentMainStore();

  return (
    <Grid container sx={{paddingInline:1}} spacing={1}>
      <Grid item xs={12}>
        <List>
          {enrollmentList.length > 0 &&
            enrollmentList.map((data: EnrollmentType, index: number) => {
              return (
                <ListItem
                  key={data.id}
                  disablePadding
                  onClick={(e) => handleCardClick(index)}
                >
                  <EnrollmentCard
                    cardData={data}
                    active={
                      data.patient_uuid == selectedCard.patient_uuid
                        ? true
                        : false
                    }
                  />
                </ListItem>
              );
            })}
        </List>
      </Grid>
    </Grid>
  );
}
export default EnrollmentList;
