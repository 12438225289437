import { create } from "zustand";
import { useUploadFileSlice } from "./UploadFileSlice";
import { useDoctorServiceGetReadLinkSlice } from "./getReadUrlSlice";

interface ImageUploadToDoctorServiceStore
  extends ReturnType<typeof useUploadFileSlice>,
    ReturnType<typeof useDoctorServiceGetReadLinkSlice> {}

export const useImageUploadToDoctorServiceStore =
  create<ImageUploadToDoctorServiceStore>()((...common) => ({
    ...useUploadFileSlice(...common),
    ...useDoctorServiceGetReadLinkSlice(...common),
  }));
