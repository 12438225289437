enum OrderStatus {
  CREATED = "created",
  REJECTED = "rejected",
  ACCEPTED = "accepted",
  CANCELED = "cancelled",
  DELIVERED = "delivered",
  DISPATCHED = "dispatched",
  HOLD = "on_hold",
  PACKED = "packing",
  PENDING_LR = "pending_lr",
  RETURN_TO_ORIGIN = "return_to_origin",
  COMPLETED = "completed",
}

export default OrderStatus;
