import { FetchAppointmentByIdResponse } from "../../../domain/models/appointment-module/fetch-appointment-response";
import { FetchAppointmentById } from "../../../domain/usages/appointment-module/get-appointment-by-id";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpGetClient } from "../../protocols/http/http-get-client";

export class RemoteFetchAppointmentById implements FetchAppointmentById {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async get(
    appointment_id: number,
    params?: FetchAppointmentById.Params
  ): Promise<FetchAppointmentByIdResponse> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(":appointment_id", appointment_id.toString()),
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

    return httpResponse.data;
  }
}
