import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { useOrderDetailsStore } from "../orderDetailsStore";
import { RemoteGenerateAddressLabel } from "../../../../data/usecases/remote-generate-address-label";
import {
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
  PSP_GENERATE_PDF,
} from "../../../../base";
import Endpoints from "../../../../domain/endpoints";
import { GenerateAddressLabel } from "../../../../domain/usages/generate-address-label";
import { usePersonalDetailStore } from "../../../adminPageStore/patientDetailsStore/personalDetailStore.ts/personalDetailsStore";
import { LocalJsonStorage } from "../../../../infra/http/local-json-storage";

interface GenerateAddressLabelSliceType {
  generateAddressLabel: Function;
  loadingAddressLabel: boolean;
}

const initialStates = {
  loadingAddressLabel: false,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useGenerateAddressLabelSlice: StateCreator<
  GenerateAddressLabelSliceType
> = (set) => ({
  ...initialStates,
  generateAddressLabel: async () => {
    const remoteGenerateAddressLabel: GenerateAddressLabel =
      new RemoteGenerateAddressLabel(
        `${PSP_GENERATE_PDF}${Endpoints.GENERATE_ADDRESSLABEL}`,
        axiosHttpClient
      );
    const filteredPrimaryAddress = Object.entries(
      useOrderDetailsStore.getState().selectedOrder.address
    ).reduce((acc: any, [key, value]) => {
      if (
        value !== "" &&
        value !== null &&
        key !== "created_at" &&
        key !== "updated_at"
      ) {
        acc[key] = value;
      }
      return acc;
    }, {});
    set(() => ({ loadingAddressLabel: true }));
    let orderDetail = useOrderDetailsStore.getState();
    let payload = {
      ship_to: {
        name: orderDetail.selectedOrder.order_for,
        address: Object.values(filteredPrimaryAddress).join(","),
        contact: orderDetail.selectedOrder.contact_no,
      },
    };

    try {
      const response = await remoteGenerateAddressLabel.generate(payload);
      if (response) {
        set(() => ({ loadingAddressLabel: false }));
      }
      const pdfBlob = new Blob([response], { type: "application/pdf" });
      const url = window.URL.createObjectURL(pdfBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `ORDER-${
        useOrderDetailsStore.getState().selectedOrder.id
      }-ADDRESS-LABEL.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error(error);
    }
  },
});
