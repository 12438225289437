import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
  PSP_ORDER_SERVICE,
} from "../../../base";
import { RemoteGetOrders } from "../../../data/usecases/remote-get-orders";
import Endpoints from "../../../domain/endpoints";
import { EnrollmentFilterType } from "../../../domain/models/enrollment-filter.type";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { Order } from "../../../domain/models/get-order-type";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { useOrderDetailsStore } from "./orderDetailsStore";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { Constant } from "../../../constants/constant";
import { GetOrders } from "../../../domain/usages/get-orders";
import { LocalLoggedInUser } from "../../../data/usecases/local-logged-in-user";

interface FetchOrderSliceType {
  fetchOrders: Function;
  orderList: Order[];
  selectedOrder: Order;
  fetchOrderState: FetchState;
  handleCardClick: Function;
  reloadPage: Function;
  totalOrder: number;
  currentPage: number;
  lastPage: number;
  handleSelectedCard: Function;
}
const initialStates = {
  totalOrder: 0,
  fetchOrderState: FetchState.DEFAULT,
  selectedOrder: {} as Order,
  orderList: [],
  orderFilter: [],
  currentPage: 0,
  lastPage: 1,
};
const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useFetchOrderSlice: StateCreator<FetchOrderSliceType> = (
  set,
  get
) => ({
  ...initialStates,
  fetchOrders: async (
    page: number,
    orderFilter: EnrollmentFilterType,
    scrolled: boolean
  ) => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getPSPToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token });

    const remoteGetOrders: GetOrders = new RemoteGetOrders(
      `${PSP_ORDER_SERVICE}${Endpoints.GET_ORDERS}`,
      axiosHttpClient
    );
    let filter = {
      q: orderFilter.q,
      length: Constant.PAGE_LIMIT,
    };

    try {
      if (!scrolled) {
        set(() => ({ fetchOrderState: FetchState.LOADING }));
      }
      let result = await remoteGetOrders.get(page, filter);
      if (result?.success) {
        set(() => ({
          fetchOrderState: FetchState.SUCCESS,
          currentPage: page,
          lastPage: Math.ceil(result.total / Constant.PAGE_LIMIT),
        }));
        if (scrolled) {
          set(() => ({ orderList: [...get().orderList, ...result.orders] }));
        } else {
          set(() => ({
            orderList: result.orders,
          }));
          get().handleSelectedCard(result.orders);
        }
      } else {
        set(() => ({ fetchOrderState: FetchState.LOADING }));
      }
    } catch (error) {
      set(() => ({ fetchOrderState: FetchState.ERROR }));
    }
  },

  handleSelectedCard: (orders: Order[]) => {
    if (get().selectedOrder.id) {
      const foundOrder = orders.find(
        (order: Order) => order?.id === get().selectedOrder?.id
      );
      set(() => ({ selectedOrder: foundOrder }));
    } else {
      const sortedOrderList = orders.sort((a, b) => (a.id < b.id ? 1 : -1));
      set(() => ({ selectedOrder: sortedOrderList[0] }));
    }
  },

  handleCardClick: (index: number) => {
    set(() => ({ selectedOrder: get().orderList[index] }));
  },
  reloadPage: () => {
    get().fetchOrders(1, useOrderDetailsStore.getState().orderFilter);
  },
});
