import { HttpConstants } from "../protocols/http/http-constants";
import { HttpGetClient } from "../protocols/http/http-get-client";
import { GetOrders } from "../../domain/usages/get-orders";

export class RemoteGetOrders implements GetOrders {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async get(page: number, params?: GetOrders.Params): Promise<any> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(":pageNumber", page.toString()),
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (httpResponse?.status == 200) {
      return httpResponse.data;
    }
  }
}
