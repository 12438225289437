import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../infra/http/axios-http-client";
import {
  CAPTCHA_HEADER,

  PSP_PROGRAM_SERVICE_API_URL,
} from "../../base";
import Endpoints from "../../domain/endpoints";
import { RemoteAcceptPatientConsent } from "../../data/usecases/remote-accept-patient-consent";
import { AcceptPatientConsent } from "../../domain/usages/accept-patient-consent";
import Swal from "sweetalert2";
import { useConsentStore } from "./consentStore";

interface AcceptPatientConsentSliceType {
  acceptPatientConsentSliceType: Function;
  consentLoading: boolean;
}
const initialStates = {
  consentLoading: false,
};

const axiosHttpClient = AxiosHttpClient.getInstance();

export const useAcceptPatientConsent: StateCreator<
  AcceptPatientConsentSliceType
> = (set, get) => ({
  ...initialStates,
  acceptPatientConsentSliceType: async (
    consentToken: string,
    payload: AcceptPatientConsent.Params,
    captcha: string,
    getEnrolmentByConsentTokenDetailsToken: string

  ) => {
  
    axiosHttpClient.setAuthCaptcha({ [CAPTCHA_HEADER]: captcha });


    const remoteAcceptPatientConsent: AcceptPatientConsent =
      new RemoteAcceptPatientConsent(
        `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.ACCEPT_CONSENT_URL}`,
        axiosHttpClient
      );
    set(() => ({
      consentLoading: true,
    }));
    let result = await remoteAcceptPatientConsent.accept(consentToken, payload);
    if (result.success) {
      set(() => ({
        consentLoading: false,
      }));
      Swal.fire({
        icon: "success",
        title: "Consent accepted Successfully!",
        timer: 2000,
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      });
      useConsentStore
        .getState()
        .getEnrolmentByConsentTokenDetails(consentToken, getEnrolmentByConsentTokenDetailsToken);
    } else {
      set(() => ({
        consentLoading: false,
      }));
      Swal.fire(
        result.message ? result.message  : result.errors? result.errors.message: "Consent request failed",
        "",
        "error"
      );
    }
  },
});
