import { StateCreator } from "zustand";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { FetchPatientOrder } from "../../../domain/usages/fetch-order-by-id";
import { RemoteFetchPatientOrder } from "../../../data/usecases/remote-fetch-order-by-id";
import { AUTH_HEADER, ORDER_SERVICE, PSP_AUTH_TOKEN_KEY } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { PatientOrderType } from "../../../domain/models/fetch-patient-order";
import { usePersonalDetailStore } from "./personalDetailStore.ts/personalDetailsStore";
import { useEnrollmentMainStore } from "../enrollmentListStore/enrollmentMainStore";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { LocalLoggedInUser } from "../../../data/usecases/local-logged-in-user";

interface PatientOrderSliceType {
  fetchOrderById: Function;
  orderList: PatientOrderType[];
  fetchOrderState: FetchState;
}

const initialStates = {
  orderList: [],
  fetchOrderState: FetchState.DEFAULT,
};

const axiosHttpClient = AxiosHttpClient.getInstance();
const storage = LocalJsonStorage.getInstance();

export const useFetchPatientOrderSlice: StateCreator<PatientOrderSliceType> = (
  set
) => ({
  ...initialStates,
  fetchOrderById: async () => {
    set(() => ({ fetchOrderState: FetchState.LOADING }));
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getPSPToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token });
    const fetchPatientOrder: FetchPatientOrder = new RemoteFetchPatientOrder(
      `${ORDER_SERVICE}${Endpoints.FETCH_ORDER_BY_ID}`,
      axiosHttpClient
    );
    let result = await fetchPatientOrder.fetch(
      useEnrollmentMainStore.getState().selectedCard.patient_uuid
    );
    if (result.success) {
      set(() => ({
        orderList: result.orders,
        fetchOrderState: FetchState.SUCCESS,
      }));
    }
  },
});
