import { CancelAppointmentResponse } from "../../../domain/models/appointment-module/cancel-appointment-response";
import { UploadPrescription } from "../../../domain/usages/appointment-module/upload-prescription";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpPostClient } from "../../protocols/http/http-post-client";

export class RemoteUploadPrescription implements UploadPrescription {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}
  async upload(
    appointment_id: number,
    params: UploadPrescription.Params
  ): Promise<CancelAppointmentResponse> {
    const httpResponse = await this.httpPostClient.post({
      url: this.url.replace(":appointment_id", appointment_id.toString()),
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

    return httpResponse.data;
  }
}
