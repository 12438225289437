import { createElement, useEffect } from "react";
import "./App.css";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import indexRoutes, { pageRoutes } from "./routes";
import { useEnrollmentMainStore } from "./store/adminPageStore/enrollmentListStore/enrollmentMainStore";

function App() {
  const { isPermissionDenied } = useEnrollmentMainStore();
  const navigate = useNavigate();
  useEffect(() => {
    const handleCopy = (e: ClipboardEvent) => {
      e.preventDefault();
      alert("Copying functionality is not allowed in this portal.");
    };

    // Add event listener to the document
    document.addEventListener("copy", handleCopy);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("copy", handleCopy);
    };
  }, []);
  useEffect(() => {
    const handleContextMenu = (event: MouseEvent) => {
      event.preventDefault();
      alert("This function is not allowed in this portal.");
    };
    document.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  useEffect(() => {
    console.log(isPermissionDenied, "isPermissionDenied");
    if (isPermissionDenied) {
      navigate(pageRoutes.loginPage);
    }
  }, [isPermissionDenied]);

  return (
    <Routes>
      <Route path="/" element={<Navigate to={pageRoutes.loginPage} />} />
      {indexRoutes.map((prop, key) => {
        return (
          <Route
            key={key}
            path={prop.path}
            element={createElement(prop.component)}
          >
            {prop.children?.map((prop, key) => {
              return (
                <Route
                  key={key}
                  path={prop.path}
                  element={createElement(prop.component)}
                ></Route>
              );
            })}
          </Route>
        );
      })}
    </Routes>
  );
}

export default App;
