import React, { useEffect, useRef, useState } from "react";
import { GenerateSignedUrlForDocumentUpload } from "../../../domain/usages/generate-signed-url-for-document-upload";
import { UploadFile } from "../../../domain/usages/upload-file";
import { ConfirmDocumentUpload } from "../../../domain/usages/confirm-document-upload";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { PatientAddress } from "../../../domain/models/patient-address";
import { Controller, useForm } from "react-hook-form";
import { ProgramInitiator } from "../../../constants/enums/programInitiator";
import { Constant } from "../../../constants/constant";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import moment from "moment";
import { usePersonalDetailStore } from "../../../store/adminPageStore/patientDetailsStore/personalDetailStore.ts/personalDetailsStore";
import { useSendToCFAStore } from "../../../store/adminPageStore/patientDetailsStore/sendToCFAStore/sendToCFAStore";
import { useDocumentStore } from "../../../store/adminPageStore/patientDetailsStore/documentStore/documentStore";
import useFileUpload from "../hooks/useFileUpload";
import { LoadingButton } from "@mui/lab";
import InformationText from "../InformationText";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UploadFileCard from "../UploadFileCard";
import { useEnrollmentMainStore } from "../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import { useProgramDetailStore } from "../../../store/adminPageStore/patientDetailsStore/programDetailsStore/programDetailsStore";
import { ConsentTokenEnrolmentData } from "../../../domain/models/consent-token-enrolment-data";
import { useConsentStore } from "../../../store/patientConsentStore/consentStore";
import FormLoading from "../Loading/FormLoading";
import { GOOGLE_RECAPTCHE_SITE_KEY } from "../../../base";
import useRecaptcha from "../../hooks/reCaptcha";
import CaptureImageModal from "../modals/CaptureImageModal";
import { EnrollmentFormFieldType } from "../../../domain/models/enrollment-form-fields";
import { getFieldValidation } from "../../../domain/Utils";
import { FieldNames } from "../../../constants/enums/fieldNames";
import GaTextArea from "../GaTextArea";
import { DocumentGroupType } from "../../../domain/models/master";

type Props = {
  generateUploadURL: GenerateSignedUrlForDocumentUpload;
  uploadFile: UploadFile;
  confirmFileUpload: ConfirmDocumentUpload;
  programId: string;
  successCallback: Function;
  title: string;
  isConsent?: boolean;
};

type UploadedDocumentType = {
  uuid: string;
  type: string;
  group: string;
};

const EnrollmentForm: React.FC<Props> = ({
  generateUploadURL,
  uploadFile,
  confirmFileUpload,
  programId,
  successCallback,
  title,
  isConsent = false,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<EnrollmentFormFieldType>({
    mode: "onChange",
  });
  const { captchaToken: loginToken, executeRecaptcha: executeLoginRecaptcha } =
    useRecaptcha(GOOGLE_RECAPTCHE_SITE_KEY, "login");

  const {
    captchaToken: fetchMasterDropdownDataToken,
    executeRecaptcha: executeFetchMasterDropdownDataRecaptcha,
  } = useRecaptcha(GOOGLE_RECAPTCHE_SITE_KEY, "fetchMasterDropdownData");
  const {
    captchaToken: fetchProductOptionsToken,
    executeRecaptcha: executeFetchProductOptionsRecaptcha,
  } = useRecaptcha(GOOGLE_RECAPTCHE_SITE_KEY, "fetchProductOptions");
  const {
    captchaToken: fetchProgramOptionsToken,
    executeRecaptcha: executeFetchProgramOptionsRecaptcha,
  } = useRecaptcha(GOOGLE_RECAPTCHE_SITE_KEY, "fetchProgramOptions");
  const {
    captchaToken: fetchProgramDocumentsToken,
    executeRecaptcha: executeFetchProgramDocumentsRecaptcha,
  } = useRecaptcha(GOOGLE_RECAPTCHE_SITE_KEY, "fetchProgramDocuments");

  const {
    captchaToken: getEnrolmentByConsentTokenDetailsToken,
    executeRecaptcha: executeRecaptchaGetEnrolmentByConsentTokenDetails,
    isCaptchaLoading,
  } = useRecaptcha(
    GOOGLE_RECAPTCHE_SITE_KEY,
    "getEnrolmentByConsentTokenDetails1"
  );

  const [openDate, setOpenDate] = useState<boolean>(false);
  const {
    handlePincode,
    district,
    state,
    country,
    city,
    editPersonalDetails,
    addAddress,
  } = usePersonalDetailStore();
  const { productOptions, fetchProductOptions, productOptionsLoading } =
    useSendToCFAStore();

  const {
    programOptions,
    fetchProgramOptions,
    programOptionsloading,
    fetchMasterDropdownData,
  } = useProgramDetailStore();
  const { fetchProgramDocuments, programDocuments } = useDocumentStore();

  const { handleRefreshUrl } = useDocumentStore();
  const { createEnrolmentLoading } = useEnrollmentMainStore();
  const { consentTokenEnrolmentDetails, consentLoading } = useConsentStore();
  const [openSelectImageCaptureMode, setOpenSelectImageCaptureMode] =
    useState<boolean>(false);
  const [selectedDocumentGroup, setSelectedDocumentGroup] =
    useState<string>("");
  const [selectedDocumentType, setSelectedDocumentType] = useState<string>("");
  const [uploadedDocuments, setUploadedDocuments] = useState<
    UploadedDocumentType[]
  >([]);

  const [selectedUploadRef, setSelectedUploadRef] = useState<
    "prescription" | "id" | "address" | "purchaseInvoice" | ""
  >("");

  const prescriptionCameraInputRef = useRef<HTMLInputElement>(null);
  const prescriptionGalleryInputRef = useRef<HTMLInputElement>(null);
  const idCameraInputRef = useRef<HTMLInputElement>(null);
  const idGalleryInputRef = useRef<HTMLInputElement>(null);
  const addressCameraInputRef = useRef<HTMLInputElement>(null);
  const addressGalleryInputRef = useRef<HTMLInputElement>(null);
  const purchaseInvoiceCameraInputRef = useRef<HTMLInputElement>(null);
  const purchaseInvoiceGalleryInputRef = useRef<HTMLInputElement>(null);

  const [formData, setFormData] = useState<EnrollmentFormFieldType>(
    {} as EnrollmentFormFieldType
  );

  const fieldRefs = useRef<{
    [key: string]: HTMLInputElement | HTMLDivElement | null;
  }>({
    initiator: null,
    patientCaretaker: null,
    hospitalCoordinator: null,
    patientName: null,
    patientStatus: null,
    mobile: null,
    email: null,
    age: null,
    address: null,
    gender: null,
    medicineName: null,
    prescriptionUuid: null,
    identityUuid: null,
    purchaseInvoiceUuid: null,
    addressProofUuid: null,
    consent: null,
  });

  const handleScrollToError = () => {
    const errorFields = Object.keys(errors);
    if (errorFields.length > 0) {
      const firstErrorField = errorFields[0];
      fieldRefs.current[firstErrorField]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const handleSelectedUploadRef = (
    value: "prescription" | "id" | "address" | "purchaseInvoice" | ""
  ) => {
    setSelectedUploadRef(value);
  };

  const onError = () => {
    handleScrollToError();
  };

  const handleCaptureFromCamera = (selectedDocType: string) => {
    executeGenerateUploadURLRecaptcha();
    executeConfirmFileUploadRecaptcha();
    setSelectedDocumentType(selectedDocType);
    handleOpenSelectCaptureMode(false, "", "");
    if (
      selectedUploadRef == "prescription" &&
      prescriptionCameraInputRef.current
    ) {
      prescriptionCameraInputRef.current.click();
    } else if (selectedUploadRef == "id" && idCameraInputRef.current) {
      idCameraInputRef.current.click();
    } else if (
      selectedUploadRef == "address" &&
      addressCameraInputRef.current
    ) {
      addressCameraInputRef.current.click();
    } else if (
      selectedUploadRef == "purchaseInvoice" &&
      purchaseInvoiceCameraInputRef.current
    ) {
      purchaseInvoiceCameraInputRef.current.click();
    }
  };

  const handleCaptureFromGallery = (selectedDocType: string) => {
    executeGenerateUploadURLRecaptcha();
    executeConfirmFileUploadRecaptcha();
    handleOpenSelectCaptureMode(false, "", "");
    setSelectedDocumentType(selectedDocType);
    if (
      selectedUploadRef == "prescription" &&
      prescriptionGalleryInputRef.current
    ) {
      prescriptionGalleryInputRef.current.click();
    } else if (selectedUploadRef == "id" && idGalleryInputRef.current) {
      idGalleryInputRef.current.click();
    } else if (
      selectedUploadRef == "address" &&
      addressGalleryInputRef.current
    ) {
      addressGalleryInputRef.current.click();
    } else if (
      selectedUploadRef == "purchaseInvoice" &&
      purchaseInvoiceGalleryInputRef.current
    ) {
      purchaseInvoiceGalleryInputRef.current.click();
    }
  };

  const {
    captchaToken: generateUploadURLCaptacha,
    executeRecaptcha: executeGenerateUploadURLRecaptcha,
  } = useRecaptcha(GOOGLE_RECAPTCHE_SITE_KEY, "generateUploadURL");
  const {
    captchaToken: confirmFileUploadCaptacha,
    executeRecaptcha: executeConfirmFileUploadRecaptcha,
  } = useRecaptcha(GOOGLE_RECAPTCHE_SITE_KEY, "confirmFileUpload");

  const handleOpenSelectCaptureMode = (
    value: boolean,
    refString: "prescription" | "id" | "address" | "purchaseInvoice" | "",
    documentGroup: string
  ) => {
    handleSelectedUploadRef(refString);
    setOpenSelectImageCaptureMode(value);
    setSelectedDocumentGroup(documentGroup);
  };

  const [
    prescriptionImageUpload,
    processingprescriptionImage,
    processPrescriptionImage,
    resetPrescriptionImage,
  ] = useFileUpload(
    generateUploadURL,
    uploadFile,
    confirmFileUpload,
    "PRESCRIPTION",
    selectedDocumentType,
    consentTokenEnrolmentDetails.id ? consentTokenEnrolmentDetails.id : null,
    generateUploadURLCaptacha,
    confirmFileUploadCaptacha
  );

  const [
    identifyImageUpload,
    processingIdentifyImage,
    processIdentifyImage,
    resetIdentifyImage,
  ] = useFileUpload(
    generateUploadURL,
    uploadFile,
    confirmFileUpload,
    "ID_PROOF",
    selectedDocumentType,
    consentTokenEnrolmentDetails.id ? consentTokenEnrolmentDetails.id : null,
    generateUploadURLCaptacha,
    confirmFileUploadCaptacha
  );
  const [
    invoiceImageUpload,
    processingInvoiceImage,
    processInvoiceImage,
    resetInvoiceImage,
  ] = useFileUpload(
    generateUploadURL,
    uploadFile,
    confirmFileUpload,
    "INVOICE",
    selectedDocumentType,
    consentTokenEnrolmentDetails.id ? consentTokenEnrolmentDetails.id : null,
    generateUploadURLCaptacha,
    confirmFileUploadCaptacha
  );
  const [
    addressImageUpload,
    processingAddressImage,
    processAddressImage,
    resetAddressImage,
  ] = useFileUpload(
    generateUploadURL,
    uploadFile,
    confirmFileUpload,
    "ADDRESS_PROOF",
    selectedDocumentType,
    consentTokenEnrolmentDetails.id ? consentTokenEnrolmentDetails.id : null,
    generateUploadURLCaptacha,
    confirmFileUploadCaptacha
  );
  const getImageUuidByType = (
    enrolment: ConsentTokenEnrolmentData,
    types: DocumentGroupType[]
  ): string | null => {
    if (enrolment && enrolment.documents.length > 0) {
      for (let type of types) {
        let document = enrolment.documents.find(
          (document) => document.document_type === type.code
        );
        if (document) {
          return document.uuid;
        }
      }
    }
    return null;
  };

  const getDocumentTypes = (documentGroup: string) => {
    const document = programDocuments.find((doc) => doc.code === documentGroup);
    return document ? document.document_types : [];
  };

  useEffect(() => {
    setUploadedDocuments([]);
    if (consentTokenEnrolmentDetails && consentTokenEnrolmentDetails.id) {
      setValue("initiator", consentTokenEnrolmentDetails.initiator);
      setValue(
        "patientCaretaker",
        consentTokenEnrolmentDetails.patient_caretaker_name
      );
      setValue(
        "hospitalCoordinator",
        consentTokenEnrolmentDetails.hospital_coordinator_name
      );
      setValue("patientName", consentTokenEnrolmentDetails.patient_full_name);
      setValue("mobile", consentTokenEnrolmentDetails.mobile);
      setValue("email", consentTokenEnrolmentDetails.email);
      setValue("gender", consentTokenEnrolmentDetails.gender);
      setValue(
        "address.line",
        consentTokenEnrolmentDetails.address
          ? consentTokenEnrolmentDetails.address.line
          : ""
      );
      setValue(
        "address.landmark",
        consentTokenEnrolmentDetails.address
          ? consentTokenEnrolmentDetails.address.landmark
          : ""
      );
      setValue(
        "address.pincode",
        consentTokenEnrolmentDetails.address
          ? consentTokenEnrolmentDetails.address.pin_code
          : ""
      );
      setValue(
        "demoConfirmation",
        consentTokenEnrolmentDetails.demo_confirmation ? true : false
      );

      setValue(
        "medicineName",
        consentTokenEnrolmentDetails.product_id.toString()
      );
      setValue("doctorName", consentTokenEnrolmentDetails.doctor_full_name);
      setValue("hospitalName", consentTokenEnrolmentDetails.hospital);
      setValue("nationality", consentTokenEnrolmentDetails.is_indian_national);
      setValue("age", consentTokenEnrolmentDetails.age);
      setValue("dateOfBirth", null);

      setValue(
        "prescriptionUuid",
        getImageUuidByType(
          consentTokenEnrolmentDetails,
          getDocumentTypes("PRESCRIPTION").length > 0
            ? getDocumentTypes("PRESCRIPTION")
            : []
        )
      );
      setValue(
        "identityUuid",
        getImageUuidByType(
          consentTokenEnrolmentDetails,
          getDocumentTypes("ID_PROOF").length > 0
            ? getDocumentTypes("ID_PROOF")
            : []
        )
      );
      setValue(
        "purchaseInvoiceUuid",
        getImageUuidByType(
          consentTokenEnrolmentDetails,
          getDocumentTypes("INVOICE").length > 0
            ? getDocumentTypes("INVOICE")
            : []
        )
      );
      setValue(
        "addressProofUuid",
        getImageUuidByType(
          consentTokenEnrolmentDetails,
          getDocumentTypes("ADDRESS_PROOF").length > 0
            ? getDocumentTypes("ADDRESS_PROOF")
            : []
        )
      );
      handleRefreshUrl();
      setValue(
        "consent",
        consentTokenEnrolmentDetails.consent_acknowledgement == 1 ? true : false
      );
      if (
        consentTokenEnrolmentDetails.address &&
        consentTokenEnrolmentDetails.address.pin_code
      ) {
        handlePincode(consentTokenEnrolmentDetails.address.pin_code);
      }
    }
  }, [consentTokenEnrolmentDetails.id]);

  useEffect(() => {
    if (prescriptionImageUpload && prescriptionImageUpload.uuid) {
      setUploadedDocuments((prevDocuments) => {
        const existingIndex = prevDocuments.findIndex(
          (doc) => doc.group === "PRESCRIPTION"
        );

        if (existingIndex !== -1) {
          const updatedDocuments = [...prevDocuments];
          updatedDocuments[existingIndex] = {
            ...updatedDocuments[existingIndex],
            uuid: prescriptionImageUpload.uuid!,
            type: selectedDocumentType,
          };
          return updatedDocuments;
        } else {
          return [
            ...prevDocuments,
            {
              uuid: prescriptionImageUpload.uuid!,
              type: selectedDocumentType,
              group: "PRESCRIPTION",
            },
          ];
        }
      });

      setValue("prescriptionUuid", prescriptionImageUpload.uuid);
    }
  }, [prescriptionImageUpload]);

  useEffect(() => {
    if (identifyImageUpload && identifyImageUpload.uuid) {
      setUploadedDocuments((prevDocuments) => {
        const existingIndex = prevDocuments.findIndex(
          (doc) => doc.group === "ID_PROOF"
        );

        if (existingIndex !== -1) {
          const updatedDocuments = [...prevDocuments];
          updatedDocuments[existingIndex] = {
            ...updatedDocuments[existingIndex],
            uuid: identifyImageUpload.uuid!,
            type: selectedDocumentType,
          };
          return updatedDocuments;
        } else {
          return [
            ...prevDocuments,
            {
              uuid: identifyImageUpload.uuid!,
              type: selectedDocumentType,
              group: "ID_PROOF",
            },
          ];
        }
      });

      setValue("identityUuid", identifyImageUpload.uuid);
    }
  }, [identifyImageUpload]);

  useEffect(() => {
    if (invoiceImageUpload && invoiceImageUpload.uuid) {
      setUploadedDocuments((prevDocuments) => {
        const existingIndex = prevDocuments.findIndex(
          (doc) => doc.group === "INVOICE"
        );

        if (existingIndex !== -1) {
          const updatedDocuments = [...prevDocuments];
          updatedDocuments[existingIndex] = {
            ...updatedDocuments[existingIndex],
            uuid: invoiceImageUpload.uuid!,
            type: selectedDocumentType,
          };
          return updatedDocuments;
        } else {
          return [
            ...prevDocuments,
            {
              uuid: invoiceImageUpload.uuid!,
              type: selectedDocumentType,
              group: "INVOICE",
            },
          ];
        }
      });

      setValue("purchaseInvoiceUuid", invoiceImageUpload.uuid);
    }
  }, [invoiceImageUpload]);

  useEffect(() => {
    if (addressImageUpload && addressImageUpload.uuid) {
      setUploadedDocuments((prevDocuments) => {
        const existingIndex = prevDocuments.findIndex(
          (doc) => doc.group === "ADDRESS_PROOF"
        );

        if (existingIndex !== -1) {
          const updatedDocuments = [...prevDocuments];
          updatedDocuments[existingIndex] = {
            ...updatedDocuments[existingIndex],
            uuid: addressImageUpload.uuid!,
            type: selectedDocumentType,
          };
          return updatedDocuments;
        } else {
          return [
            ...prevDocuments,
            {
              uuid: addressImageUpload.uuid!,
              type: selectedDocumentType,
              group: "ADDRESS_PROOF",
            },
          ];
        }
      });

      setValue("addressProofUuid", addressImageUpload.uuid);
    }
  }, [addressImageUpload]);

  const calculateAge = () => {
    const today = new Date();
    const birthDate = new Date(
      moment((watch("dateOfBirth") as Dayjs).toString()).format("YYYY-MM-DD")
    );
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    setValue("age", age);
  };

  useEffect(() => {
    if (watch("dateOfBirth")) {
      calculateAge();
    }
  }, [watch("dateOfBirth")]);

  useEffect(() => {
    if (fetchMasterDropdownDataToken) {
      if (!(consentTokenEnrolmentDetails && consentTokenEnrolmentDetails.id)) {
        fetchMasterDropdownData(programId, fetchMasterDropdownDataToken);

        setValue("dateOfBirth", null);
      } else {
        fetchMasterDropdownData(
          consentTokenEnrolmentDetails.program_id,
          fetchMasterDropdownDataToken
        );
      }
    }
  }, [fetchMasterDropdownDataToken]);

  useEffect(() => {
    if (fetchProgramDocumentsToken && programId) {
      if (!(consentTokenEnrolmentDetails && consentTokenEnrolmentDetails.id)) {
        fetchProgramDocuments(programId, fetchProgramDocumentsToken);

        setValue("dateOfBirth", null);
      } else {
        fetchProgramDocuments(
          consentTokenEnrolmentDetails.program_id,
          fetchProgramDocumentsToken
        );
      }
    }
  }, [fetchProgramDocumentsToken]);

  useEffect(() => {
    if (fetchProgramOptionsToken) {
      if (!(consentTokenEnrolmentDetails && consentTokenEnrolmentDetails.id)) {
        let params = {
          program_id: programId,
        };

        fetchProgramOptions(fetchProgramOptionsToken, params);

        setValue("dateOfBirth", null);
      } else {
        let params = {
          program_id: consentTokenEnrolmentDetails.program_id,
        };
        fetchProgramOptions(fetchProgramOptionsToken, params);
      }
    }
  }, [fetchProgramOptionsToken]);

  useEffect(() => {
    executeFetchMasterDropdownDataRecaptcha();
    executeFetchProgramDocumentsRecaptcha();
    executeFetchProgramOptionsRecaptcha();
    executeFetchProductOptionsRecaptcha();
  }, []);

  useEffect(() => {
    if (fetchProductOptionsToken && programId) {
      if (
        programOptions.length > 0 &&
        !(consentTokenEnrolmentDetails && consentTokenEnrolmentDetails.id)
      ) {
        let params = {
          program_id: programId,
        };
        fetchProductOptions(fetchProductOptionsToken, params);
      }
    }
  }, [programOptions, fetchProductOptionsToken]);

  useEffect(() => {
    if (loginToken && getEnrolmentByConsentTokenDetailsToken) {
      let payload = {
        initiator: formData.initiator,
        patient_caretaker_name: formData.patientCaretaker
          ? formData.patientCaretaker
          : null,
        hospital_coordinator_name: formData.hospitalCoordinator
          ? formData.hospitalCoordinator
          : null,
        patient_full_name: formData.patientName,
        patient_status: formData.patientStatus,
        email: formData.email,
        mobile: formData.mobile,
        gender: formData.gender,
        demo_confirmation: formData.demoConfirmation ? 1 : 0,
        program_id: programId,
        is_indian_national: formData.nationality,
        product_id: formData.medicineName,
        prescription_uuid: formData.prescriptionUuid,
        address_proof_uuid: formData.addressProofUuid,
        identity_uuid: formData.identityUuid,
        iop: formData.iop,
        disease_duration_years: formData.numberOfYearsOfGlucoma,
        prescribed_drugs: formData.currentDrugs,
        date_of_birth: formData.dateOfBirth
          ? moment((formData.dateOfBirth as Dayjs).toString()).format(
              Constant.YEAR_MONTH_DATE_FORMAT
            )
          : null,
        age: formData.age,
        address: {
          pin_code: formData.address.pincode,
          district: formData.address.district,
          state: formData.address.state,
          line: formData.address.line,
          landmark: formData.address.landmark,
          country: formData.address.country,
          city: formData.address.city,
        },
        doctor_full_name: formData.doctorName ? formData.doctorName : null,
        hospital_name: formData.hospitalName ? formData.hospitalName : null,
        consent_accepted: formData.consent ? true : false,

        documents: uploadedDocuments,
      };
      if (payload && loginToken && getEnrolmentByConsentTokenDetailsToken) {
        if (consentTokenEnrolmentDetails.id && isConsent) {
          let updatePersonalDetailsPayload = {
            fullName: formData.patientName,
            gender: formData.gender,
            dateOfBirth: formData.dateOfBirth
              ? moment((formData.dateOfBirth as Dayjs).toString()).format(
                  Constant.YEAR_MONTH_DATE_FORMAT
                )
              : null,
            age: formData.age && formData.age.toString(),
          };
          let updateAddressPayload = {
            addresses: [
              {
                type: "primary",
                pincode: formData.address.pincode,
                district: district,
                state: state,
                line: formData.address.line,
                area: "",
                landmark: formData.address.landmark,
                country: country,
                city: city,
              },
            ],
          };
          addAddress(
            consentTokenEnrolmentDetails.patient_uuid,
            updateAddressPayload,
            () => {},
            true
          );
          editPersonalDetails(
            consentTokenEnrolmentDetails.patient_uuid,
            updatePersonalDetailsPayload,
            true
          );
          successCallback(
            payload,
            loginToken,
            getEnrolmentByConsentTokenDetailsToken
          );
        } else {
          successCallback(
            payload,
            loginToken,
            getEnrolmentByConsentTokenDetailsToken
          );
        }
      }
    }
  }, [loginToken, getEnrolmentByConsentTokenDetailsToken]);

  const onSubmit = (data: EnrollmentFormFieldType) => {
    setFormData(data);
    executeLoginRecaptcha();
    executeRecaptchaGetEnrolmentByConsentTokenDetails();
  };

  useEffect(() => {
    setValue("address.city", city);
    setValue("address.country", country);
    setValue("address.district", district);
    setValue("address.state", state);
  }, [city, country, district, state]);

  return (
    <Stack marginTop={1}>
      {programOptionsloading ||
      productOptionsLoading ||
      programOptions.length == 0 ? (
        <FormLoading />
      ) : (
        <>
          <Typography
            variant="body1"
            textAlign={"center"}
            fontWeight={660}
            color={"warning.dark"}
            sx={{ textDecoration: "underline" }}
          >
            {`${
              programOptions.length > 0 ? programOptions[0].name : ""
            } ${title}`}
          </Typography>
          <Stack margin={2} gap={2}>
            <Typography variant="body1" fontWeight={660}>
              Personal Details
            </Typography>
            {getFieldValidation(
              programOptions[0].config_data,
              FieldNames.Initiator
            ) &&
              getFieldValidation(
                programOptions[0].config_data,
                FieldNames.Initiator
              )?.visibility && (
                <Controller
                  name="initiator"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <div ref={(el) => (fieldRefs.current["initiator"] = el)}>
                      <TextField
                        disabled={
                          consentTokenEnrolmentDetails.consent_acknowledgement ==
                            1 || consentTokenEnrolmentDetails.initiator
                            ? true
                            : false
                        }
                        select={consentTokenEnrolmentDetails.id ? false : true}
                        label="Initiator "
                        value={value}
                        fullWidth
                        onChange={onChange}
                        error={!!error}
                        size="small"
                        helperText={error?.message}
                      >
                        <MenuItem value={ProgramInitiator.PATIENT}>
                          Patient Himself
                        </MenuItem>
                        <MenuItem value={ProgramInitiator.CARETAKER}>
                          Patient Caretaker
                        </MenuItem>
                        <MenuItem value={ProgramInitiator.HOSPITAL_COORDINATOR}>
                          Hospital Co-ordinator (on behalf of patient)
                        </MenuItem>
                      </TextField>
                    </div>
                  )}
                  rules={{
                    required: {
                      value:
                        getFieldValidation(
                          programOptions[0].config_data,
                          FieldNames.Initiator
                        )?.required ?? false,

                      message: "Field Required",
                    },
                  }}
                />
              )}

            {watch("initiator") == ProgramInitiator.CARETAKER &&
              getFieldValidation(
                programOptions[0].config_data,
                FieldNames.PatientCaretakerName
              ) &&
              getFieldValidation(
                programOptions[0].config_data,
                FieldNames.PatientCaretakerName
              )?.visibility && (
                <Controller
                  name="patientCaretaker"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      inputRef={(el) =>
                        (fieldRefs.current["patientCaretaker"] = el)
                      }
                      label="Name of the Caretaker"
                      disabled={
                        consentTokenEnrolmentDetails.consent_acknowledgement ==
                          1 ||
                        consentTokenEnrolmentDetails.patient_caretaker_name
                          ? true
                          : false
                      }
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      size="small"
                      helperText={error?.message}
                    />
                  )}
                  rules={{
                    required: {
                      value:
                        getFieldValidation(
                          programOptions[0].config_data,
                          FieldNames.PatientCaretakerName
                        )?.required ?? false,
                      message: "Field Required",
                    },
                  }}
                />
              )}
            {watch("initiator") == ProgramInitiator.HOSPITAL_COORDINATOR &&
              getFieldValidation(
                programOptions[0].config_data,
                FieldNames.HospitalCoordinatorName
              ) &&
              getFieldValidation(
                programOptions[0].config_data,
                FieldNames.HospitalCoordinatorName
              )?.visibility && (
                <Controller
                  name="hospitalCoordinator"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="Name of Hospital Co-ordinator "
                      inputRef={(el) =>
                        (fieldRefs.current["hospitalCoordinator"] = el)
                      }
                      disabled={
                        consentTokenEnrolmentDetails.consent_acknowledgement ==
                          1 ||
                        consentTokenEnrolmentDetails.hospital_coordinator_name
                          ? true
                          : false
                      }
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      size="small"
                      helperText={error?.message}
                    />
                  )}
                  rules={{
                    required: {
                      value:
                        getFieldValidation(
                          programOptions[0].config_data,
                          FieldNames.HospitalCoordinatorName
                        )?.required ?? false,
                      message: "Field Required",
                    },
                  }}
                />
              )}
            {getFieldValidation(
              programOptions[0].config_data,
              FieldNames.PatientFullName
            ) &&
              getFieldValidation(
                programOptions[0].config_data,
                FieldNames.PatientFullName
              )?.visibility && (
                <Controller
                  name="patientName"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="Patient Name "
                      inputRef={(el) => (fieldRefs.current["patientName"] = el)}
                      disabled={
                        consentTokenEnrolmentDetails.consent_acknowledgement ==
                          1 || consentTokenEnrolmentDetails.patient_full_name
                          ? true
                          : false
                      }
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      size="small"
                      helperText={error?.message}
                    />
                  )}
                  rules={{
                    required: {
                      value:
                        getFieldValidation(
                          programOptions[0].config_data,
                          FieldNames.PatientFullName
                        )?.required ?? false,
                      message: "Field Required",
                    },
                  }}
                />
              )}
            {getFieldValidation(
              programOptions[0].config_data,
              FieldNames.IsIndianNational
            ) &&
              getFieldValidation(
                programOptions[0].config_data,
                FieldNames.IsIndianNational
              )?.visibility && (
                <Controller
                  name="nationality"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormControl>
                      <Stack
                        flexDirection={"row"}
                        gap={1}
                        alignItems={"center"}
                      >
                        <FormLabel>
                          <Typography color={"black"}>
                            Is an Indian National?
                          </Typography>
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={value}
                          onChange={onChange}
                        >
                          <FormControlLabel
                            value={1}
                            checked={value == 1}
                            control={<Radio size="small" color="secondary" />}
                            disabled={
                              consentTokenEnrolmentDetails.consent_acknowledgement ==
                              1
                                ? true
                                : false
                            }
                            label={
                              <Typography variant="subtitle2">Yes</Typography>
                            }
                          />
                          <FormControlLabel
                            value={0}
                            checked={value == 0}
                            disabled={
                              consentTokenEnrolmentDetails.consent_acknowledgement ==
                              1
                                ? true
                                : false
                            }
                            control={<Radio size="small" color="secondary" />}
                            label={
                              <Typography variant="subtitle2">No</Typography>
                            }
                          />
                        </RadioGroup>
                      </Stack>
                      <FormHelperText>
                        <Typography variant="caption" color="error.main">
                          {error?.message}
                        </Typography>
                      </FormHelperText>
                    </FormControl>
                  )}
                  rules={{
                    required: {
                      value:
                        getFieldValidation(
                          programOptions[0].config_data,
                          FieldNames.IsIndianNational
                        )?.required ?? false,
                      message: "Field Required",
                    },
                  }}
                />
              )}
            {getFieldValidation(
              programOptions[0].config_data,
              FieldNames.PatientStatus
            ) &&
              getFieldValidation(
                programOptions[0].config_data,
                FieldNames.PatientStatus
              )?.visibility && (
                <Controller
                  name="patientStatus"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <div
                      ref={(el) => (fieldRefs.current["patientStatus"] = el)}
                    >
                      <FormControl error={!!error}>
                        <FormLabel>Patient Status </FormLabel>
                        <RadioGroup
                          name="patient_status"
                          value={value}
                          onChange={onChange}
                        >
                          <div style={{ display: "flex" }}>
                            <FormControlLabel
                              value="in_patient"
                              control={
                                <Radio
                                  size="small"
                                  checked={value == "in_patient"}
                                  disabled={
                                    consentTokenEnrolmentDetails.consent_acknowledgement ==
                                    1
                                      ? true
                                      : false
                                  }
                                />
                              }
                              label="In Patient"
                            />
                            <FormControlLabel
                              value="out_patient"
                              control={
                                <Radio
                                  size="small"
                                  checked={value == "out_patient"}
                                  disabled={
                                    consentTokenEnrolmentDetails.consent_acknowledgement ==
                                    1
                                      ? true
                                      : false
                                  }
                                />
                              }
                              label="Out Patient"
                            />
                          </div>
                        </RadioGroup>

                        <FormHelperText>{error?.message}</FormHelperText>
                      </FormControl>
                    </div>
                  )}
                  rules={{
                    required: {
                      value:
                        getFieldValidation(
                          programOptions[0].config_data,
                          FieldNames.PatientStatus
                        )?.required ?? false,
                      message: "Field Required",
                    },
                  }}
                />
              )}
            {getFieldValidation(
              programOptions[0].config_data,
              FieldNames.Mobile
            ) &&
              getFieldValidation(
                programOptions[0].config_data,
                FieldNames.Mobile
              )?.visibility && (
                <Controller
                  name="mobile"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="Mobile Number "
                      inputRef={(el) => (fieldRefs.current["mobile"] = el)}
                      disabled={
                        consentTokenEnrolmentDetails.consent_acknowledgement ==
                          1 || consentTokenEnrolmentDetails.mobile
                          ? true
                          : false
                      }
                      value={value}
                      onChange={onChange}
                      size="small"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                  rules={{
                    pattern: {
                      value: Constant.CORRECT_MOBILE.VALUE,
                      message: "Invalid mobile",
                    },
                    required: {
                      value:
                        getFieldValidation(
                          programOptions[0].config_data,
                          FieldNames.Mobile
                        )?.required ?? false,
                      message: "Field Required",
                    },
                  }}
                />
              )}
            {getFieldValidation(
              programOptions[0].config_data,
              FieldNames.Email
            ) &&
              getFieldValidation(
                programOptions[0].config_data,
                FieldNames.Email
              )?.visibility && (
                <Controller
                  name="email"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="Email "
                      inputRef={(el) => (fieldRefs.current["email"] = el)}
                      value={value}
                      disabled={
                        consentTokenEnrolmentDetails.consent_acknowledgement ==
                        1
                          ? true
                          : false
                      }
                      onChange={onChange}
                      size="small"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                  rules={{
                    pattern: {
                      value: Constant.CORRECT_EMAIL.VALUE,
                      message: "Invalid email",
                    },
                    required: {
                      value:
                        getFieldValidation(
                          programOptions[0].config_data,
                          FieldNames.Email
                        )?.required ?? false,
                      message: "Field Required",
                    },
                  }}
                />
              )}

            <Stack
              flexDirection={"row"}
              gap={2}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {getFieldValidation(
                programOptions[0].config_data,
                FieldNames.DOB
              ) &&
                getFieldValidation(
                  programOptions[0].config_data,
                  FieldNames.DOB
                )?.visibility && (
                  <Controller
                    name="dateOfBirth"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label={"Date of Birth "}
                            open={openDate}
                            onClose={() => {
                              setOpenDate(false);
                            }}
                            onOpen={() => {
                              setOpenDate(true);
                            }}
                            disableFuture
                            value={value}
                            onChange={onChange}
                            format="DD/MM/YYYY"
                          />
                        </LocalizationProvider>
                      </>
                    )}
                    rules={{
                      required: {
                        value:
                          getFieldValidation(
                            programOptions[0].config_data,
                            FieldNames.DOB
                          )?.required ?? false,
                        message: Constant.FIELD_REQUIRED,
                      },
                    }}
                  />
                )}

              {getFieldValidation(
                programOptions[0].config_data,
                FieldNames.Age
              ) &&
                getFieldValidation(
                  programOptions[0].config_data,
                  FieldNames.Age
                )?.visibility && (
                  <Controller
                    name="age"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        inputRef={(el) => (fieldRefs.current["age"] = el)}
                        InputLabelProps={{ shrink: true }}
                        label="Age "
                        disabled={
                          consentTokenEnrolmentDetails.consent_acknowledgement ==
                          1
                            ? true
                            : false
                        }
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={value}
                        error={error && true}
                        helperText={error?.message}
                        onChange={(event: any) => {
                          onChange(event.target.value);
                          setValue("dateOfBirth", null);
                        }}
                        sx={{
                          borderRadius: "3.5px",
                        }}
                      />
                    )}
                    rules={{
                      required: {
                        value:
                          getFieldValidation(
                            programOptions[0].config_data,
                            FieldNames.Age
                          )?.required ?? false,
                        message: Constant.FIELD_REQUIRED,
                      },
                      pattern: {
                        value: Constant.NUMBER_ONLY.VALUE,
                        message: Constant.NUMBER_ONLY.MESSAGE,
                      },
                    }}
                  />
                )}
            </Stack>
            {getFieldValidation(
              programOptions[0].config_data,
              FieldNames.Gender
            ) &&
              getFieldValidation(
                programOptions[0].config_data,
                FieldNames.Gender
              )?.visibility && (
                <Controller
                  name="gender"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <div ref={(el) => (fieldRefs.current["gender"] = el)}>
                      <FormControl error={!!error}>
                        <FormLabel>Gender </FormLabel>
                        <RadioGroup
                          name="gender"
                          value={value}
                          onChange={onChange}
                        >
                          <div style={{ display: "flex" }}>
                            <FormControlLabel
                              value="male"
                              control={
                                <Radio
                                  size="small"
                                  checked={value == "male"}
                                  disabled={
                                    consentTokenEnrolmentDetails.consent_acknowledgement ==
                                    1
                                      ? true
                                      : false
                                  }
                                />
                              }
                              label="Male"
                            />
                            <FormControlLabel
                              value="female"
                              control={
                                <Radio
                                  size="small"
                                  checked={value == "female"}
                                  disabled={
                                    consentTokenEnrolmentDetails.consent_acknowledgement ==
                                    1
                                      ? true
                                      : false
                                  }
                                />
                              }
                              label="Female"
                            />
                            <FormControlLabel
                              value="others"
                              control={
                                <Radio
                                  size="small"
                                  checked={value == "others"}
                                  disabled={
                                    consentTokenEnrolmentDetails.consent_acknowledgement ==
                                    1
                                      ? true
                                      : false
                                  }
                                />
                              }
                              label="Others"
                            />
                          </div>
                        </RadioGroup>

                        <FormHelperText>{error?.message}</FormHelperText>
                      </FormControl>
                    </div>
                  )}
                  rules={{
                    required: {
                      value:
                        getFieldValidation(
                          programOptions[0].config_data,
                          FieldNames.Gender
                        )?.required ?? false,
                      message: "Field Required",
                    },
                  }}
                />
              )}
            {getFieldValidation(
              programOptions[0].config_data,
              FieldNames.AddressLine
            ) &&
              getFieldValidation(
                programOptions[0].config_data,
                FieldNames.AddressLine
              )?.visibility && (
                <Controller
                  name="address.line"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      fullWidth
                      label="Address line "
                      value={value}
                      inputRef={(el) => (fieldRefs.current["address"] = el)}
                      disabled={
                        consentTokenEnrolmentDetails.consent_acknowledgement ==
                        1
                          ? true
                          : false
                      }
                      onChange={onChange}
                      size="small"
                      error={error && true}
                      helperText={error?.message}
                    />
                  )}
                  rules={{
                    required: {
                      value:
                        getFieldValidation(
                          programOptions[0].config_data,
                          FieldNames.AddressLine
                        )?.required ?? false,
                      message: "Field Required",
                    },
                  }}
                />
              )}
            {getFieldValidation(
              programOptions[0].config_data,
              FieldNames.AddessLandmark
            ) &&
              getFieldValidation(
                programOptions[0].config_data,
                FieldNames.AddessLandmark
              )?.visibility && (
                <Controller
                  name="address.landmark"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      fullWidth
                      label="Landmark "
                      size="small"
                      inputRef={(el) => (fieldRefs.current["address"] = el)}
                      disabled={
                        consentTokenEnrolmentDetails.consent_acknowledgement ==
                        1
                          ? true
                          : false
                      }
                      value={value}
                      onChange={onChange}
                      error={error && true}
                      helperText={error?.message}
                    />
                  )}
                  rules={{
                    required: {
                      value:
                        getFieldValidation(
                          programOptions[0].config_data,
                          FieldNames.AddessLandmark
                        )?.required ?? false,
                      message: "Field Required",
                    },
                  }}
                />
              )}
            {getFieldValidation(
              programOptions[0].config_data,
              FieldNames.AddressPincode
            ) &&
              getFieldValidation(
                programOptions[0].config_data,
                FieldNames.AddressPincode
              )?.visibility && (
                <Controller
                  name="address.pincode"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      fullWidth
                      label="Pincode "
                      size="small"
                      inputRef={(el) => (fieldRefs.current["address"] = el)}
                      disabled={
                        consentTokenEnrolmentDetails.consent_acknowledgement ==
                        1
                          ? true
                          : false
                      }
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);

                        handlePincode(e.target.value);
                      }}
                      error={error && true}
                      helperText={error?.message}
                    />
                  )}
                  rules={{
                    required: {
                      value:
                        getFieldValidation(
                          programOptions[0].config_data,
                          FieldNames.AddressPincode
                        )?.required ?? false,
                      message: "Field Required",
                    },
                    pattern: {
                      value: Constant.CORRECT_PINCODE.VALUE,
                      message: Constant.CORRECT_PINCODE.MESSAGE,
                    },
                  }}
                />
              )}
            {getFieldValidation(
              programOptions[0].config_data,
              FieldNames.AddressCity
            ) &&
              getFieldValidation(
                programOptions[0].config_data,
                FieldNames.AddressCity
              )?.visibility && (
                <Controller
                  name="address.city"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      disabled
                      fullWidth
                      label="City "
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      value={value}
                      InputProps={{
                        readOnly: true,
                      }}
                      error={error && true}
                      helperText={error?.message}
                    />
                  )}
                  rules={{
                    required: {
                      value:
                        getFieldValidation(
                          programOptions[0].config_data,
                          FieldNames.AddressCity
                        )?.required ?? false,
                      message: "Field Required",
                    },
                  }}
                />
              )}
            {getFieldValidation(
              programOptions[0].config_data,
              FieldNames.AddressDistrict
            ) &&
              getFieldValidation(
                programOptions[0].config_data,
                FieldNames.AddressDistrict
              )?.visibility && (
                <Controller
                  name="address.district"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      disabled
                      fullWidth
                      label="District "
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      value={value}
                      InputProps={{
                        readOnly: true,
                      }}
                      error={error && true}
                      helperText={error?.message}
                    />
                  )}
                  rules={{
                    required: {
                      value:
                        getFieldValidation(
                          programOptions[0].config_data,
                          FieldNames.AddressDistrict
                        )?.required ?? false,
                      message: "Field Required",
                    },
                  }}
                />
              )}
            {getFieldValidation(
              programOptions[0].config_data,
              FieldNames.AddressState
            ) &&
              getFieldValidation(
                programOptions[0].config_data,
                FieldNames.AddressState
              )?.visibility && (
                <Controller
                  name="address.state"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      disabled
                      fullWidth
                      label="State "
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      value={value}
                      InputProps={{
                        readOnly: true,
                      }}
                      error={error && true}
                      helperText={error?.message}
                    />
                  )}
                  rules={{
                    required: {
                      value:
                        getFieldValidation(
                          programOptions[0].config_data,
                          FieldNames.AddressState
                        )?.required ?? false,
                      message: "Field Required",
                    },
                  }}
                />
              )}
            {getFieldValidation(
              programOptions[0].config_data,
              FieldNames.AddressCountry
            ) &&
              getFieldValidation(
                programOptions[0].config_data,
                FieldNames.AddressCountry
              )?.visibility && (
                <Controller
                  name="address.country"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      disabled
                      fullWidth
                      size="small"
                      label="Country "
                      InputLabelProps={{ shrink: true }}
                      value={value}
                      InputProps={{
                        readOnly: true,
                      }}
                      error={error && true}
                      helperText={error?.message}
                    />
                  )}
                  rules={{
                    required: {
                      value:
                        getFieldValidation(
                          programOptions[0].config_data,
                          FieldNames.AddressCountry
                        )?.required ?? false,
                      message: "Field Required",
                    },
                  }}
                />
              )}
            {getFieldValidation(
              programOptions[0].config_data,
              FieldNames.DoctorFullName
            ) &&
              getFieldValidation(
                programOptions[0].config_data,
                FieldNames.DoctorFullName
              )?.visibility && (
                <Controller
                  name="doctorName"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="Doctor Name"
                      value={value}
                      onChange={onChange}
                      disabled={
                        consentTokenEnrolmentDetails.consent_acknowledgement ==
                        1
                          ? true
                          : false
                      }
                      error={!!error}
                      size="small"
                      helperText={error?.message}
                    />
                  )}
                  rules={{
                    required: {
                      value:
                        getFieldValidation(
                          programOptions[0].config_data,
                          FieldNames.DoctorFullName
                        )?.required ?? false,
                      message: "Field Required",
                    },
                  }}
                />
              )}
            {getFieldValidation(
              programOptions[0].config_data,
              FieldNames.Hospital
            ) &&
              getFieldValidation(
                programOptions[0].config_data,
                FieldNames.Hospital
              )?.visibility && (
                <Controller
                  name="hospitalName"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="Hospital Name"
                      value={value}
                      disabled={
                        consentTokenEnrolmentDetails.consent_acknowledgement ==
                        1
                          ? true
                          : false
                      }
                      onChange={onChange}
                      error={!!error}
                      size="small"
                      helperText={error?.message}
                    />
                  )}
                  rules={{
                    required: {
                      value:
                        getFieldValidation(
                          programOptions[0].config_data,
                          FieldNames.Hospital
                        )?.required ?? false,
                      message: "Field Required",
                    },
                  }}
                />
              )}
            {getFieldValidation(
              programOptions[0].config_data,
              FieldNames.IOP
            ) &&
              getFieldValidation(programOptions[0].config_data, FieldNames.IOP)
                ?.visibility && (
                <Controller
                  name="iop"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="IOP (mm Hg)"
                      value={value}
                      type="number"
                      onChange={onChange}
                      disabled={
                        consentTokenEnrolmentDetails.consent_acknowledgement ==
                        1
                          ? true
                          : false
                      }
                      error={!!error}
                      size="small"
                      helperText={error?.message}
                    />
                  )}
                  rules={{
                    required: {
                      value:
                        getFieldValidation(
                          programOptions[0].config_data,
                          FieldNames.IOP
                        )?.required ?? false,
                      message: "Field Required",
                    },
                  }}
                />
              )}
            {getFieldValidation(
              programOptions[0].config_data,
              FieldNames.DiseaseDuration
            ) &&
              getFieldValidation(
                programOptions[0].config_data,
                FieldNames.DiseaseDuration
              )?.visibility && (
                <Controller
                  name="numberOfYearsOfGlucoma"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="No of years of Glaucoma"
                      type="number"
                      value={value}
                      onChange={onChange}
                      disabled={
                        consentTokenEnrolmentDetails.consent_acknowledgement ==
                        1
                          ? true
                          : false
                      }
                      error={!!error}
                      size="small"
                      helperText={error?.message}
                    />
                  )}
                  rules={{
                    required: {
                      value:
                        getFieldValidation(
                          programOptions[0].config_data,
                          FieldNames.DiseaseDuration
                        )?.required ?? false,
                      message: "Field Required",
                    },
                  }}
                />
              )}
            {getFieldValidation(
              programOptions[0].config_data,
              FieldNames.PrescribedDrugs
            ) &&
              getFieldValidation(
                programOptions[0].config_data,
                FieldNames.PrescribedDrugs
              )?.visibility && (
                <Controller
                  name="currentDrugs"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <GaTextArea
                      value={value}
                      onChange={onChange}
                      placeholder="Enter drugs currently prescribed for managing Glaucoma "
                      error={error && true}
                      errorMessage={error?.message}
                    />
                  )}
                  rules={{
                    required: {
                      value:
                        getFieldValidation(
                          programOptions[0].config_data,
                          FieldNames.PrescribedDrugs
                        )?.required ?? false,
                      message: "Field Required",
                    },
                  }}
                />
              )}
            {getFieldValidation(
              programOptions[0].config_data,
              FieldNames.ProductId
            ) &&
              getFieldValidation(
                programOptions[0].config_data,
                FieldNames.ProductId
              )?.visibility && (
                <Controller
                  name="medicineName"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <div ref={(el) => (fieldRefs.current["medicineName"] = el)}>
                      <TextField
                        select
                        fullWidth
                        label="Medicine Name "
                        value={value}
                        disabled={
                          consentTokenEnrolmentDetails.consent_acknowledgement ==
                            1 || consentTokenEnrolmentDetails.product_id
                            ? true
                            : false
                        }
                        size="small"
                        onChange={onChange}
                        error={error && true}
                        helperText={error?.message}
                      >
                        {productOptions.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  )}
                  rules={{
                    required: {
                      value:
                        getFieldValidation(
                          programOptions[0].config_data,
                          FieldNames.ProductId
                        )?.required ?? false,
                      message: "Field Required",
                    },
                  }}
                />
              )}

            <Typography variant="body1" fontWeight={660}>
              Documents
            </Typography>
            {getFieldValidation(
              programOptions[0].config_data,
              FieldNames.PrecriptionDocument
            ) &&
              getFieldValidation(
                programOptions[0].config_data,
                FieldNames.PrecriptionDocument
              )?.visibility && (
                <Controller
                  name="prescriptionUuid"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <div
                      ref={(el) => (fieldRefs.current["prescriptionUuid"] = el)}
                      style={{ width: "100%" }}
                    >
                      <Stack>
                        <UploadFileCard
                          documentGroupType="PRESCRIPTION"
                          handleOpenModal={handleOpenSelectCaptureMode}
                          cameraInputRef={prescriptionCameraInputRef}
                          galleryInputRef={prescriptionGalleryInputRef}
                          uuid={value}
                          refName="prescription"
                          title="Upload Prescription "
                          id={1}
                          isConsent={
                            consentTokenEnrolmentDetails.consent_acknowledgement ==
                            1
                              ? true
                              : false
                          }
                          onFileSelect={(file: File) =>
                            processPrescriptionImage(file, selectedDocumentType)
                          }
                          file={prescriptionImageUpload?.file}
                          reset={() => resetPrescriptionImage()}
                          isLoading={processingprescriptionImage}
                          error={!!error}
                          helperText={error?.message}
                        />
                      </Stack>
                    </div>
                  )}
                  rules={{
                    required: {
                      value:
                        getFieldValidation(
                          programOptions[0].config_data,
                          FieldNames.PrecriptionDocument
                        )?.required ?? false,
                      message: "Field Required",
                    },
                  }}
                />
              )}

            {getFieldValidation(
              programOptions[0].config_data,
              FieldNames.IdProofDocument
            ) &&
              getFieldValidation(
                programOptions[0].config_data,
                FieldNames.IdProofDocument
              )?.visibility && (
                <Controller
                  name="identityUuid"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <div
                      ref={(el) => (fieldRefs.current["identityUuid"] = el)}
                      style={{ width: "100%" }}
                    >
                      <Stack>
                        <UploadFileCard
                          refName="id"
                          documentGroupType="ID_PROOF"
                          handleOpenModal={handleOpenSelectCaptureMode}
                          cameraInputRef={idCameraInputRef}
                          galleryInputRef={idGalleryInputRef}
                          uuid={value}
                          title="Upload Id proof Aadhar card/PanCard/Driving License etc ."
                          id={2}
                          isConsent={
                            consentTokenEnrolmentDetails.consent_acknowledgement ==
                            1
                              ? true
                              : false
                          }
                          onFileSelect={(file: File) =>
                            processIdentifyImage(file, selectedDocumentType)
                          }
                          file={identifyImageUpload?.file}
                          reset={() => resetIdentifyImage()}
                          isLoading={processingIdentifyImage}
                          error={!!error}
                          helperText={error?.message}
                        />
                      </Stack>
                    </div>
                  )}
                  rules={{
                    required: {
                      value:
                        getFieldValidation(
                          programOptions[0].config_data,
                          FieldNames.IdProofDocument
                        )?.required ?? false,
                      message: "Field Required",
                    },
                  }}
                />
              )}
            {getFieldValidation(
              programOptions[0].config_data,
              FieldNames.PurchaseInvoiceDocument
            ) &&
              getFieldValidation(
                programOptions[0].config_data,
                FieldNames.PurchaseInvoiceDocument
              )?.visibility && (
                <Controller
                  name="purchaseInvoiceUuid"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <div
                      ref={(el) =>
                        (fieldRefs.current["purchaseInvoiceUuid"] = el)
                      }
                      style={{ width: "100%" }}
                    >
                      <Stack>
                        <UploadFileCard
                          documentGroupType="INVOICE"
                          refName="purchaseInvoice"
                          handleOpenModal={handleOpenSelectCaptureMode}
                          cameraInputRef={purchaseInvoiceCameraInputRef}
                          galleryInputRef={purchaseInvoiceGalleryInputRef}
                          uuid={value}
                          title={`Upload Purchase Invoice`}
                          id={3}
                          isConsent={
                            consentTokenEnrolmentDetails.consent_acknowledgement ==
                            1
                              ? true
                              : false
                          }
                          onFileSelect={(file: File) =>
                            processInvoiceImage(file, selectedDocumentType)
                          }
                          file={invoiceImageUpload?.file}
                          reset={() => resetInvoiceImage()}
                          isLoading={processingInvoiceImage}
                          error={!!error}
                          helperText={error?.message}
                        />
                      </Stack>
                    </div>
                  )}
                  rules={{
                    required: {
                      value:
                        (watch("patientStatus") == "out_patient" &&
                          getFieldValidation(
                            programOptions[0].config_data,
                            FieldNames.PurchaseInvoiceDocument
                          )?.required) ??
                        false,
                      message: "Field Required",
                    },
                  }}
                />
              )}
            {getFieldValidation(
              programOptions[0].config_data,
              FieldNames.AddressProofDocument
            ) &&
              getFieldValidation(
                programOptions[0].config_data,
                FieldNames.AddressProofDocument
              )?.visibility && (
                <Controller
                  name="addressProofUuid"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <UploadFileCard
                      refName="address"
                      documentGroupType="ADDRESS_PROOF"
                      handleOpenModal={handleOpenSelectCaptureMode}
                      cameraInputRef={addressCameraInputRef}
                      galleryInputRef={addressGalleryInputRef}
                      uuid={value}
                      title="Upload Address proof"
                      id={4}
                      isConsent={
                        consentTokenEnrolmentDetails.consent_acknowledgement ==
                        1
                          ? true
                          : false
                      }
                      onFileSelect={(file: File) =>
                        processAddressImage(file, selectedDocumentType)
                      }
                      file={addressImageUpload?.file}
                      reset={() => resetAddressImage()}
                      isLoading={processingAddressImage}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                  rules={{
                    required: {
                      value:
                        getFieldValidation(
                          programOptions[0].config_data,
                          FieldNames.AddressProofDocument
                        )?.required ?? false,
                      message: "Field Required",
                    },
                  }}
                />
              )}
            {programOptions.length > 0 &&
              getFieldValidation(
                programOptions[0].config_data,
                FieldNames.PatientConsent
              ) &&
              getFieldValidation(
                programOptions[0].config_data,
                FieldNames.PatientConsent
              )?.visibility && (
                <>
                  {programOptions[0].patient_consent && (
                    <Accordion expanded>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="subtitle2" fontWeight={660}>
                          Consent
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <InformationText
                          text={programOptions[0].patient_consent}
                        />
                      </AccordionDetails>
                    </Accordion>
                  )}

                  {programOptions.length > 0 &&
                    getFieldValidation(
                      programOptions[0].config_data,
                      FieldNames.TermsAndConditions
                    ) &&
                    getFieldValidation(
                      programOptions[0].config_data,
                      FieldNames.TermsAndConditions
                    )?.visibility && (
                      <>
                        {programOptions[0].terms_and_conditions && (
                          <Accordion expanded>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography variant="subtitle2" fontWeight={660}>
                                Terms and Conditions
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <InformationText
                                text={programOptions[0].terms_and_conditions}
                              />
                            </AccordionDetails>
                          </Accordion>
                        )}
                      </>
                    )}

                  {programOptions[0].privacy_notice &&
                    getFieldValidation(
                      programOptions[0].config_data,
                      FieldNames.PrivacyNotice
                    ) &&
                    getFieldValidation(
                      programOptions[0].config_data,
                      FieldNames.PrivacyNotice
                    )?.visibility && (
                      <Accordion expanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography variant="subtitle2" fontWeight={660}>
                            Privacy Notice
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <InformationText
                            text={programOptions[0].privacy_notice}
                          />
                        </AccordionDetails>
                      </Accordion>
                    )}
                </>
              )}

            {consentTokenEnrolmentDetails.consent_acknowledgement === 1 ? (
              <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <FormControlLabel
                  control={<Checkbox defaultChecked disabled />}
                  label="Accept Consent"
                />
                <Typography>Date: {moment().format("YYYY-MM-DD")}</Typography>
              </Stack>
            ) : (
              <Controller
                name="consent"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Stack
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox onChange={onChange} required value={value} />
                      }
                      label="Accept Consent"
                    />
                    <Typography>
                      Date: {moment().format("YYYY-MM-DD")}
                    </Typography>
                  </Stack>
                )}
                rules={{ required: { value: true, message: "Field Required" } }}
              />
            )}

            {!consentTokenEnrolmentDetails.consent_acknowledgement && (
              <LoadingButton
                loading={consentLoading || createEnrolmentLoading}
                disabled={programOptions.length == 0 || !watch("consent")}
                variant="contained"
                color="secondary"
                loadingPosition="center"
                onClick={handleSubmit(onSubmit, onError)}
              >
                Submit
              </LoadingButton>
            )}
          </Stack>
        </>
      )}
      {openSelectImageCaptureMode && (
        <CaptureImageModal
          openCaptureImageModal={openSelectImageCaptureMode}
          handleCaptureFromGallery={handleCaptureFromGallery}
          handleCaptureFromCamera={handleCaptureFromCamera}
          selectedDocumentGroup={selectedDocumentGroup}
          handleCaptureImageModal={() => {
            handleOpenSelectCaptureMode(false, "", "");
          }}
        />
      )}
    </Stack>
  );
};

export default EnrollmentForm;
