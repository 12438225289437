import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import {
  COMMON_AUTH_HEADER,
  COMMON_PATIENT_SERVICE_API_URL,
  PATIENT_TOKEN_KEY,
} from "../../../../base";
import Endpoints from "../../../../domain/endpoints";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import { usePersonalDetailStore } from "./personalDetailsStore";
import { LocalJsonStorage } from "../../../../infra/http/local-json-storage";
import { AddPatientContacts } from "../../../../domain/usages/add-patient-contacts";
import { RemoteAddPatientContacts } from "../../../../data/usecases/remote-add-patient-contacts";
import { useEnrollmentMainStore } from "../../enrollmentListStore/enrollmentMainStore";
import { LocalLoggedInUser } from "../../../../data/usecases/local-logged-in-user";

interface AddPatientContactDetailSliceType {
  addContact: Function;
}
const initialStates = {};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useAddPatientContactDetailSlice: StateCreator<
  AddPatientContactDetailSliceType
> = (set, get) => ({
  ...initialStates,
  addContact: async (patientId: string, payload: AddPatientContacts.Params) => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getPatientToken();
    axiosHttpClient.setAuthHeaders({ [COMMON_AUTH_HEADER]: `Bearer ${(token)}` });

    const remoteAddPatientContacts: AddPatientContacts =
      new RemoteAddPatientContacts(
        `${COMMON_PATIENT_SERVICE_API_URL}${Endpoints.ADD_PATIENT_CONTACT_DETAILS}`,
        axiosHttpClient
      );
    usePersonalDetailStore.getState().handleLoading(true);
    let result = await remoteAddPatientContacts.add(patientId, payload);
    if (result.success) {
      usePersonalDetailStore.getState().handleLoading(false);
      usePersonalDetailStore
        .getState()
        .getPatient(
          useEnrollmentMainStore.getState().selectedCard.patient_uuid
        );
      usePersonalDetailStore.getState().handleEditPersonalDetails(null);
      Swal.fire("Added contacts details successfully.", "", "success");
    } else {
      usePersonalDetailStore.getState().handleLoading(false);

      if (result.errors && result.errors.length > 0) {
        Swal.fire(result.errors[0].message, "", "error");
      } else {
        Swal.fire(result.message, "", "error");
      }
    }
  },
});
