import { AddCommentsForFollowUp } from "../../../domain/usages/follow-ups/add-comments-for-follow-up";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpPostClient } from "../../protocols/http/http-post-client";

export class RemoteAddCommentsForFollowUp implements AddCommentsForFollowUp {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async add(
    threadId: string,
    followUpId: string,
    params: AddCommentsForFollowUp.Params
  ): Promise<any> {
    const httpResponse = await this.httpPostClient.post({
      url: this.url
        .replace(":threadId", threadId)
        .replace(":followupId", followUpId),
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
