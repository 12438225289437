import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Modal,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import { styled } from "@mui/material/styles";
import IMAGES from "../../../assets/images";
import { toTitleCase } from "../../../domain/Utils";
import { useOrderDetailsStore } from "../../../store/stockistPageStore/orderStore/orderDetailsStore";
import CircularProgress from "@mui/material/CircularProgress";
import OrderStatus from "../../../domain/orderStatus";
import ViewOrderDocumentModal from "./ViewOrderDocumentModal";
import { useSendToCFAStore } from "../../../store/adminPageStore/patientDetailsStore/sendToCFAStore/sendToCFAStore";
import FileUpload from "../FileUpload";
import ImageGallery from "../ImageGallery";
import { useFulfillmentStore } from "../../../store/fulfillment/fulfilmentStore";
import { useLoginPageStore } from "../../../store/loginPageStore/loginPageStore";
import DocumentUploadType from "../../../domain/documentType";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { LocalLoggedInUser } from "../../../data/usecases/local-logged-in-user";
import { useNavigate } from "react-router-dom";
import AddOrderDocumentsModal from "./AddOrderDocumentsModal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: 10,
  boxShadow: 24,
  p: 4,
  paddingTop: 1,

  overflow: "auto",
};
type Props = {
  openModal: boolean;
  handleClose: Function;
};

const OrderDetailsModal: React.FC<Props> = ({ openModal, handleClose }) => {
  const navigate = useNavigate();
  const storage = LocalJsonStorage.getInstance();
  const loggedInUser = new LocalLoggedInUser(storage);
  const [documentType, setDocumentType] = useState<string>("");

  const { patientOrderDetails, getOrderDetailsLoading } =
    useOrderDetailsStore();

  const { generateUrl, uploadToBucket, uploadFiles, ConfirmFileUpload } =
    useFulfillmentStore();
  const { loginDetails } = useLoginPageStore();
  const [imageUploadLoading, setImageUploadLoading] = useState<boolean>(false);

  const { cancelOrder, cancelOrderLoading } = useSendToCFAStore();
  const [showOrderDocument, setShowOrderDocument] = useState<boolean>(false);
  const [showAddOrderDocument, setShowAddOrderDocument] =
    useState<boolean>(false);
  const [showCancelConfirmModal, setShowCancelConfirmModal] =
    useState<boolean>(false);

  const handleOpenCancelConfirmModal = (value: boolean) => {
    setShowCancelConfirmModal(value);
  };

  const handleSelectDocumentType = (value: string) => {
    setDocumentType(value);
  };

  const handleCancelOrder = async (reason: string) => {
    let payload = {
      reference_number: patientOrderDetails.sub_orders[0].reference_number,
      status: OrderStatus.REJECTED,
      mode: "WEB_APP",
      comment: reason,
    };
    const result = await cancelOrder(payload);
    if (result.success) {
      handleClose();
      handleOpenCancelConfirmModal(false);
    }
  };

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },

    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#3F51B7",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#3F51B7",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,

    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor: "#3F51B7",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
      backgroundColor: "#3F51B7",
    }),
  }));

  function ColorlibStepRTOIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
      1: (
        <Stack alignItems={"center"} justifyContent={"center"}>
          <Avatar src={IMAGES.CreatedBoxImage}></Avatar>
        </Stack>
      ),
      2: (
        <Stack alignItems={"center"} justifyContent={"center"}>
          <Avatar src={IMAGES.OrderAccepted}></Avatar>
        </Stack>
      ),
      3: (
        <Stack alignItems={"center"} justifyContent={"center"}>
          <Avatar src={IMAGES.OrderPacking}></Avatar>
        </Stack>
      ),
      4: (
        <Stack alignItems={"center"} justifyContent={"center"}>
          <Avatar src={IMAGES.PendingLR}></Avatar>
        </Stack>
      ),
      5: (
        <Stack alignItems={"center"} justifyContent={"center"}>
          <Avatar src={IMAGES.SelectedTruck}></Avatar>
        </Stack>
      ),
      6: (
        <Stack alignItems={"center"} justifyContent={"center"}>
          <Avatar src={IMAGES.RTOOrder}></Avatar>
        </Stack>
      ),
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
      1: (
        <Stack alignItems={"center"} justifyContent={"center"}>
          <Avatar src={IMAGES.CreatedBoxImage}></Avatar>
        </Stack>
      ),
      2: (
        <Stack alignItems={"center"} justifyContent={"center"}>
          <Avatar src={IMAGES.OrderAccepted}></Avatar>
        </Stack>
      ),
      3: (
        <Stack alignItems={"center"} justifyContent={"center"}>
          <Avatar src={IMAGES.OrderPacking}></Avatar>
        </Stack>
      ),
      4: (
        <Stack alignItems={"center"} justifyContent={"center"}>
          <Avatar src={IMAGES.PendingLR}></Avatar>
        </Stack>
      ),
      5: (
        <Stack alignItems={"center"} justifyContent={"center"}>
          <Avatar src={IMAGES.SelectedTruck}></Avatar>
        </Stack>
      ),
      6: (
        <Stack alignItems={"center"} justifyContent={"center"}>
          <Avatar src={IMAGES.OrderDelivered}></Avatar>
        </Stack>
      ),
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
  function ColorlibStepLabOrderIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
      1: (
        <Stack alignItems={"center"} justifyContent={"center"}>
          <Avatar src={IMAGES.CreatedBoxImage}></Avatar>
        </Stack>
      ),
      2: (
        <Stack alignItems={"center"} justifyContent={"center"}>
          <Avatar src={IMAGES.OrderAccepted}></Avatar>
        </Stack>
      ),
      3: (
        <Stack alignItems={"center"} justifyContent={"center"}>
          <Avatar src={IMAGES.PatientReport}></Avatar>
        </Stack>
      ),
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
  function ColorlibStepRejectedIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
      1: (
        <Stack alignItems={"center"} justifyContent={"center"}>
          <Avatar src={IMAGES.CreatedBoxImage}></Avatar>
        </Stack>
      ),
      2: (
        <Stack alignItems={"center"} justifyContent={"center"}>
          <Avatar src={IMAGES.CanceledOrder}></Avatar>
        </Stack>
      ),
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }
  function ColorlibStepHoldingOrderIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
      1: (
        <Stack alignItems={"center"} justifyContent={"center"}>
          <Avatar src={IMAGES.CreatedBoxImage}></Avatar>
        </Stack>
      ),
      2: (
        <Stack alignItems={"center"} justifyContent={"center"}>
          <Avatar src={IMAGES.HoldOrder}></Avatar>
        </Stack>
      ),
      3: (
        <Stack alignItems={"center"} justifyContent={"center"}>
          <Avatar src={IMAGES.OrderPacking}></Avatar>
        </Stack>
      ),
      4: (
        <Stack alignItems={"center"} justifyContent={"center"}>
          <Avatar src={IMAGES.SelectedTruck}></Avatar>
        </Stack>
      ),
      5: (
        <Stack alignItems={"center"} justifyContent={"center"}>
          <Avatar src={IMAGES.OrderDelivered}></Avatar>
        </Stack>
      ),
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  const getCurrentOrderStatus = (status: string) => {
    if (status == OrderStatus.CREATED) {
      return 0;
    } else if (
      status == OrderStatus.ACCEPTED ||
      status == OrderStatus.REJECTED ||
      status == OrderStatus.HOLD
    ) {
      return 1;
    } else if (
      status == OrderStatus.PACKED ||
      status == OrderStatus.COMPLETED
    ) {
      return 2;
    } else if (status == OrderStatus.PENDING_LR) {
      return 3;
    } else if (status == OrderStatus.DISPATCHED) {
      return 4;
    } else if (
      status == OrderStatus.DELIVERED ||
      status == OrderStatus.RETURN_TO_ORIGIN
    ) {
      return 5;
    }

    return 0;
  };

  const getTitleForOrder = (status: string, orderType: string) => {
    if (status == OrderStatus.REJECTED) {
      return ["Created", "Rejected by Supplier"];
    } else if (status == OrderStatus.HOLD) {
      return ["Created", "On Hold", "Packed", "Dispatched", "Delivered"];
    } else if (status == OrderStatus.RETURN_TO_ORIGIN) {
      return [
        "Created",
        "Accepted by Supplier",
        "Packing",
        "Pending LR",
        "Dispatched",
        "Return To Origin",
      ];
    } else {
      return [
        "Created",
        "Accepted by Supplier",
        "Packing",
        "Pending LR",
        "Dispatched",
        "Delivered",
      ];
    }
  };

  const calculateTotalOrderedAmount = () => {
    let totalAmount = 0;
    if (
      patientOrderDetails.sub_orders.length > 0 &&
      patientOrderDetails.sub_orders[0].items.length > 0
    ) {
      for (let i = 0; i < patientOrderDetails.sub_orders[0].items.length; i++) {
        totalAmount =
          totalAmount +
          parseFloat(patientOrderDetails.sub_orders[0].items[i].total_amount) +
          +(
            parseFloat(
              patientOrderDetails.sub_orders[0].items[i].total_amount
            ) * parseFloat(patientOrderDetails.sub_orders[0].items[i].igst)
          ) /
            100;
      }
      return totalAmount;
    } else {
      return 0;
    }
  };

  const uploadImageFile = async (
    file: File | null,
    handleModalClose: Function
  ) => {
    setImageUploadLoading(true);
    generateUrl(
      {
        file: file,
        success: false,
      },
      documentType
    )
      .then((res: any) => uploadToBucket(res))
      .then((res: any) => ConfirmFileUpload(res))
      .then(async (res: any) => {
        await uploadFiles(
          patientOrderDetails.id,
          patientOrderDetails.sub_orders[0].id,
          documentType,
          loggedInUser.getUserDetails().id,
          loggedInUser.getUserDetails().full_name,
          res
        );
      })
      .then(async (res: any) => {
        setImageUploadLoading(false);
        setShowAddOrderDocument(false);
        handleSelectDocumentType("");
        handleClose();
        handleModalClose();
      })
      .catch((error: any) => {
        setImageUploadLoading(false);
      });
  };
  return (
    <Modal
      open={openModal}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {getOrderDetailsLoading && patientOrderDetails ? (
          <>
            <Stack justifyContent={"end"} alignItems={"end"}>
              <IconButton onClick={() => handleClose()}>
                <CloseIcon fontSize="small" color="error" />
              </IconButton>
            </Stack>
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              width={"100%"}
              height={"100%"}
            >
              <CircularProgress color="secondary" />
            </Stack>
          </>
        ) : (
          <>
            <Stack justifyContent={"end"} alignItems={"end"}>
              <IconButton onClick={() => handleClose()}>
                <CloseIcon fontSize="small" color="error" />
              </IconButton>
            </Stack>
            <Card
              sx={{ width: "100%", borderRadius: 5, marginBottom: 2 }}
              variant="outlined"
            >
              <CardContent>
                <Stack
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  marginBottom={2}
                >
                  <Typography
                    marginBottom={2}
                    fontWeight={660}
                    variant="subtitle2"
                  >
                    Order Tracker - #{patientOrderDetails.id} (
                    {patientOrderDetails.order_type})
                  </Typography>
                  <Stack flexDirection={"row"} gap={1}>
                    {/* {patientOrderDetails.order_type == OrderType.COMMERCIAL &&
                    (patientOrderDetails.sub_orders[0].status ==
                      OrderStatus.ACCEPTED ||
                      patientOrderDetails.sub_orders[0].status ==
                        OrderStatus.CREATED ||
                      patientOrderDetails.sub_orders[0].status ==
                        OrderStatus.HOLD) && ( */}
                    {/* <Button
                      color="error"
                      size="small"
                      variant="contained"
                      onClick={() => handleOpenCancelConfirmModal(true)}
                    >
                      <Typography
                        variant="caption"
                        fontWeight={770}
                        textTransform={"none"}
                      >
                        Cancel Order
                      </Typography>
                    </Button> */}
                    {/* <FileUpload
                      buttonTitle="Upload Supporting Documents"
                      onSuccessCallBack={uploadImageFile}
                      loading={imageUploadLoading}
                    /> */}
                    <Button
                      variant="contained"
                      onClick={() => setShowAddOrderDocument(true)}
                    >
                      <Typography textTransform={"none"}>
                        Upload Documents
                      </Typography>
                    </Button>
                  </Stack>
                </Stack>

                <Stepper
                  activeStep={getCurrentOrderStatus(
                    patientOrderDetails.sub_orders[0].status
                  )}
                  alternativeLabel
                  connector={<ColorlibConnector />}
                >
                  {getTitleForOrder(
                    patientOrderDetails.sub_orders[0].status,
                    patientOrderDetails.order_type
                  ).map((label) => (
                    <Step key={label}>
                      <StepLabel
                        StepIconComponent={
                          patientOrderDetails.sub_orders[0].status ==
                          OrderStatus.REJECTED
                            ? ColorlibStepRejectedIcon
                            : patientOrderDetails.sub_orders[0].status ==
                              OrderStatus.HOLD
                            ? ColorlibStepHoldingOrderIcon
                            : patientOrderDetails.sub_orders[0].status ==
                              OrderStatus.RETURN_TO_ORIGIN
                            ? ColorlibStepRTOIcon
                            : ColorlibStepIcon
                        }
                      >
                        <Typography variant="caption" fontWeight={660}>
                          {label}
                        </Typography>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </CardContent>
            </Card>
            {/* <Card sx={{ width: "100%", borderRadius: 5 }}>
              <CardContent>
                <Typography fontWeight={660} variant="subtitle2">
                  Supporting Documents
                </Typography>
              </CardContent>
              <ImageGallery
                images={[
                  {
                    url: "https://images.unsplash.com/photo-1593642634443-44adaa06623a?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60",
                    title: "Image 1",
                  },
                  {
                    url: "https://images.unsplash.com/photo-1521747116042-5a810fda9664?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60",
                    title: "Image 2",
                  },
                  {
                    url: "https://images.unsplash.com/photo-1527613426441-4da17471b66d?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60",
                    title: "Image 3",
                  },
                  {
                    url: "https://images.unsplash.com/photo-1534126511673-b6899657816a?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60",
                    title: "Image 4",
                  },
                  {
                    url: "https://images.unsplash.com/photo-1542233428-56c5eeca2f5b?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60",
                    title: "Image 5",
                  },
                  {
                    url: "https://www.pdf995.com/samples/pdf.pdf", // Example PDF URL
                    title: "Sample PDF 1",
                  },
                  {
                    url: "https://s29.q4cdn.com/175625835/files/doc_downloads/test.pdf", // Example PDF URL
                    title: "Sample PDF 2",
                  },
                  {
                    url: "https://www.orimi.com/pdf-test.pdf", // Example PDF URL
                    title: "Sample PDF 3",
                  },
                  {
                    url: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf", // Example PDF URL
                    title: "Sample PDF 4",
                  },
                  {
                    url: "https://www.pdf995.com/samples/pdf.pdf", // Duplicate PDF URL for demo purposes
                    title: "Sample PDF 5",
                  },
                ]}
              />
            </Card> */}
            <Stack
              marginTop={1}
              alignItems={"center"}
              justifyContent={"space-around"}
              flexDirection={"row"}
              gap={2}
            >
              <Card sx={{ width: "50%", borderRadius: 5 }} variant="outlined">
                <CardHeader
                  title={
                    <Typography fontWeight={660}>Receiver Details</Typography>
                  }
                  sx={{
                    backgroundColor: "secondary.main",
                    textAlign: "center",
                    color: "white",
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                  }}
                />
                <CardContent>
                  <Typography textAlign={"center"}>
                    {toTitleCase(
                      patientOrderDetails.sub_orders[0].address.line
                    )}
                    ,
                  </Typography>
                  <Typography textAlign={"center"}>
                    {toTitleCase(
                      patientOrderDetails.sub_orders[0].address.landmark
                    )}
                  </Typography>
                  <Typography textAlign={"center"}>
                    {toTitleCase(
                      patientOrderDetails.sub_orders[0].address.city
                    )}
                    ,
                    {toTitleCase(
                      patientOrderDetails.sub_orders[0].address.district
                    )}
                  </Typography>
                  <Typography textAlign={"center"}>
                    {toTitleCase(
                      patientOrderDetails.sub_orders[0].address.state
                    )}
                    ,{patientOrderDetails.sub_orders[0].address.pin_code},
                    {toTitleCase(
                      patientOrderDetails.sub_orders[0].address.country
                    )}
                  </Typography>
                </CardContent>
              </Card>
              <Card sx={{ width: "50%", borderRadius: 5 }} variant="outlined">
                <CardHeader
                  title={
                    <Typography fontWeight={660}>Supplier Details</Typography>
                  }
                  sx={{
                    backgroundColor: "secondary.main",
                    textAlign: "center",
                    color: "white",
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                  }}
                />
                <CardContent>
                  <Typography textAlign={"center"}>
                    {toTitleCase(
                      patientOrderDetails.sub_orders[0].suppliers[0].name
                    )}
                  </Typography>
                  <Typography textAlign={"center"}>
                    GST No:{" "}
                    {patientOrderDetails.sub_orders[0].suppliers[0].gst_no}
                  </Typography>
                  <Typography textAlign={"center"}>
                    PAN No:{" "}
                    {patientOrderDetails.sub_orders[0].suppliers[0].pan_no}
                  </Typography>
                  <Typography textAlign={"center"}>
                    DL No:{" "}
                    {patientOrderDetails.sub_orders[0].suppliers[0].dl_no
                      ? patientOrderDetails.sub_orders[0].suppliers[0].dl_no
                      : "-"}
                  </Typography>
                </CardContent>
              </Card>
              {patientOrderDetails.order_type == "LAB_ORDER" && (
                <Card sx={{ width: "50%", borderRadius: 5 }} variant="outlined">
                  <CardHeader
                    title={
                      <Typography fontWeight={660}>
                        Sample Collection Details
                      </Typography>
                    }
                    sx={{
                      backgroundColor: "secondary.main",
                      textAlign: "center",
                      color: "white",
                      borderTopLeftRadius: 5,
                      borderTopRightRadius: 5,
                    }}
                  />
                  <CardContent>
                    <Typography textAlign={"center"}>
                      Test Method:{" "}
                      {patientOrderDetails.meta_data?.method_of_test}
                    </Typography>
                    <Typography textAlign={"center"}>
                      Collection Type:{" "}
                      {patientOrderDetails.meta_data?.sample_collection_type}
                    </Typography>
                    <Typography textAlign={"center"}>
                      Date & Time:
                      {
                        patientOrderDetails.meta_data
                          ?.sample_collection_date_and_time
                      }
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Stack>

            <Paper
              sx={{
                width: "100%",

                overflow: "hidden",
                marginTop: 1,
                borderRadius: 5,
              }}
            >
              <TableContainer sx={{ maxHeight: 300, borderRadius: 5 }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ bgcolor: "#3F51B7", color: "white" }}
                        align={"center"}
                      >
                        Product Name
                      </TableCell>
                      <TableCell
                        sx={{ bgcolor: "#3F51B7", color: "white" }}
                        align={"center"}
                      >
                        Unit of Measure
                      </TableCell>
                      <TableCell
                        sx={{ bgcolor: "#3F51B7", color: "white" }}
                        align={"center"}
                      >
                        Unit Price
                      </TableCell>
                      <TableCell
                        sx={{ bgcolor: "#3F51B7", color: "white" }}
                        align={"center"}
                      >
                        Quantity
                      </TableCell>
                      <TableCell
                        sx={{ bgcolor: "#3F51B7", color: "white" }}
                        align={"center"}
                      >
                        Tax (in %)
                      </TableCell>
                      <TableCell
                        sx={{ bgcolor: "#3F51B7", color: "white" }}
                        align={"center"}
                      >
                        Amount (incl tax)
                      </TableCell>
                      {patientOrderDetails.sub_orders[0].status ===
                        OrderStatus.COMPLETED && (
                        <TableCell
                          sx={{ bgcolor: "#3F51B7", color: "white" }}
                          align={"center"}
                        >
                          Actions
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {patientOrderDetails.sub_orders[0].items.map((data) => {
                      return (
                        <TableRow hover>
                          <TableCell align={"center"}>
                            <Typography variant="subtitle2">
                              {data.product_name}
                            </Typography>
                          </TableCell>
                          <TableCell align={"center"}>
                            {" "}
                            <Typography variant="subtitle2">
                              {data.uom}{" "}
                            </Typography>
                          </TableCell>
                          <TableCell align={"center"}>
                            <Typography variant="subtitle2">
                              {parseFloat(data.unit_price).toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell align={"center"}>
                            {" "}
                            <Typography variant="subtitle2">
                              {data.quantity}{" "}
                            </Typography>
                          </TableCell>
                          <TableCell align={"center"}>
                            {" "}
                            <Typography variant="subtitle2">
                              {parseFloat(data.igst).toFixed(2)}
                            </Typography>
                          </TableCell>
                          <TableCell align={"center"}>
                            <Typography variant="subtitle2" fontWeight={660}>
                              {(
                                parseFloat(data.total_amount) +
                                (parseFloat(data.total_amount) *
                                  parseFloat(data.igst)) /
                                  100
                              ).toFixed(2)}
                            </Typography>
                          </TableCell>
                          {patientOrderDetails.sub_orders[0].status ===
                            OrderStatus.COMPLETED && (
                            <TableCell align={"center"}>
                              <Button
                                onClick={() => setShowOrderDocument(true)}
                              >
                                <Typography
                                  variant="subtitle2"
                                  textTransform={"none"}
                                  fontWeight={600}
                                >
                                  View Report
                                </Typography>
                              </Button>
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                    <TableRow>
                      <TableCell colSpan={4} />
                      <TableCell align="center">
                        <Typography variant="subtitle2" fontWeight={660}>
                          Approx. Total
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="subtitle2" fontWeight={660}>
                          Rs. {calculateTotalOrderedAmount().toFixed(2)}
                          /-
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        )}
        {showOrderDocument && (
          <ViewOrderDocumentModal
            openDocument={showOrderDocument}
            orderId={patientOrderDetails.id}
            subOrderId={patientOrderDetails.sub_orders[0].id}
            handleClose={() => setShowOrderDocument(false)}
          />
        )}

        <AddOrderDocumentsModal
          open={showAddOrderDocument}
          handleClose={() => setShowAddOrderDocument(false)}
          handleSuccessCallback={uploadImageFile}
          loading={imageUploadLoading}
          documentType={documentType}
          handleSelectDocumentType={handleSelectDocumentType}
        />
        {/* {showCancelConfirmModal && (
          <CancelOrderModal
            openCancelOrderModal={showCancelConfirmModal}
            handleCloseCancelModal={() => handleOpenCancelConfirmModal(false)}
            message={`Are you sure, you want to cancel the order #${patientOrderDetails.id} (
           ${patientOrderDetails.order_type})?`}
            handleSuccessCallback={handleCancelOrder}
            loading={cancelOrderLoading}
          />
        )} */}
      </Box>
    </Modal>
  );
};

export default OrderDetailsModal;
