import { HttpConstants } from "../protocols/http/http-constants";
import { HttpGetClient } from "../protocols/http/http-get-client";
import { GetOrderDetails } from "../../domain/usages/get-order-details";

export class RemoteGetOrderDetails implements GetOrderDetails {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async get(id: string, params?: GetOrderDetails.Params): Promise<any> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(":id", id),
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (httpResponse?.status == 200) {
      return httpResponse.data;
    } else{
      return 404
    }
  }
}
