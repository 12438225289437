import { create } from "zustand";
import { useAcceptOrderSlice } from "./acceptOrderSlice";
import { useDeliverOrderSlice } from "./deliverOrderSlice";
import { useDispatchOrderSlice } from "./dispatchOrderSlice";
import { useFetchOrderSlice } from "./fetchOrderSlice";
import { useUpdateOrderSlice } from "./updateOrderSlice";
import { useGetVendorSlice } from "./getVendorSlice";
import { useFilterOrderSlice } from "./filterOrderSlice";
import { usePackOrderSlice } from "./packOrderSlice";
import { useGenerateAddressLabelSlice } from "./generateDocuments/generateAddressLabeSlicel";
import { useGenerateDeliveryChallanSlice } from "./generateDocuments/generateDeliveryChallanSlice";
import { useFetchPatientOrderSlice } from "./fetchPatientOrderSlice";
import { useOrderDetailsSlice } from "./getOrderDetailsSlice";
import { useFetchSubOrderDetailsSlice } from "./fetchSubOrderDetails";

interface OrderDetailsStoreInterface
  extends ReturnType<typeof usePackOrderSlice>,
    ReturnType<typeof useGetVendorSlice>,
    ReturnType<typeof useFilterOrderSlice>,
    ReturnType<typeof useDeliverOrderSlice>,
    ReturnType<typeof useDispatchOrderSlice>,
    ReturnType<typeof useFetchOrderSlice>,
    ReturnType<typeof useAcceptOrderSlice>,
    ReturnType<typeof useUpdateOrderSlice>,
    ReturnType<typeof useGenerateAddressLabelSlice>,
    ReturnType<typeof useGenerateDeliveryChallanSlice>,
    ReturnType<typeof useFetchPatientOrderSlice>,
    ReturnType<typeof useOrderDetailsSlice>,
    ReturnType<typeof useFetchSubOrderDetailsSlice> {}

export const useOrderDetailsStore = create<OrderDetailsStoreInterface>(
  (...order) => ({
    ...useGenerateDeliveryChallanSlice(...order),
    ...useGenerateAddressLabelSlice(...order),
    ...usePackOrderSlice(...order),
    ...useGetVendorSlice(...order),
    ...useFilterOrderSlice(...order),
    ...useDeliverOrderSlice(...order),
    ...useDispatchOrderSlice(...order),
    ...useFetchOrderSlice(...order),
    ...useAcceptOrderSlice(...order),
    ...useUpdateOrderSlice(...order),
    ...useFetchPatientOrderSlice(...order),
    ...useOrderDetailsSlice(...order),
    ...useFetchSubOrderDetailsSlice(...order),
  })
);
