import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CustomImageCropper from "./CustomImageCropper";
import { Document, Page } from "react-pdf";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { LoadingButton } from "@mui/lab";

type Props = {
  buttonTitle: string;
  onSuccessCallBack: (file: File | null, handleModalClose: Function) => void;
  loading: boolean;
};

const FileUpload: React.FC<Props> = ({
  buttonTitle,
  onSuccessCallBack,
  loading,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [cropperOpen, setCropperOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [pdfDialogOpen, setPdfDialogOpen] = useState(false);
  const [numPages, setNumPages] = React.useState<number | null>(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [scale, setScale] = React.useState<number>(1.3);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0] || null;
    if (selectedFile) {
      setFile(selectedFile);
      if (selectedFile.type.startsWith("image/")) {
        setCropperOpen(true);
      } else if (selectedFile.type === "application/pdf") {
        setPdfUrl(URL.createObjectURL(selectedFile));
        setPdfDialogOpen(true);
      } else {
        alert("Unsupported file type.");
      }
    }
  };

  const handleZoomIn = () => {
    setScale((prevScale) => prevScale + 0.1); // Increase scale by 0.1
  };

  const handleZoomOut = () => {
    setScale((prevScale) => Math.max(0.1, prevScale - 0.1)); // Decrease scale by 0.1, with a minimum of 0.1
  };

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const handleCrop = async (croppedImageUrl: string) => {
    const response = await fetch(croppedImageUrl);
    const blob = await response.blob();
    const file = new File([blob], "cropped-image.jpg", { type: blob.type });

    onSuccessCallBack(file, () => setCropperOpen(false));
  };

  const handleClosePdfDialog = () => {
    setPdfDialogOpen(false);
    if (pdfUrl) {
      URL.revokeObjectURL(pdfUrl);
      setPdfUrl(null);
    }
  };

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  useEffect(() => {
    // Cleanup object URL for PDF
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [pdfUrl]);

  return (
    <div>
      <input
        accept="image/*,application/pdf"
        style={{ display: "none" }}
        id="upload-button"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="upload-button">
        <Button
          variant="contained"
          component="span"
          color="secondary"
          startIcon={<CloudUploadIcon />}
        >
          <Typography textTransform={"none"}>{buttonTitle}</Typography>
        </Button>
      </label>

      {file && cropperOpen && (
        <CustomImageCropper
          imageUrl={URL.createObjectURL(file)}
          onCrop={handleCrop}
          onClose={() => setCropperOpen(false)}
          loading={loading}
        />
      )}
      <Dialog
        open={pdfDialogOpen}
        onClose={handleClosePdfDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>PDF Preview</DialogTitle>
        <DialogContent>
          {pdfUrl && (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                  marginBottom: "10px",
                }}
              >
                <Box
                  border={"1px solid"}
                  borderRadius={"30%"}
                  color={"primary.main"}
                  bgcolor={"primary.main"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <IconButton size="small" onClick={handleZoomIn}>
                    <ZoomInIcon sx={{ color: "white" }} />
                  </IconButton>
                </Box>
                <Box
                  border={"1px solid"}
                  borderRadius={"30%"}
                  color={"primary.main"}
                  bgcolor={"primary.main"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <IconButton size="small" onClick={handleZoomOut}>
                    <ZoomOutIcon sx={{ color: "white" }} />
                  </IconButton>
                </Box>

                <Stack
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexDirection={"row"}
                  gap={2}
                >
                  <Typography>
                    Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                    {numPages || "--"}
                  </Typography>
                  <Button
                    size="small"
                    variant="contained"
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                  >
                    Previous
                  </Button>
                  {numPages && (
                    <Button
                      size="small"
                      variant="contained"
                      disabled={pageNumber >= numPages}
                      onClick={nextPage}
                    >
                      Next
                    </Button>
                  )}
                </Stack>
              </Box>
              <Container
                sx={{
                  overflowY: "auto",
                  flexDirection: "row",
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "60vh",
                  border: "1px solid",
                }}
              >
                <Document
                  file={pdfUrl}
                  loading={
                    <Stack
                      alignItems={"center"}
                      justifyItems={"center"}
                      height={"60vh"}
                    >
                      <CircularProgress />
                    </Stack>
                  }
                  onEnded={() => <Typography>End of file</Typography>}
                  error={
                    <Stack
                      alignItems={"center"}
                      justifyContent={"center"}
                      marginTop={10}
                      gap={1}
                    >
                      <Typography variant="subtitle2" fontWeight={660}>
                        Failed to Load PDF
                      </Typography>
                    </Stack>
                  }
                  noData={
                    <Stack
                      alignItems={"center"}
                      justifyContent={"center"}
                      marginTop={10}
                    >
                      No Records found
                    </Stack>
                  }
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page pageNumber={pageNumber} scale={scale} />
                </Document>
              </Container>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClosePdfDialog}
            color="primary"
            disabled={loading}
          >
            Close
          </Button>
          <LoadingButton
            loading={loading}
            onClick={() => onSuccessCallBack(file, handleClosePdfDialog)}
            color="primary"
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FileUpload;
