import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { TextField, Grid, Typography } from "@mui/material";
import { LoginItems } from "../../../domain/models/user-login-type";
import { useLoginPageStore } from "../../../store/loginPageStore/loginPageStore";
import { useNavigate } from "react-router-dom";
import { pageRoutes } from "../../../routes";
import Swal from "sweetalert2";
import LoadingButton from "@mui/lab/LoadingButton";
import LoginIcon from "@mui/icons-material/Login";
import { useLoginStore } from "../../../store/loginPageStore/loginStore";

const LoginForm: React.FC = () => {
  const { handleLogin, loginDetails, loginFailed, loginLoading } =
    useLoginPageStore();
  const { tokenForPatient, generateTokenLoading } = useLoginStore();
  const navigate = useNavigate();

  const { handleSubmit, control } = useForm<LoginItems>({
    mode: "onChange",
  });

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (Object.keys(loginDetails).length !== 0) {
      if (loginDetails.role_id === 9 && tokenForPatient) {
        navigate(pageRoutes.adminPage);
      } else if (loginDetails?.role_id === 10) {
        navigate(pageRoutes.orderService);
      } else {
        Swal.fire("Invalid User", "", "error");
      }
    }
  }, [tokenForPatient]);

  useEffect(() => {
    if (loginFailed) {
      setLoading(false);
    }
  }, [loginFailed]);

  const handleClick = (data: LoginItems) => {
    handleLogin(data);
    setLoading(true);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" fontWeight={600}>
          Login Form
        </Typography>
      </Grid>

      <Grid item xs={9}>
        <Typography variant="subtitle1" fontWeight={"bold"}>
          Username
        </Typography>
        <Controller
          name="Username"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              fullWidth
              size="small"
              value={value}
              onChange={onChange}
              error={error && true}
              helperText={error?.message}
            />
          )}
          rules={{
            required: { value: true, message: "Field Required" },
          }}
        />
      </Grid>

      <Grid item xs={9}>
        <Typography variant="subtitle1" fontWeight={"bold"}>
          Password
        </Typography>
        <Controller
          name="Password"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              fullWidth
              type="password"
              variant="outlined"
              size="small"
              value={value}
              onChange={onChange}
              error={error && true}
              helperText={error?.message}
            />
          )}
          rules={{
            required: { value: true, message: "Field Required" },
          }}
        />
      </Grid>
      <Grid item xs={9}>
        <LoadingButton
          loading={loginLoading || generateTokenLoading}
          loadingPosition="start"
          startIcon={<LoginIcon fontSize="small" />}
          color="secondary"
          variant="contained"
          fullWidth
          onClick={handleSubmit(handleClick)}
        >
          Login
        </LoadingButton>
      </Grid>
    </Grid>
  );
};
export default LoginForm;
