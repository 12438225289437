import { HttpConstants } from "../protocols/http/http-constants";
import { HttpGetClient } from "../protocols/http/http-get-client";
import { GetAddressBasedOnPincode } from "../../domain/usages/get-address-based-on-pincode";
export class RemoteGetAddressBasedOnPincode
  implements GetAddressBasedOnPincode
{
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async fetch(
    pincode: string,
    params?: GetAddressBasedOnPincode.Params
  ): Promise<any> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(":pincode", pincode),
      query: {},
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (httpResponse.status == 200) {
      return httpResponse.data;
    }
  }
}
