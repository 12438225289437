import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
  PSP_ORDER_SERVICE,
} from "../../../base";
import { RemoteVendorMaster } from "../../../data/usecases/remote-vendor-master";
import Endpoints from "../../../domain/endpoints";
import { Vendor } from "../../../domain/models/get-vendor";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { VendorMaster } from "../../../domain/usages/vendor-master";
import { LocalLoggedInUser } from "../../../data/usecases/local-logged-in-user";

interface GetVendorSLiceType {
  fetchVendor: Function;
  vendorList: Vendor[];
}

const initialStates = {
  vendorList: [],
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useGetVendorSlice: StateCreator<GetVendorSLiceType> = (set) => ({
  ...initialStates,
  fetchVendor: async () => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getPSPToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token });

    const remoteVendorMaster: VendorMaster = new RemoteVendorMaster(
      `${PSP_ORDER_SERVICE}${Endpoints.GET_VENDOR}`,
      axiosHttpClient
    );

    let result = await remoteVendorMaster.get();
    set(() => ({
      vendorList: result?.vendors,
    }));
  },
});
