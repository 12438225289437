import {
  Box,
  FilledInput,
  IconButton,
  InputAdornment,
  Modal,
  OutlinedInput,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useOrderDetailsStore } from "../../../store/stockistPageStore/orderStore/orderDetailsStore";
import { useEffect, useState } from "react";
import { useEnrollmentMainStore } from "../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";

type Props = {
  openModal: boolean;
  handleCloseModal: Function;
};

const style = {
  borderRadius: "12px",
  display: "flex",
  gap: 2,
  flexDirection: "column",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const UnlockModal: React.FC<Props> = ({ openModal, handleCloseModal }) => {
  const { handleTextBlur, error, isBlurred } = useEnrollmentMainStore();
  const [showPassword, setShowPassword] = useState(false);
  const [inputPassword, setInputPassword] = useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  useEffect(() => {
    if (!isBlurred) {
      handleCloseModal();
    }
  }, [isBlurred]);
  const handleMouseUpPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <>
      <Modal open={openModal} onClose={() => handleCloseModal()}>
        <Box sx={style}>
          <Stack alignItems={"center"} gap={5}>
            <Typography>🔑 Enter your secret key to continue:</Typography>
            <OutlinedInput
              fullWidth
              error={error != ""}
              onChange={(e) => setInputPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={
                      showPassword
                        ? "hide the password"
                        : "display the password"
                    }
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    onMouseUp={handleMouseUpPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {error != "" && (
              <Typography variant="subtitle2" color={"error.main"}>
                {error}
              </Typography>
            )}

            <Button
              variant="contained"
              onClick={() => {
                handleTextBlur(inputPassword);
              }}
            >
              <Typography textTransform={"none"}>Submit</Typography>
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
export default UnlockModal;
