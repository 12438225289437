import { CreateDemoRequest } from "../../domain/usages/create-demo-request";
import { HttpConstants } from "../protocols/http/http-constants";
import { HttpPostClient } from "../protocols/http/http-post-client";

export class RemoteCreateDemoRequest implements CreateDemoRequest {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}
  async create(params: CreateDemoRequest.Params): Promise<any> {
    const httpResponse = await this.httpPostClient.post({
      url: this.url,
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

      return httpResponse.data;

  }
}
