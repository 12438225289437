import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import { usePersonalDetailStore } from "../../../../../store/adminPageStore/patientDetailsStore/personalDetailStore.ts/personalDetailsStore";
import { Controller, useForm } from "react-hook-form";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Constant } from "../../../../../constants/constant";
import CancelIcon from "@mui/icons-material/Cancel";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import { toTitleCase } from "../../../../../domain/Utils";
import DeactivateConfirmModal from "./DeactivateConfirmModal";
import StarsIcon from "@mui/icons-material/Stars";
import "../../../../../App.css";
import { useEnrollmentMainStore } from "../../../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";

type Props = {};

type ContactDetailType = {
  isPrimary: boolean;
  contactType: string;
  contact: string;
  fullName: string;
};

type CareTakerType = {
  fullName: string;
  mobileNumber: string;
  whatsapp: string;
  email: string;
  [prop: string]: number | string | string[];
};

interface Contact {
  isPrimary: boolean;
  self: boolean;
  status: string;
  type: string;
  value: string;
  _id: string;
}

interface FormattedData {
  createdAt: string;
  fullName: string;
  contacts: Contact[];
}

const ContactDetails = (props: Props) => {
  const { selectedPatientPersonalDetails, updateContacts, addContact } =
    usePersonalDetailStore();
  const { isBlurred } = useEnrollmentMainStore();
  const { handleSubmit, control, setValue, watch } = useForm<ContactDetailType>(
    {
      mode: "onChange",
    }
  );
  const [addCareTakers, setAddCareTakers] = useState<CareTakerType[]>([]);
  const [careTakerDetails, setCareTakerDetails] = useState<CareTakerType[]>([]);
  const [addCareTakerErrors, setAddCareTakerErrors] = useState({
    fullName: [""],
    mobileNumber: [""],
    whatsapp: [""],
    email: [""],
  });
  const [openDeactivateModal, setOpenDeactivateModal] =
    useState<boolean>(false);
  const [addContactForCaretaker, setAddContactForCaretaker] =
    useState<boolean>(false);
  const [addContactForPatient, setAddContactForPatient] =
    useState<boolean>(false);
  const [deactivateModalDetails, setDeactivateModalDetails] = useState<Contact>(
    {} as Contact
  );

  const filteredMobileNumber = selectedPatientPersonalDetails.contacts.filter(
    (mobile) => {
      return (
        mobile.self != false &&
        mobile.type === "mobile" &&
        mobile.status === "active"
      );
    }
  );
  const filteredWhatsappNumber = selectedPatientPersonalDetails.contacts.filter(
    (mobile) => {
      return (
        mobile.self != false &&
        mobile.type === "whatsapp" &&
        mobile.status === "active"
      );
    }
  );
  const filteredEmail = selectedPatientPersonalDetails.contacts.filter(
    (mobile) => {
      return (
        mobile.self != false &&
        mobile.type === "email" &&
        mobile.status === "active"
      );
    }
  );

  const handleFormatingCareTakerDetails = () => {
    const filteredCareTakerDetails =
      selectedPatientPersonalDetails.contacts.filter((mobile) => {
        return (
          mobile.self === false &&
          (mobile.type === "email" ||
            mobile.type === "whatsapp" ||
            mobile.type === "mobile") &&
          mobile.status === "active"
        );
      });
    const formattedData: FormattedData[] = filteredCareTakerDetails.reduce(
      (acc: FormattedData[], obj: any) => {
        const existingEntry = acc.find(
          (entry) => entry.fullName === obj.fullName
        );
        const contact: Contact = {
          isPrimary: obj.isPrimary,
          self: obj.self,
          status: obj.status,
          type: obj.type,
          value: obj.value,
          _id: obj._id,
        };

        if (existingEntry) {
          existingEntry.contacts.push(contact);
        } else {
          acc.push({
            createdAt: obj.createdAt,
            fullName: obj.fullName,
            contacts: [contact],
          });
        }
        return acc;
      },
      []
    );
    return formattedData;
  };

  const addCareTaker = () => {
    addCareTakers.push({
      fullName: "",
      mobileNumber: "",
      whatsapp: "",
      email: "",
    });
    setAddCareTakers([...addCareTakers]);
  };

  const cancelCareTaker = (index: number) => {
    addCareTakers.splice(index, 1);
    setAddCareTakers([...addCareTakers]);
  };

  const handleSaveCareTakersDisable = () => {
    const isfullNameEmpty = addCareTakers.some(
      (element) => element.fullName == ""
    );
    const isfullNameNotEmpty = addCareTakerErrors.fullName.some(
      (element) => element !== ""
    );
    const isMobileNotEmpty = addCareTakerErrors.mobileNumber.some(
      (element) => element !== ""
    );
    const isEmailNotEmpty = addCareTakerErrors.email.some(
      (element) => element !== ""
    );
    const isWhatsappNotEmpty = addCareTakerErrors.whatsapp.some(
      (element) => element !== ""
    );
    return (
      isfullNameEmpty ||
      isfullNameNotEmpty ||
      isMobileNotEmpty ||
      isEmailNotEmpty ||
      isWhatsappNotEmpty
    );
  };

  const handleChangeInput = (index: number, name: string, value: string) => {
    if (name == "fullName") {
      addCareTakerErrors.fullName[index] = "";
    } else if (name == "mobileNumber") {
      if (!Constant.CORRECT_MOBILE.VALUE.test(value) && value != "") {
        addCareTakerErrors.mobileNumber[index] = "Enter valid mobile number";
      } else {
        addCareTakerErrors.mobileNumber[index] = "";
      }
    } else if (name == "whatsapp") {
      if (!Constant.CORRECT_MOBILE.VALUE.test(value) && value != "") {
        addCareTakerErrors.whatsapp[index] = "Enter valid mobile number";
      } else {
        addCareTakerErrors.whatsapp[index] = "";
      }
    } else if (name == "email") {
      if (!Constant.CORRECT_EMAIL.VALUE.test(value) && value != "") {
        addCareTakerErrors.email[index] = "Enter valid email id";
      } else {
        addCareTakerErrors.email[index] = "";
      }
    }

    const values = [...addCareTakers];

    values[index][name] = value;

    setAddCareTakers(values);
  };

  const handleSaveCareTakers = () => {
    const convertedContacts = addCareTakers
      .map((contact) => {
        const contactDetails = [];

        if (contact.mobileNumber !== "") {
          contactDetails.push({
            type: "mobile",
            value: contact.mobileNumber,
            fullName: contact.fullName,
            self: false,
          });
        }
        if (contact.whatsapp !== "") {
          contactDetails.push({
            type: "whatsapp",
            value: contact.whatsapp,
            fullName: contact.fullName,
            self: false,
          });
        }
        if (contact.email !== "") {
          contactDetails.push({
            type: "email",
            value: contact.email,
            fullName: contact.fullName,
            self: false,
          });
        }

        return contactDetails;
      })
      .flat();
    if (convertedContacts.length > 0) {
      let payload = {
        contacts: convertedContacts,
      };
      addContact(selectedPatientPersonalDetails._id, payload);
    } else {
      Swal.fire("Contact value is mandatory", "", "error");
    }
  };

  useEffect(() => {
    for (let i = 0; i <= careTakerDetails.length; i++) {
      careTakerDetails.pop();
    }
  }, [selectedPatientPersonalDetails._id]);

  const addContactForExisitingCareTaker = (value: boolean) => {
    setAddContactForPatient(false);
    setAddContactForCaretaker(value);
  };
  const handleAddContactForPatient = (value: boolean) => {
    setAddContactForCaretaker(false);
    setAddContactForPatient(value);
  };
  const handleDetactivateContact = (data: Contact) => {
    let payload = {
      status: "in_active",
    };
    updateContacts(
      selectedPatientPersonalDetails._id,
      data._id,
      payload,
      handleCloseDeactivateModal
    );
  };

  const handleOpenDeactivateModal = (contact: Contact) => {
    setDeactivateModalDetails(contact);
    setOpenDeactivateModal(true);
  };
  const handleCloseDeactivateModal = (contact: Contact) => {
    setDeactivateModalDetails({} as Contact);
    setOpenDeactivateModal(false);
  };
  const handleAddContactToExistingCaretaker = (data: ContactDetailType) => {
    let payload = {
      contacts: [
        {
          type: data.contactType,
          value: data.contact,
          self: false,
          fullName: data.fullName,
        },
      ],
    };
    addContact(selectedPatientPersonalDetails._id, payload);
  };
  const handleAddContactToPatient = (data: ContactDetailType) => {
    let payload = {
      contacts: [
        {
          type: data.contactType,
          value: data.contact,
          isPrimary: data.isPrimary ? data.isPrimary : false,
        },
      ],
    };
    addContact(selectedPatientPersonalDetails._id, payload);
  };

  return (
    <>
      <Card variant="outlined">
        <CardContent>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography variant="caption" fontWeight={660}>
              Patient Contact Details
            </Typography>
            {!isBlurred && (
              <AddCircleIcon
                color="secondary"
                sx={{ fontSize: "15px" }}
                onClick={() => {
                  setValue("fullName", "");
                  handleAddContactForPatient(true);
                }}
              />
            )}
          </Stack>
          {addContactForPatient && (
            <>
              <Stack
                spacing={1}
                direction={"row"}
                alignItems={"center"}
                marginTop={1}
              >
                <Controller
                  name="contactType"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormControl fullWidth size="small">
                      <InputLabel>Contact Type</InputLabel>
                      <Select
                        label="Contact Type"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                          setValue("contact", "");
                        }}
                        error={error ? true : false}
                      >
                        <MenuItem value={"mobile"}>Mobile</MenuItem>
                        <MenuItem value={"whatsapp"}>Whatsapp</MenuItem>
                        <MenuItem value={"email"}>Email</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: Constant.FIELD_REQUIRED,
                    },
                  }}
                />
                <Controller
                  name="contact"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      size="small"
                      fullWidth
                      disabled={!watch("contactType")}
                      value={value}
                      onChange={onChange}
                      error={error ? true : false}
                      helperText={error?.message}
                      InputProps={{
                        endAdornment: value && (
                          <Controller
                            name="isPrimary"
                            control={control}
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <FormControlLabel
                                control={
                                  <Checkbox value={value} onChange={onChange} />
                                }
                                label={
                                  <Typography
                                    variant="caption"
                                    fontWeight={660}
                                  >
                                    Primary
                                  </Typography>
                                }
                              />
                            )}
                          />
                        ),
                      }}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: Constant.FIELD_REQUIRED,
                    },
                    pattern: {
                      value:
                        watch("contactType") === "email"
                          ? Constant.CORRECT_EMAIL.VALUE
                          : Constant.CORRECT_MOBILE.VALUE,
                      message:
                        watch("contactType") === "email"
                          ? Constant.CORRECT_EMAIL.MESSAGE
                          : Constant.CORRECT_MOBILE.MESSAGE,
                    },
                  }}
                />
              </Stack>
              <Stack
                direction={"row"}
                spacing={1}
                justifyContent={"end"}
                alignItems={"center"}
                marginTop={1}
              >
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={() => handleAddContactForPatient(false)}
                >
                  <Typography textTransform={"none"} fontSize={"12px"}>
                    Close
                  </Typography>
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={handleSubmit(handleAddContactToPatient)}
                >
                  <Typography textTransform={"none"} fontSize={"12px"}>
                    Submit
                  </Typography>
                </Button>
              </Stack>
            </>
          )}
          <TableContainer>
            <Table size="small">
              {filteredMobileNumber.length > 0 &&
                filteredMobileNumber.map((contact, index) => {
                  return (
                    <TableRow sx={{ border: 0 }} key={index}>
                      <TableCell sx={{ border: 0, alignItems: "center" }}>
                        <Stack
                          direction={"row"}
                          spacing={0.2}
                          alignItems={"center"}
                        >
                          <Typography>Mobile </Typography>
                          {!isBlurred && (
                            <DeleteIcon
                              color="error"
                              sx={{ fontSize: "15px" }}
                              onClick={() => {
                                handleOpenDeactivateModal(contact);
                              }}
                            />
                          )}

                          <Typography>:</Typography>
                        </Stack>
                      </TableCell>

                      <TableCell sx={{ border: 0 }}>
                        {" "}
                        <Stack
                          direction={"row"}
                          spacing={0.2}
                          alignItems={"center"}
                        >
                          <Typography
                            variant="subtitle2"
                            className={isBlurred ? "blurred-text" : ""}
                          >
                            {contact.value}
                          </Typography>
                          {contact.isPrimary == true && !isBlurred && (
                            <StarsIcon
                              sx={{ fontSize: "15px" }}
                              color="success"
                            />
                          )}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {filteredWhatsappNumber.length > 0 &&
                filteredWhatsappNumber.map((contact, index) => {
                  return (
                    <TableRow sx={{ border: 0 }} key={index}>
                      <TableCell sx={{ border: 0, alignItems: "center" }}>
                        <Stack
                          direction={"row"}
                          spacing={0.2}
                          alignItems={"center"}
                        >
                          <Typography>Whatsapp</Typography>
                          {!isBlurred && (
                            <DeleteIcon
                              color="error"
                              sx={{ fontSize: "15px" }}
                              onClick={() => {
                                handleOpenDeactivateModal(contact);
                              }}
                            />
                          )}
                          <Typography>:</Typography>
                        </Stack>
                      </TableCell>

                      <TableCell sx={{ border: 0 }}>
                        <Stack
                          direction={"row"}
                          spacing={0.2}
                          alignItems={"center"}
                        >
                          <Typography
                            variant="subtitle2"
                            className={isBlurred ? "blurred-text" : ""}
                          >
                            {contact.value}
                          </Typography>
                          {contact.isPrimary == true && !isBlurred && (
                            <StarsIcon
                              sx={{ fontSize: "15px" }}
                              color="success"
                            />
                          )}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}

              {filteredEmail.length > 0 &&
                filteredEmail.map((contact, index) => {
                  return (
                    <TableRow sx={{ border: 0 }} key={index}>
                      <TableCell sx={{ border: 0, alignItems: "center" }}>
                        <Stack
                          direction={"row"}
                          spacing={0.2}
                          alignItems={"center"}
                        >
                          <Typography>Email </Typography>
                          {!isBlurred && (
                            <DeleteIcon
                              color="error"
                              sx={{ fontSize: "15px" }}
                              onClick={() => {
                                handleOpenDeactivateModal(contact);
                              }}
                            />
                          )}
                          <Typography>:</Typography>
                        </Stack>
                      </TableCell>

                      <TableCell sx={{ border: 0 }}>
                        <Stack
                          direction={"row"}
                          spacing={0.2}
                          alignItems={"center"}
                        >
                          <Typography
                            variant="subtitle2"
                            className={isBlurred ? "blurred-text" : ""}
                          >
                            {contact.value}
                          </Typography>
                          {contact.isPrimary == true && !isBlurred && (
                            <StarsIcon
                              sx={{ fontSize: "15px" }}
                              color="success"
                            />
                          )}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </Table>
          </TableContainer>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing={1}
              marginY={2}
            >
              <Typography variant="subtitle2" fontWeight={660}>
                CareTaker Details
              </Typography>
              {!isBlurred && (
                <AddCircleIcon
                  color="secondary"
                  sx={{ fontSize: "15px" }}
                  onClick={addCareTaker}
                />
              )}
            </Stack>
            {addCareTakers.length > 0 && !isBlurred && (
              <Button
                disabled={handleSaveCareTakersDisable()}
                variant="contained"
                size="small"
                color="secondary"
                onClick={() => handleSaveCareTakers()}
              >
                <Typography textTransform={"none"} variant="caption">
                  Save CareTaker Details
                </Typography>
              </Button>
            )}
          </Stack>

          {addCareTakers.length > 0 &&
            addCareTakers.map((data, index) => {
              return (
                <>
                  <Stack
                    direction={"row"}
                    spacing={4}
                    alignItems={"center"}
                    marginBottom={1}
                  >
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <Typography variant="caption">Name:</Typography>
                      <TextField
                        name="fullName"
                        size="small"
                        variant="outlined"
                        placeholder="Enter name"
                        value={data.fullName}
                        fullWidth
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          handleChangeInput(
                            index,
                            event.target.name,
                            event.target.value
                          )
                        }
                        sx={{
                          borderRadius: "3.5px",
                        }}
                        error={
                          addCareTakerErrors.fullName[index] ? true : false
                        }
                        helperText={addCareTakerErrors.fullName[index]}
                      />
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                      <Typography variant="caption">Mobile:</Typography>
                      <TextField
                        name="mobileNumber"
                        size="small"
                        placeholder="Enter mobile number"
                        value={data.mobileNumber}
                        fullWidth
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          handleChangeInput(
                            index,
                            event.target.name,
                            event.target.value
                          )
                        }
                        error={
                          addCareTakerErrors.mobileNumber[index] ? true : false
                        }
                        helperText={addCareTakerErrors.mobileNumber[index]}
                      />
                    </Stack>
                  </Stack>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    alignItems={"center"}
                    marginBottom={1}
                  >
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                      <Typography variant="caption">Email:</Typography>
                      <TextField
                        name="email"
                        size="small"
                        variant="outlined"
                        placeholder="Enter email Id"
                        value={data.email}
                        fullWidth
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          handleChangeInput(
                            index,
                            event.target.name,
                            event.target.value
                          )
                        }
                        sx={{
                          borderRadius: "3.5px",
                        }}
                        error={addCareTakerErrors.email[index] ? true : false}
                        helperText={addCareTakerErrors.email[index]}
                      />
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                      <Typography variant="caption">Whatsapp:</Typography>
                      <TextField
                        name="whatsapp"
                        size="small"
                        placeholder="Enter whatsapp number"
                        value={data.whatsapp}
                        fullWidth
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          handleChangeInput(
                            index,
                            event.target.name,
                            event.target.value
                          )
                        }
                        error={
                          addCareTakerErrors.whatsapp[index] ? true : false
                        }
                        helperText={addCareTakerErrors.whatsapp[index]}
                      />
                    </Stack>
                    <CancelIcon
                      fontSize="small"
                      color="error"
                      onClick={() => cancelCareTaker(index)}
                    />
                  </Stack>
                </>
              );
            })}
          <TableContainer>
            {handleFormatingCareTakerDetails().length > 0 &&
              handleFormatingCareTakerDetails().map((data, index) => {
                return (
                  <>
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                      <Typography variant="caption" fontWeight={660}>
                        CareTaker: {index + 1}
                      </Typography>
                      {!isBlurred && (
                        <AddCircleIcon
                          color="secondary"
                          sx={{ fontSize: "15px" }}
                          onClick={() => {
                            setValue("fullName", data.fullName);
                            addContactForExisitingCareTaker(true);
                          }}
                        />
                      )}
                    </Stack>
                    {addContactForCaretaker &&
                      data.fullName == watch("fullName") && (
                        <>
                          <Stack
                            spacing={1}
                            direction={"row"}
                            alignItems={"center"}
                            marginTop={1}
                          >
                            <Controller
                              name="fullName"
                              control={control}
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <TextField
                                  size="small"
                                  fullWidth
                                  value={value}
                                  disabled
                                  InputProps={{ readOnly: true }}
                                />
                              )}
                            />
                            <Controller
                              name="contactType"
                              control={control}
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <FormControl fullWidth size="small">
                                  <InputLabel>Contact Type</InputLabel>
                                  <Select
                                    label="Contact Type"
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e.target.value);
                                      setValue("contact", "");
                                    }}
                                    error={error ? true : false}
                                  >
                                    <MenuItem value={"mobile"}>Mobile</MenuItem>
                                    <MenuItem value={"whatsapp"}>
                                      Whatsapp
                                    </MenuItem>
                                    <MenuItem value={"email"}>Email</MenuItem>
                                  </Select>
                                </FormControl>
                              )}
                              rules={{
                                required: {
                                  value: true,
                                  message: Constant.FIELD_REQUIRED,
                                },
                              }}
                            />
                            <Controller
                              name="contact"
                              disabled={!watch("contactType")}
                              control={control}
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <TextField
                                  size="small"
                                  fullWidth
                                  value={value}
                                  onChange={onChange}
                                  error={error ? true : false}
                                  helperText={error?.message}
                                />
                              )}
                              rules={{
                                required: {
                                  value: true,
                                  message: Constant.FIELD_REQUIRED,
                                },
                                pattern: {
                                  value:
                                    watch("contactType") === "email"
                                      ? Constant.CORRECT_EMAIL.VALUE
                                      : Constant.CORRECT_MOBILE.VALUE,
                                  message:
                                    watch("contactType") === "email"
                                      ? Constant.CORRECT_EMAIL.MESSAGE
                                      : Constant.CORRECT_MOBILE.MESSAGE,
                                },
                              }}
                            />
                          </Stack>
                          <Stack
                            spacing={1}
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"end"}
                            marginTop={1}
                          >
                            <Button
                              variant="contained"
                              color="error"
                              size="small"
                              onClick={() =>
                                addContactForExisitingCareTaker(false)
                              }
                            >
                              <Typography
                                textTransform={"none"}
                                fontSize={"12px"}
                              >
                                Close
                              </Typography>
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              size="small"
                              onClick={handleSubmit(
                                handleAddContactToExistingCaretaker
                              )}
                            >
                              <Typography
                                textTransform={"none"}
                                fontSize={"12px"}
                              >
                                Submit
                              </Typography>
                            </Button>
                          </Stack>
                        </>
                      )}

                    <Table size="small">
                      <TableRow sx={{ border: 0 }}>
                        <TableCell sx={{ border: 0, width: "25%" }}>
                          <Stack
                            direction={"row"}
                            spacing={0.5}
                            alignItems={"center"}
                          >
                            <Typography>Full Name:</Typography>
                          </Stack>
                        </TableCell>
                        <TableCell
                          sx={{ border: 0 }}
                          className={isBlurred ? "blurred-text" : ""}
                        >
                          {data.fullName}
                        </TableCell>
                      </TableRow>
                      {data.contacts.length > 0 &&
                        !isBlurred &&
                        data.contacts.map((contact, index) => {
                          return (
                            <TableRow sx={{ border: 0 }} key={index}>
                              <TableCell sx={{ border: 0, width: "25%" }}>
                                <Stack
                                  direction={"row"}
                                  spacing={0.5}
                                  alignItems={"center"}
                                >
                                  <Typography
                                    className={isBlurred ? "blurred-text" : ""}
                                  >
                                    {toTitleCase(contact.type)}
                                  </Typography>
                                  {contact.value ? (
                                    <DeleteIcon
                                      color="error"
                                      sx={{ fontSize: "15px" }}
                                      onClick={() =>
                                        handleOpenDeactivateModal(contact)
                                      }
                                    />
                                  ) : (
                                    <AddCircleIcon
                                      color="secondary"
                                      sx={{ fontSize: "15px" }}
                                      // onClick={() => {
                                      //   setValue("mobile", "");
                                      //   setEditableValue("careTakerMobile");

                                      //   handleEditPersonalDetails(
                                      //     EditableTypes.CONTACTS
                                      //   );
                                      // }}
                                    />
                                  )}
                                  <Typography>:</Typography>
                                </Stack>
                              </TableCell>
                              <TableCell
                                sx={{ border: 0 }}
                                className={isBlurred ? "blurred-text" : ""}
                              >
                                {contact.value}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </Table>
                  </>
                );
              })}
          </TableContainer>
        </CardContent>
      </Card>
      <DeactivateConfirmModal
        open={openDeactivateModal}
        handleClose={handleCloseDeactivateModal}
        data={deactivateModalDetails}
        successCallback={handleDetactivateContact}
      />
    </>
  );
};

export default ContactDetails;
