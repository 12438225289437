import { StateCreator } from "zustand";
import {
  COMMON_AUTH_HEADER,
  ORDER_SERVICE,
  ORDER_SERVICE_TOKEN,
  PSP_AUTH_TOKEN_KEY,
} from "../../../../base";
import { RemoteCreateOrder } from "../../../../data/usecases/remote-create-order";
import Endpoints from "../../../../domain/endpoints";
import { AxiosHttpClient } from "../../../../infra/http/axios-http-client";
import Swal from "sweetalert2";
import { LocalJsonStorage } from "../../../../infra/http/local-json-storage";
import { CreateOrder } from "../../../../domain/usages/create-order";
import { usePersonalDetailStore } from "../personalDetailStore.ts/personalDetailsStore";

interface CreateOrderSliceType {
  makeOrder: Function;
  createOrderLoading: boolean;
  handleCreateOrderLoading: Function;
}

const initialStates = {
  createOrderLoading: false,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useCreateOrderSlice: StateCreator<CreateOrderSliceType> = (
  set,
  get
) => ({
  ...initialStates,
  handleCreateOrderLoading: (value: boolean) => {
    if (value) {
      Swal.fire({
        didOpen: () => {
          Swal.showLoading();
        },
        title:
          "Please wait... Your order is being created. This might take a few moments. Thank you for your patience! 😊",
        timerProgressBar: true,
      });
    }
  },
  makeOrder: async (payload: CreateOrder.Params) => {
    axiosHttpClient.setAuthHeaders({
      [COMMON_AUTH_HEADER]: `${ORDER_SERVICE_TOKEN}`,
    });

    const remoteCreateOrder: CreateOrder = new RemoteCreateOrder(
      `${ORDER_SERVICE}${Endpoints.CREATE_ORDER}`,
      axiosHttpClient
    );
    get().handleCreateOrderLoading(true);
    let result = await remoteCreateOrder.create(payload);
    if (result.success) {
      get().handleCreateOrderLoading(false);
      Swal.fire({
        title: "Order Created Successfully",
        icon: "success",
        timer: 3000,
      });
      usePersonalDetailStore.getState().reloadPersonalDetails();
    } else {
      get().handleCreateOrderLoading(false);
      if (result.message) {
        Swal.fire(result.message, "", "error");
      } else {
        Swal.fire("Order Creation Failed", "", "error");
      }
    }
  },
});
