import {
  Dialog,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import GaButton from "../components/GaButton";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useEffect, useState } from "react";
import { useAppointmentDetailsStore } from "../../store/adminPageStore/patientDetailsStore/appointmentDetailsStore/appointmentDetailsStore";
import moment from "moment";
import { Constant } from "../../constants/constant";
import { AppointmentStatus } from "../../constants/enums/appointmentStatus";
import ImageCard from "../components/ImageCard";
import UploadPrecriptionModal from "../components/doctor-view/upload-prescription-modal";
import { useParams } from "react-router-dom";
import { FetchState } from "../../domain/models/fetch-state-type";
import Swal from "sweetalert2";
import { DoctorViewLoading } from "../components/doctor-view/doctor-view-loading";
import useRecaptcha from "../hooks/reCaptcha";
import { GOOGLE_RECAPTCHE_SITE_KEY } from "../../base";

export const MobileAppointmentModulePage = () => {
  const { appointment_id } = useParams();

  const [openDeclineDrawer, setOpenDeclineDrawer] = useState(false);
  const [openFinshAppointmentModal, setOpenFinishAppointmentModal] =
    useState(false);
  const [declineReason, setDeclineReason] = useState("");

  const {
    cancelAppointment,
    confirmAppointment,
    fetchAppointmentsById,
    fetchAppointmentsByIdLoading,
    appointmentDetails,
  } = useAppointmentDetailsStore();

  const handleRedirect = () => {
    window.location.href = appointmentDetails.video_call_link;
  };

  const {
    captchaToken: fetchAppointmentsByIdToken,
    executeRecaptcha: executeRecaptchaFetchAppointmentsById,
    isCaptchaLoading,
  } = useRecaptcha(GOOGLE_RECAPTCHE_SITE_KEY, "fetchAppointmentsById");

  const {
    captchaToken: confirmAppointmentToken,
    executeRecaptcha: executeRecaptchaConfirmAppointment,
    isCaptchaLoading: confirmAppointmentLoading,
  } = useRecaptcha(GOOGLE_RECAPTCHE_SITE_KEY, "confirmAppointment");

  const {
    captchaToken: declineAppointmentToken,
    executeRecaptcha: executeRecaptchaDeclineAppointment,
    isCaptchaLoading: declineAppointmentLoading,
  } = useRecaptcha(GOOGLE_RECAPTCHE_SITE_KEY, "declineAppointment");

  useEffect(() => {
    executeRecaptchaFetchAppointmentsById();
  }, []);

  useEffect(() => {
    if (fetchAppointmentsByIdToken) {
      fetchAppointmentsById(appointment_id, fetchAppointmentsByIdToken);
    }
  }, [fetchAppointmentsByIdToken]);

  useEffect(() => {
    if (confirmAppointmentToken) {
      confirmAppointment(appointmentDetails.id, confirmAppointmentToken);
    }
  }, [confirmAppointmentToken]);

  useEffect(() => {
    if (declineAppointmentToken) {
      cancelAppointment(
        declineReason,
        appointmentDetails.id,
        declineAppointmentToken
      );
    }
  }, [declineAppointmentToken]);
  return (
    <Stack
      direction={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      bgcolor={"#F8F8F8"}
      height={"100vh"}
      p={1}
    >
      {fetchAppointmentsByIdLoading === FetchState.LOADING &&
        isCaptchaLoading && <DoctorViewLoading />}
      {fetchAppointmentsByIdLoading === FetchState.SUCCESS &&
        !isCaptchaLoading && (
          <Stack
            bgcolor={"#FAFAFC"}
            direction={"column"}
            borderRadius={"16px"}
            width={"100%"}
          >
            <Stack direction={"column"} p={2}>
              <Typography fontWeight={"600"}>
                {appointmentDetails.requester.name}
              </Typography>
              <Typography>{appointmentDetails.requester.mobile}</Typography>
              <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={1}
                color={
                  appointmentDetails.status === AppointmentStatus.Canceled
                    ? "red"
                    : "black"
                }
              >
                <AccessTimeIcon />
                <Typography fontWeight={"600"} fontSize={14}>
                  {moment(appointmentDetails.date).format(
                    Constant.MONTH_DATE_YEAR_FORMAT
                  ) +
                    " : " +
                    moment(
                      appointmentDetails.start_time,
                      Constant.HOUR_MIN_SEC_FORMAT
                    ).format(Constant.HOUR_MIN_FORMAT)}{" "}
                  {`(${appointmentDetails.status})`}
                </Typography>
              </Stack>

              <Typography
                color={"#AAB6C3"}
                fontFamily={"Lato"}
                fontStyle={"italic"}
                mt={1}
              >
                “{appointmentDetails.description}”
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              flexWrap={"wrap"}
              spacing={1}
              mb={1}
              pl={2}
            >
              {appointmentDetails.documents.map((fileItem) => (
                <ImageCard
                  key={fileItem.id}
                  uuid={fileItem.uuid}
                  title={fileItem.title}
                />
              ))}
            </Stack>
            {(appointmentDetails.status === AppointmentStatus.Booked ||
              appointmentDetails.status === AppointmentStatus.Confirmed) && (
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-around"}
                spacing={1}
                p={2}
              >
                {appointmentDetails.status === AppointmentStatus.Booked ? (
                  <GaButton
                    text={`Accept (${appointmentDetails.type})`}
                    onClick={() => {
                      executeRecaptchaConfirmAppointment();
                    }}
                    buttonBackgroundColor="#4DB8C8"
                    buttonSx={{
                      borderRadius: "32px",
                      width: "50%",
                      padding: "10px",
                    }}
                  />
                ) : (
                  <GaButton
                    text="Link to Google meet"
                    onClick={handleRedirect}
                    buttonBackgroundColor="#4DB8C8"
                    buttonSx={{
                      borderRadius: "32px",
                      width: "70%",
                      padding: "10px",
                    }}
                  />
                )}
                <GaButton
                  text="Decline"
                  onClick={() => {
                    setOpenDeclineDrawer(true);
                  }}
                  buttonBackgroundColor="#ED5E5E"
                  buttonSx={{
                    borderRadius: "32px",
                    width:
                      appointmentDetails.status === AppointmentStatus.Booked
                        ? "50%"
                        : "30%",
                    padding: "10px",
                  }}
                />
              </Stack>
            )}
            {appointmentDetails.status === AppointmentStatus.Confirmed && (
              <Stack pl={2} pr={2}>
                <GaButton
                  text="Finish Appointment?"
                  onClick={() => {
                    setOpenFinishAppointmentModal(true);
                  }}
                  isDisabled={moment(
                    moment(appointmentDetails.date).format("MM-DD-YYYY")
                  ).isAfter(moment().format("MM-DD-YYYY"))}
                  buttonBackgroundColor="#1F2C2E"
                  buttonSx={{
                    borderRadius: "32px",
                    width: "100%",
                    padding: "10px",
                  }}
                />
              </Stack>
            )}
          </Stack>
        )}
      {openFinshAppointmentModal && (
        <UploadPrecriptionModal
          openUploadPrsecriptionModal={openFinshAppointmentModal}
          handleUploadPrescriptionModal={() =>
            setOpenFinishAppointmentModal(false)
          }
          appointmetId={appointmentDetails.id}
        />
      )}
      {openDeclineDrawer && (
        <Dialog
          open={openDeclineDrawer}
          onClose={() => setOpenDeclineDrawer(false)}
        >
          <Stack
            p={2}
            direction={"column"}
            alignItems={"center"}
            justifyContent={"space-around"}
          >
            <Typography fontWeight={"600"}>
              {`Decline Appointment (${appointmentDetails.requester.name})`}
            </Typography>
            <Divider />
            <Typography color={"red"} fontSize={"15px"} pt={1} pb={1}>
              {`Are you sure you want to decline the appointment for ${
                appointmentDetails.requester.name
              }
              on ${moment(appointmentDetails.date).format(
                Constant.MONTH_DATE_YEAR_FORMAT
              )} ?`}
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="cancellation-reason-label">
                Reason for Cancellation
              </InputLabel>
              <Select
                labelId="cancellation-reason-label"
                value={declineReason}
                onChange={(e) => setDeclineReason(e.target.value)}
                label="Reason for Cancellation"
              >
                {Constant.DOCTOR_APPOINTMENT_CANCELLATION_REASON.map(
                  (reasonObj) => (
                    <MenuItem key={reasonObj.id} value={reasonObj.reason}>
                      {reasonObj.reason}
                    </MenuItem>
                  )
                )}
              </Select>
              <FormHelperText>
                Please select a reason for cancelling the appointment
              </FormHelperText>
            </FormControl>
            <GaButton
              text="Decline"
              onClick={() => {
                executeRecaptchaDeclineAppointment();
                setOpenDeclineDrawer(false);
                Swal.fire({
                  title: "Cancelling Appointment...",
                  timerProgressBar: true,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                });
              }}
              buttonBackgroundColor="#ED5E5E"
              isDisabled={declineReason === ""}
              buttonSx={{
                borderRadius: "32px",
                width: "100%",
                padding: "10px",
                marginTop: "12px",
              }}
            />
          </Stack>
        </Dialog>
      )}
    </Stack>
  );
};
