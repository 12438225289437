import { StateCreator } from "zustand";
import { AUTH_HEADER, APPOINTMENT_SERVICE_STAGING_URL } from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { LocalLoggedInUser } from "../../../data/usecases/local-logged-in-user";
import { RemoteFetchAppointmentDocumentTypes } from "../../../data/usecases/appointment-module/remote-fetch-appointment-document-types";
import {
  AppointmentDocumentTypeResponse,
  DocumentTypeResponse,
} from "../../../domain/models/appointment-module/fetch-appointment-document-type";

interface FetchAppointmentDocumentTypesSliceInterface {
  fetchAppointmentDocumentTypes: Function;
  fetchAppointmentDocumentTypesLoading: FetchState;
  appointmentDocumentTypes: DocumentTypeResponse[];
}

const initialStates = {
  fetchAppointmentDocumentTypesLoading: FetchState.DEFAULT,
  appointmentDocumentTypes: [],
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useFetchAppointmentDocumentTypesSlice: StateCreator<
  FetchAppointmentDocumentTypesSliceInterface
> = (set, get) => ({
  ...initialStates,
  fetchAppointmentDocumentTypes: async () => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token });
    const remoteFetchAppointmentDocumentTypes =
      new RemoteFetchAppointmentDocumentTypes(
        `${APPOINTMENT_SERVICE_STAGING_URL}${Endpoints.APPOINTMENT_DOCUMENT_TYPE}`,
        axiosHttpClient
      );
    set(() => ({ fetchAppointmentDocumentTypesLoading: FetchState.LOADING }));
    let result: AppointmentDocumentTypeResponse =
      await remoteFetchAppointmentDocumentTypes.fetch();
    if (result.success) {
      set(() => ({
        fetchAppointmentDocumentTypesLoading: FetchState.SUCCESS,
        appointmentDocumentTypes: result.document_types,
      }));
    } else {
      set(() => ({ fetchAppointmentDocumentTypesLoading: FetchState.ERROR }));
    }
  },
});
