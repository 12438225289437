import { FetchAppointmentsResponse } from "../../../domain/models/appointment-module/fetch-appointment-response";
import { FetchAppointments } from "../../../domain/usages/appointment-module/fetch-appointment";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpGetClient } from "../../protocols/http/http-get-client";

export class RemoteFetchAppointments implements FetchAppointments {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async fetch(
    params: FetchAppointments.Params
  ): Promise<FetchAppointmentsResponse> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url,
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

    return httpResponse.data;
  }
}
