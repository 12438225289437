import { create } from "zustand";
import { useCreateNewFollowUpSlice } from "./createNewFollowUpsSlice";
import { useFetchDailyCountSlice } from "./fetchDailyCountSlice";
import { useFetchFollowUpThreadForEnrollmentIdSlice } from "./fetchFollowUpThreadsForEnrolmentIdSlice";
import { useFetchFollowUpForThreadSlice } from "./fetchFollowupsForThread";
import { useAddFollowUpForThreadSlice } from "./addFollowUpForThreadSlice";
import { useAddCommentsForFollowUpSlice } from "./addCommentsForFollowupSlice";
import { useCloseFollowUpThreadSlice } from "./closeFollowupThread";

interface FollowUpsStoreInterface
  extends ReturnType<typeof useCreateNewFollowUpSlice>,
    ReturnType<typeof useFetchDailyCountSlice>,
    ReturnType<typeof useFetchFollowUpThreadForEnrollmentIdSlice>,
    ReturnType<typeof useFetchFollowUpForThreadSlice>,
    ReturnType<typeof useAddFollowUpForThreadSlice>,
    ReturnType<typeof useAddCommentsForFollowUpSlice>,
    ReturnType<typeof useCloseFollowUpThreadSlice> {}

export const useFollowUpsStore = create<FollowUpsStoreInterface>()(
  (...followUp) => ({
    ...useCreateNewFollowUpSlice(...followUp),
    ...useFetchDailyCountSlice(...followUp),
    ...useFetchFollowUpThreadForEnrollmentIdSlice(...followUp),
    ...useFetchFollowUpForThreadSlice(...followUp),
    ...useAddFollowUpForThreadSlice(...followUp),
    ...useAddCommentsForFollowUpSlice(...followUp),
    ...useCloseFollowUpThreadSlice(...followUp),
  })
);
