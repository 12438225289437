import { StateCreator } from "zustand";
import { EnrollmentFilterType } from "../../../domain/models/enrollment-filter.type";
import { useOrderDetailsStore } from "./orderDetailsStore";

interface FilterOrderSliceType {
  handleAppliedFilter: Function;
  orderFilter: EnrollmentFilterType;
}

const initialStates = {
  orderFilter: {} as EnrollmentFilterType,
};

export const useFilterOrderSlice: StateCreator<FilterOrderSliceType> = (
  set
) => ({
  ...initialStates,
  handleAppliedFilter: (appliedFilter: EnrollmentFilterType) => {
    useOrderDetailsStore.getState().orderList.splice(0);
    set(() => ({ orderFilter: appliedFilter }));
    useOrderDetailsStore.getState().fetchOrders(1, appliedFilter);
  },
});
