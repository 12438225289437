import { AppointmentDocumentTypeResponse } from "../../../domain/models/appointment-module/fetch-appointment-document-type";
import { FetchAppointmentDocumentTypes } from "../../../domain/usages/appointment-module/fetch-document-types";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpGetClient } from "../../protocols/http/http-get-client";

export class RemoteFetchAppointmentDocumentTypes
  implements FetchAppointmentDocumentTypes
{
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async fetch(
    params?: FetchAppointmentDocumentTypes.Params
  ): Promise<AppointmentDocumentTypeResponse> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url,
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });

    return httpResponse.data;
  }
}
