import { Box, Button, Collapse, Typography } from "@mui/material";
import AppointmentCardHeader from "./AppointmentCardHeader";
import AppointmentCardStepper from "./AppointmentCardStepper";
import AppointmentCardDescription from "./AppointmentCardDescription";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import React, { useState } from "react";
import CreateAppointment from "./CreateAppointment";
import { AppointmentType } from "../../../../../domain/models/appointment-module/fetch-appointment-response";
import { useAppointmentDetailsStore } from "../../../../../store/adminPageStore/patientDetailsStore/appointmentDetailsStore/appointmentDetailsStore";
import { AppointmentStatus } from "../../../../../constants/enums/appointmentStatus";
import moment from "moment";
import { Constant } from "../../../../../constants/constant";

interface AppointmentCardProps {
  appointment: AppointmentType;
}

const activestep: any = {
  booked: 1,
  confirmed: 2,
  completed: 3,
};

const AppointmentCard: React.FC<AppointmentCardProps> = ({ appointment }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const { showAllAppointments } = useAppointmentDetailsStore();

  const onEditClick = (editValue: boolean) => {
    setEditMode(editValue);
  };

  const capitalizeWords = (name: string) => {
    return name
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return editMode ? (
    <CreateAppointment
      saveAppointment={onEditClick}
      appointment={appointment}
    />
  ) : (
    <Box
      border={
        appointment.status === "upcomming"
          ? "2px solid #A2BCF6"
          : "2px solid lightgrey"
      }
      borderRadius={"20px"}
      width={"99%"}
      mb={1}
      bgcolor={appointment.status === "upcomming" ? "#FAFCFF" : "white"}
    >
      <AppointmentCardHeader
        name={
          showAllAppointments
            ? capitalizeWords(appointment.appointee.name) +
              " < > Patient " +
              capitalizeWords(appointment.requester.name)
            : capitalizeWords(appointment.appointee.name)
        }
        id={String(appointment.id)}
        date={
          appointment.date +
          " : " +
          moment(appointment.start_time, Constant.HOUR_MIN_SEC_FORMAT).format(
            Constant.HOUR_MIN_FORMAT
          )
        }
      />
      <AppointmentCardStepper
        activeStep={activestep[appointment.status]}
        declined={
          appointment.status === AppointmentStatus.Canceled ||
          appointment.status === AppointmentStatus.Rescheduled
        }
      />

      <Button
        onClick={() => setIsExpanded(!isExpanded)}
        sx={{
          width: "100%",
          textAlign: "left",
          padding: "5px",
        }}
      >
        <Typography
          sx={{
            textTransform: "none",
            fontSize: "14px",
          }}
        >
          {isExpanded ? "Collapse" : "Expand"}
        </Typography>
        {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>

      <Collapse in={isExpanded}>
        <AppointmentCardDescription
          appointment={appointment}
          onEditClick={onEditClick}
        />
      </Collapse>
    </Box>
  );
};

export default AppointmentCard;
