import CryptoJS from "crypto-js";
export const toTitleCase = (str: string): string => {
  if(str){
    return str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
      return match.toUpperCase();
    });
  }
  return ""
  
};

export const getDomain = (): string => {
  return window.location.hostname;
};

export const encryptValue = (value: string, key: string) => {
  const iv=CryptoJS.lib.WordArray.random(16);
  const cryptoKey = CryptoJS.enc.Utf8.parse(key);
  const encrypted = CryptoJS.AES.encrypt(value, cryptoKey, {
    iv:iv ,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })
  const combined=iv.concat(encrypted.ciphertext);
  return CryptoJS.enc.Base64.stringify(combined);
};

export const getFieldValidation = (
  configData: string | null,
  fieldName: string
) => {
if(configData!="null" && configData!=null){

  const data = JSON.parse(configData) as {
    name: string;
    title: string;
    required: boolean;
    visibility: boolean;
  }[];
  return data.find((field) => field.name === fieldName) || null;
}
return null
  
};

export const filterAddress = (
  line: string | null,
  landmark: string | null,
  area: string | null,
  city: string | null,
  district: string | null,
  state: string | null,
  pincode: string | null,
  country: string | null
) => {
  const addressParts: (string | number | null)[] = [
    line,
    landmark,
    area,
    city,
    district,
    state,
    pincode,
    country,
  ].filter((part) => part !== null && part !== "");

  return addressParts.join(", ");
};
