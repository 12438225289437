import React, { useEffect } from "react";
import {
  Typography,
  Chip,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Pagination,
  Stack,
} from "@mui/material";
import { DemoData } from "../../../../domain/models/demo-request";
import { Divider } from "@material-ui/core";
import { useDemoRequestStore } from "../../../../store/stockistPageStore/demoRequestStore/demoRequestStore";
const DemoRequestDetails: React.FC = () => {
  const { getDemoRequest, demoRequestData, presentPage, totalPage } =
    useDemoRequestStore();

  useEffect(() => {
    getDemoRequest(1);
  }, []);

  const tableHeader = [
    "ID#",
    "Patient Name",
    "Patient Mobile Number",
    "Demo Person Name",
    "Demo Person Mobile Number",
    "Status",
  ];

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    getDemoRequest(page);
  };

  return (
    <>
      <Stack alignItems={"end"} mb={1}>
        <Pagination
          variant="outlined"
          color="secondary"
          page={presentPage}
          onChange={handlePageChange}
          count={totalPage}
        />
      </Stack>
      <Box padding={0.5} border={"1px solid lightgrey"} borderRadius={"12px"}>
        <TableContainer
          sx={{ maxHeight: "70vh", boxShadow: "none" }}
          component={Paper}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {tableHeader.map((row: string, index: number) => (
                  <TableCell key={index}>
                    <Typography color={"text.secondary"} variant="caption">
                      {row}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {demoRequestData?.map((data: DemoData, index: number) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    <Typography variant="subtitle2" fontWeight={550}>
                      {data?.enrolment?.id}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" fontWeight={550}>
                      {data?.enrolment?.patient_full_name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" fontWeight={550}>
                      {data?.enrolment.mobile}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" fontWeight={550}>
                      {data?.assisted_by}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" fontWeight={550}>
                      {data?.assisted_by_mobile}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Chip
                      variant="outlined"
                      color="secondary"
                      label={data?.status}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
export default DemoRequestDetails;
