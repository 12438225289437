const Endpoints = {
  //admin screen
  GET_ENROLMENT_DETAILS: "/v1/enrolments/:id",
  FETCH_PROGRAM_DOCS: "/v1/program-documents/:id",
  FETCH_ENROLLMENTS: "/v1/enrolments?page=:pageNumber",
  GET_PATIENT_DETAILS: "/v1/patients/:id",
  GET_PROGRAM_DOCUMENT: "/v1/documents/:id",
  UPDATE_BASIC_PATIENT_DETAILS: "/v1/patients/:id",
  ADD_PATIENT_CONTACT_DETAILS: "/v1/patients/:patientId/contacts",
  ADD_PATIENT_ADDRESS_DETAILS: "/v1/patients/:patientId/addresses",
  UPDATE_PATIENT_CONTACT_DETAILS: "/v1/patients/:patientId/contact/:contactId",
  UPDATE_PATIENT_ADDRESS_DETAILS: "/v1/patients/:patientId/address/:addressId",
  GET_LOCATION: "/locations/pincodes/:pincode",
  UPDATE_PROGRAM_DETAILS: "/v1/enrolments/:id",
  CREATE_ENROLLMENT: "/v1/enrolments",
  FETCH_PROGRAMS: "/v1/programs",
  FETCH_PRODUCTS: "/v1/products",

  //image upload
  CONFIRM_DOCUMENT_UPLOAD: "/v1/documents/:id/confirm",
  GENERATE_SIGNED_URL_FOR_DOCUMENT_UPLOAD: "/v1/documents",

  //create order
  CREATE_ORDER: "/v1/orders",
  CHANGE_ORDER_STATUS: "/v1/orders/status",
  GET_ORDER_DETAILS: "/v1/orders/:id",
  FETCH_SUPPLIER_LIST: "/v1/suppliers",
  FETCH_ORDERS: "/v1/orders/list/for-customer",
  FETCH_SUBORDER_DETAILS: "/v1/orders/:orderId/sub-orders/:subOrderId",
  FETCH_PROGRAM_PRODUCTS: "/v1/products/groups/id/:groupId?limit=10",

  //followup related endpoints
  FETCH_FOLLOW_UP_THREAD_FOR_ENROLMENT_ID: "/v1/follow-up-threads",
  CREATE_FOLLOW_UP_THREAD: "/v1/follow-up-threads",
  FETCH_FOLLOW_UPS_FOR_MONTH: "/v1/follow-up-threads/daily-aggregates",
  ADD_FOLLOW_UP_FOR_THREAD: "/v1/follow-up-threads/:threadId/follow-ups",
  FETCH_FOLLOW_UPS_FOR_THREAD: "/v1/follow-up-threads/:threadId/follow-ups",
  ADD_COMMENTS_FOR_FOLLOW_UP:
    "/v1/follow-up-threads/:threadId/follow-ups/:followupId/comments",
  CLOSE_FOLLOW_UP_THREAD: "/v1/follow-up-threads/:threadId/close",

  //stockist screen related endpoints
  GET_DEMO_REQUEST: "/v1/demo-requests?page=:pageNumber",
  GET_ORDERS: "/v1/orders?page=:pageNumber",
  UPDATE_ORDER_DETAILS: "/v1/orders/:id",
  DISPATCH_ORDER: "/v1/orders/:id/dispatch",
  ACCEPT_ORDER: "/v1/orders/:id/accept",
  DELIVER_ORDER: "/v1/orders/:id/delivered",
  CREATE_DEMO_REQUEST: "/v1/demo-requests",
  GET_VENDOR: "/v1/vendors",
  PACK_ORDER: "/v1/orders/:id/packing",
  GENERATE_ADDRESSLABEL: "/pdfs/address_label",
  GENERATE_DELIVERY_LABEL: "/pdfs/delivery_challan",
  GET_ACTIVE_ADDRESS: "/v1/addresses/active",
  FETCH_ORDER_BY_ID: "/v1/orders/forPatient/:id",

  //login
  USER_LOGIN: "/v1/auth/login",
  GENERATE_USER_TOKEN: "/v1/patient-services/generate-auth-token",

  //consent related endpoints
  GET_ENROLMENT_BY_CONSENT_TOKEN_URL: "/v1/enrolments/:token/consent",
  ACCEPT_CONSENT_URL: "/v1/enrolments/:token/consent",

  //meta data

  UPDATE_TAC_DATA: "/v1/enrolments/:id/meta-data",

  SEND_CONSENT_SMS: "/v1/enrolments/:enrolmentId/consent/send",

  //Lab Orders
  FETCH_LAB_REPORTS: "/v1/reports",
  FULLFILLMENT_GET_READ_LINK: "/v1/file/:id",

  GENERATE_URL: "/v1/file",
  UPLOAD_FILES_TO_DB: "/v1/orders/:order_id/sub-order/:sub_order_id/documents",

  // Appointment Module
  FETCH_DOCTOR_FOR_APPOINTMENT: "/v1/appointments/appointees",
  CREATE_APPOINTMENT: "/v1/appointments",
  FETCH_APPOINTMENTS: "/v1/appointments",
  FETCH_APPOINTMENT_CALENDAR_COUNT: "/v1/appointments/calendar",
  FETCH_DOCTOR_SLOTS: "/v1/appointments/appointees/:doctor_id/slots",
  CANCEL_APPOINTMENT: "/v1/appointments/:appointment_id/cancel",
  EDIT_APPOINTMENT: "/v1/appointments/:appointment_id",
  CONFIRM_APPOINTMENT: "/v1/appointments/:appointment_id/confirm",
  FETCH_APPOINTMENT_BY_ID: "/v1/appointments/appointment/:appointment_id",
  UPLOAD_PRESCRIPTION: "/v1/appointments/:appointment_id/upload-prescriptions",
  MASTER_DROPDOWN_DATA: "/v1/programs/:programId/masters",
  UPADTE_ENROLMENT_STATUS: "/v1/enrolments/:enrolmentId/status",
  APPOINTMENT_DOCUMENT_TYPE: "/v1/appointments/document-types",

  //file upload
  GET_READ_LINK: "/v1/file/:id",
  STORE_ENROLMENT_DOCUMENT: "/v1/enrolments/:enrolment_id/documents",
};
export default Endpoints;
