import { create } from "zustand";
import { useConfirmDocumentUploadSlice } from "./confirmUploadSlice";
import { useFetchMasterSlice } from "./fetchMasterSlice";
import { useFetchProgramDocumentSlice } from "./fetchProgramDocument";
import { useGenerateSignedUrlSlice } from "./generateSignedUrlSlice";
import { useUploadDocumnetSlice } from "./uploadDocumentSlice";
import { useGetReadLinkSlice } from "./GetReadLinkSlice";
import { useStoreEnrolmentDocumnetSlice } from "./storeEnrolmentSlice";


interface DocumentStoreInterface
  extends ReturnType<typeof useGenerateSignedUrlSlice>,
    ReturnType<typeof useUploadDocumnetSlice>,
    ReturnType<typeof useConfirmDocumentUploadSlice>,
    ReturnType<typeof useFetchMasterSlice>,
    ReturnType<typeof useFetchProgramDocumentSlice>,
    ReturnType<typeof useGetReadLinkSlice>,
    ReturnType<typeof useStoreEnrolmentDocumnetSlice> {}

export const useDocumentStore = create<DocumentStoreInterface>((...doc) => ({
  ...useGenerateSignedUrlSlice(...doc),
  ...useUploadDocumnetSlice(...doc),
  ...useConfirmDocumentUploadSlice(...doc),
  ...useFetchMasterSlice(...doc),
  ...useFetchProgramDocumentSlice(...doc),
  ...useGetReadLinkSlice(...doc),
  ...useStoreEnrolmentDocumnetSlice(...doc)
}));
