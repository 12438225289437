import { Skeleton, Stack } from "@mui/material";

type Props = {};

const ListLoading = (props: Props) => {
  return (
    <Stack spacing={2} margin={3}>
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={80}
        sx={{
          borderRadius: "12px",
        }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={80}
        sx={{
          borderRadius: "12px",
        }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={80}
        sx={{
          borderRadius: "12px",
        }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={80}
        sx={{
          borderRadius: "12px",
        }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={80}
        sx={{
          borderRadius: "12px",
        }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={80}
        sx={{
          borderRadius: "12px",
        }}
      />
    </Stack>
  );
};

export default ListLoading;
