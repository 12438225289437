import { UpdateProgramDetails } from "../../domain/usages/update-program-details";
import { HttpConstants } from "../protocols/http/http-constants";
import { HttpPutClient } from "../protocols/http/http-put-client";

export class RemoteUpdateProgramDetails implements UpdateProgramDetails {
  constructor(
    private readonly url: string,
    private readonly httpPutClient: HttpPutClient
  ) {}

  async update(id: string, params?: UpdateProgramDetails.Params): Promise<any> {
    const httpResponse = await this.httpPutClient.put({
      url: this.url.replace(":id", id.toString()),
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (httpResponse.status == 200) {
      return httpResponse.data;
    }
  }
}
 