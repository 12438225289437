import { Card, CardHeader, Button, Typography, Stack } from "@mui/material";
import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import Add from "@mui/icons-material/Add";
import ImageComponent from "../../ImageComponent";
import "../../../../components/patient-information/OncologyPageStyles.css";
import AddDocumentModal from "./AddDocumentModal";
import UpdateDocumentModal from "./UpdateDocumentModal";
import { useEnrollmentMainStore } from "../../../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";
import { useDocumentStore } from "../../../../../store/adminPageStore/patientDetailsStore/documentStore/documentStore";
import { GOOGLE_RECAPTCHE_SITE_KEY } from "../../../../../base";
import useRecaptcha from "../../../../hooks/reCaptcha";

const ProgramDocs: React.FC = () => {
  const { selectedCard, isBlurred } = useEnrollmentMainStore();
  const { fetchProgramDocuments } = useDocumentStore();
  const {
    handleAddNewDocumentModal,
    handleUpdateDocumentModal,
    handleRefreshUrl,
    showAddNewDocumentModal,
    showUpdateDocumentModal,
  } = useDocumentStore();
  const {
    captchaToken: fetchProgramDocumentsToken,
    executeRecaptcha: executeFetchProgramDocumentsRecaptcha,
  } = useRecaptcha(GOOGLE_RECAPTCHE_SITE_KEY, "fetchProgramDocuments");

  useEffect(() => {
    executeFetchProgramDocumentsRecaptcha();
    handleRefreshUrl();
  }, []);

  useEffect(() => {
    if (fetchProgramDocumentsToken) {
      fetchProgramDocuments(
        selectedCard.program.id,
        fetchProgramDocumentsToken
      );
    }
  }, [fetchProgramDocumentsToken]);

  return (
    <Stack overflow="scroll" height="48vh">
      {!isBlurred && (
        <Stack
          direction={"row"}
          justifyContent="end"
          bgcolor={"white"}
          position={"fixed"}
          width={"41%"}
          padding={1}
        >
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<Add />}
            onClick={() => handleAddNewDocumentModal()}
          >
            <Typography variant="subtitle2" fontWeight={"bold"}>
              Add New Document
            </Typography>
          </Button>
        </Stack>
      )}
      <Grid container spacing={4} mt={4}>
        {selectedCard.documents.map((document, index) => {
          return (
            <Grid item xs={6}>
              <Card key={index} variant="outlined">
                <CardHeader
                  title={
                    <Typography variant="body1" fontWeight={600}>
                      {document.document_type}
                    </Typography>
                  }
                />
                <ImageComponent uuid={document.uuid} />
                <Stack direction="row" spacing={1} marginLeft={1}>
                  <Typography variant="subtitle2" fontWeight={"bold"}>
                    Document Group:
                  </Typography>
                  <Typography variant="subtitle2">
                    {document.document_group}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1} marginLeft={1}>
                  <Typography variant="subtitle2" fontWeight={"bold"}>
                    Status:
                  </Typography>
                  <Typography variant="subtitle2">{document.status}</Typography>
                </Stack>
                {!isBlurred && (
                  <Button
                    variant="text"
                    onClick={() => handleUpdateDocumentModal()}
                    color="secondary"
                  >
                    Update
                  </Button>
                )}
              </Card>
            </Grid>
          );
        })}
      </Grid>
      {showAddNewDocumentModal && <AddDocumentModal />}
      {showUpdateDocumentModal && <UpdateDocumentModal />}
    </Stack>
  );
};
export default ProgramDocs;
