import { create } from "zustand";
import { useGetEnrolmentByConsentTokenDetails } from "./getEnrolmentByConsentTokenSlice";
import { useAcceptPatientConsent } from "./acceptPatientConsent";

interface ConsentStoreInterface
  extends ReturnType<typeof useGetEnrolmentByConsentTokenDetails>,
    ReturnType<typeof useAcceptPatientConsent> {}

export const useConsentStore = create<ConsentStoreInterface>((...data) => ({
  ...useGetEnrolmentByConsentTokenDetails(...data),
  ...useAcceptPatientConsent(...data),
}));
