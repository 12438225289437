import * as React from "react";
import "../../components/patient-information/OncologyPageStyles.css";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { EnrollmentType } from "../../../domain/models/enrollment-type";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment";
import { useEnrollmentMainStore } from "../../../store/adminPageStore/enrollmentListStore/enrollmentMainStore";

type Props = {
  cardData: EnrollmentType;
  active: boolean;
};

const EnrollmentCard: React.FC<Props> = ({ cardData, active }) => {
  const { isBlurred } = useEnrollmentMainStore();
  return (
    <Card
      variant="outlined"
      sx={{
        width: "100%",
        border: " 1px solid #D0CFCF",
        borderRadius: " 12px",
        marginTop: "14px",
        cursor: "pointer",
        background: active ? "rgba(149, 157, 165, 0.2)" : "#FFF",
        "&:hover": {
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        },
      }}
    >
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems="center"
      >
        <CardHeader
          avatar={
            <Avatar
              sx={{
                bgcolor: "#6750A4",
              }}
            >
              {cardData.patient_full_name[0]}
            </Avatar>
          }
          title={
            <Typography className={isBlurred ? "blurred-text" : ""}>
              {cardData.patient_full_name}
            </Typography>
          }
          subheader={
            <Stack>
              <Typography variant="caption">
                Code: {cardData.patient_code}
              </Typography>
              <Box>
                <Typography variant="caption" whiteSpace="nowrap">
                  Product: {cardData.product.name}
                </Typography>
              </Box>
              <Box>
                <Typography variant="caption">
                  Enrolment Date: {moment(cardData.created_at).format("ll")}
                </Typography>
              </Box>
            </Stack>
          }
        />

        {cardData.consent_acknowledgement == 1 && (
          <CardContent>
            <CheckCircleIcon color="success" fontSize="small" />
          </CardContent>
        )}
      </Stack>
    </Card>
  );
};
export default EnrollmentCard;
