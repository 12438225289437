import Swal from "sweetalert2";
import { StateCreator } from "zustand";
import {
  AUTH_HEADER,
  PSP_AUTH_TOKEN_KEY,
  PSP_PROGRAM_SERVICE_API_URL,
} from "../../../base";
import Endpoints from "../../../domain/endpoints";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AddNewFollowUpForThread } from "../../../domain/usages/follow-ups/add-follow-up-for-thread";
import { RemoteAddNewFollowUpForThread } from "../../../data/usecases/follow-ups/remote-add-follow-up-for-thread";
import { AddCommentsForFollowUp } from "../../../domain/usages/follow-ups/add-comments-for-follow-up";
import { RemoteAddCommentsForFollowUp } from "../../../data/usecases/follow-ups/remote-add-comments-for-follow-up";
import { useFollowUpsStore } from "./followUpsStore";
import { LocalLoggedInUser } from "../../../data/usecases/local-logged-in-user";

interface AddCommentsForFollowUpType {
  addCommentsForFollowUpLoading: boolean;
  addCommentsForFollowUp: Function;
}

const initialStates = {
  addCommentsForFollowUpLoading: false,
};

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

export const useAddCommentsForFollowUpSlice: StateCreator<
  AddCommentsForFollowUpType
> = (set, get) => ({
  ...initialStates,
  addCommentsForFollowUp: async (
    threadId: string,
    followUpId: string,
    payload: AddCommentsForFollowUp.Params
  ) => {
    const loggedInUser = new LocalLoggedInUser(storage);
    let token = loggedInUser.getPSPToken();
    axiosHttpClient.setAuthHeaders({ [AUTH_HEADER]: token });

    const remoteAddCommentsForFollowUp: AddCommentsForFollowUp =
      new RemoteAddCommentsForFollowUp(
        `${PSP_PROGRAM_SERVICE_API_URL}${Endpoints.ADD_COMMENTS_FOR_FOLLOW_UP}`,
        axiosHttpClient
      );
    set(() => ({ addCommentsForFollowUpLoading: true }));
    const result = await remoteAddCommentsForFollowUp.add(
      threadId,
      followUpId,
      payload
    );

    if (result.success) {
      set(() => ({ addCommentsForFollowUpLoading: false }));
      useFollowUpsStore
      .getState()
      .fetchFollowUpForThread(
        threadId,
        false
      );
      Swal.fire("Comment added successfully.", "", "success");
    } else {
      set(() => ({ addCommentsForFollowUpLoading: false }));
      Swal.fire("Comment creation failed.", "", "error");
    }
  },
});
