import { AddNewFollowUpForThread } from "../../../domain/usages/follow-ups/add-follow-up-for-thread";
import { CloseFollowUpThread } from "../../../domain/usages/follow-ups/close-follow-up-thread";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpPostClient } from "../../protocols/http/http-post-client";
import { HttpPutClient } from "../../protocols/http/http-put-client";

export class RemoteCloseFollowUpThread implements CloseFollowUpThread {
  constructor(
    private readonly url: string,
    private readonly httpPutClient: HttpPutClient
  ) {}

  async close(
    threadId: string,
    params: CloseFollowUpThread.Params
  ): Promise<any> {
    const httpResponse = await this.httpPutClient.put({
      url: this.url.replace(":threadId", threadId),
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
