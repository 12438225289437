import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useOrderDetailsStore } from "../../../store/stockistPageStore/orderStore/orderDetailsStore";
import { FetchState } from "../../../domain/models/fetch-state-type";
import { useDocumentStore } from "../../../store/adminPageStore/patientDetailsStore/documentStore/documentStore";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";

import { Document, Page, pdfjs } from "react-pdf";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import "../../../App.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { saveAs } from "file-saver";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

type Props = {
  openDocument: boolean;
  orderId: number;
  subOrderId: number;
  handleClose: Function;
};

const style = {
  borderRadius: "12px",
  display: "flex",
  gap: 2,
  flexDirection: "column",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "90vh",

  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ViewOrderDocumentModal: React.FC<Props> = ({
  openDocument,
  subOrderId,
  orderId,
  handleClose,
}) => {
  const { fetchSubOrderDetails, fetchSubOrderDetailsState, subOrderDetails } =
    useOrderDetailsStore();
  const { getImage, imageUrl, fetchImageState } = useDocumentStore();

  useEffect(() => {
    if (openDocument) {
      fetchSubOrderDetails(orderId, subOrderId);
    }
  }, [openDocument]);
  const [numPages, setNumPages] = React.useState<number | null>(null);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [scale, setScale] = React.useState<number>(1.3);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
    setPageNumber(1);
  };
  const downloadPDF = async (url: string): Promise<void> => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      saveAs(blob, "downloaded.pdf");
    } catch (error) {
      console.error("Error downloading the PDF:", error);
    }
  };
  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const loaderRef = React.useRef<HTMLDivElement>(null);
  const handleZoomIn = () => {
    setScale((prevScale) => prevScale + 0.1); // Increase scale by 0.1
  };

  const handleZoomOut = () => {
    setScale((prevScale) => Math.max(0.1, prevScale - 0.1)); // Decrease scale by 0.1, with a minimum of 0.1
  };

  const loadMorePages = React.useCallback(() => {
    if (numPages && pageNumber < numPages) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  }, [pageNumber, numPages]);

  useIntersectionObserver({
    target: loaderRef,
    onIntersect: loadMorePages,
    enabled: pageNumber < (numPages || 0),
  });

  useEffect(() => {
    if (
      subOrderDetails &&
      subOrderDetails.order_document_details &&
      subOrderDetails.order_document_details.file_uuid
    ) {
      getImage(subOrderDetails.order_document_details.file_uuid);
    }
  }, [subOrderDetails]);
  return (
    <Modal open={openDocument} onClose={() => handleClose()}>
      <Box sx={style}>
        <Stack justifyContent={"end"} alignItems={"end"}>
          <IconButton onClick={() => handleClose()}>
            <CloseIcon fontSize="small" color="error" />
          </IconButton>
        </Stack>
        {(fetchSubOrderDetailsState == FetchState.ERROR ||
          fetchImageState == FetchState.ERROR) && (
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"row"}
            gap={1}
          >
            <ErrorIcon color="error" fontSize="large" />
            <Typography>
              Error while displaying image, please try after sometime..
            </Typography>
          </Stack>
        )}
        {(fetchSubOrderDetailsState == FetchState.LOADING ||
          fetchImageState == FetchState.LOADING) && (
          <Stack alignItems={"center"} justifyContent={"center"}>
            <CircularProgress />
          </Stack>
        )}
        {fetchSubOrderDetailsState == FetchState.SUCCESS &&
          fetchImageState == FetchState.SUCCESS && (
            <Stack>
              <Stack>
                <Typography>
                  Document Type:{" "}
                  {subOrderDetails.order_document_details.document_type}
                </Typography>
                <Typography>
                  Uploaded by:{" "}
                  {subOrderDetails.order_document_details.uploader_name}
                </Typography>
                <Typography>
                  Uploaded on:{" "}
                  {moment(
                    subOrderDetails.order_document_details.updated_at
                  ).format("ll")}
                </Typography>
              </Stack>
              {imageUrl && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Box
                    border={"1px solid"}
                    borderRadius={"30%"}
                    color={"primary.main"}
                    bgcolor={"primary.main"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <IconButton size="small" onClick={handleZoomIn}>
                      <ZoomInIcon sx={{ color: "white" }} />
                    </IconButton>
                  </Box>
                  <Box
                    border={"1px solid"}
                    borderRadius={"30%"}
                    color={"primary.main"}
                    bgcolor={"primary.main"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <IconButton size="small" onClick={handleZoomOut}>
                      <ZoomOutIcon sx={{ color: "white" }} />
                    </IconButton>
                  </Box>
                  <Box alignItems={"center"} justifyContent={"center"}>
                    <Button
                      variant="contained"
                      endIcon={<SaveAltIcon sx={{ color: "white" }} />}
                      onClick={() => downloadPDF(imageUrl)}
                    >
                      <Typography
                        color={"white"}
                        variant="caption"
                        fontWeight={660}
                      >
                        Download
                      </Typography>
                    </Button>
                  </Box>
                  <Stack
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDirection={"row"}
                    gap={2}
                  >
                    <Typography>
                      Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                      {numPages || "--"}
                    </Typography>
                    <Button
                      size="small"
                      variant="contained"
                      disabled={pageNumber <= 1}
                      onClick={previousPage}
                    >
                      Previous
                    </Button>
                    {numPages && (
                      <Button
                        size="small"
                        variant="contained"
                        disabled={pageNumber >= numPages}
                        onClick={nextPage}
                      >
                        Next
                      </Button>
                    )}
                  </Stack>
                </Box>
              )}

              <Container
                sx={{
                  overflowY: "auto",
                  flexDirection: "row",
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "60vh",
                }}
              >
                <Document
                  file={imageUrl}
                  loading={
                    <Stack
                      alignItems={"center"}
                      justifyItems={"center"}
                      height={"60vh"}
                    >
                      <CircularProgress />
                    </Stack>
                  }
                  onEnded={() => <Typography>End of file</Typography>}
                  error={
                    <Stack
                      alignItems={"center"}
                      justifyContent={"center"}
                      marginTop={10}
                      gap={1}
                    >
                      <Typography variant="subtitle2" fontWeight={660}>
                        Failed to Loading PDF
                      </Typography>
                    </Stack>
                  }
                  noData={
                    <Stack
                      alignItems={"center"}
                      justifyContent={"center"}
                      marginTop={10}
                    >
                      No Records found
                    </Stack>
                  }
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page pageNumber={pageNumber} scale={scale} />
                </Document>
              </Container>
            </Stack>
          )}
      </Box>
    </Modal>
  );
};

export default ViewOrderDocumentModal;
