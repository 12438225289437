import { Grid } from "@mui/material";
import React, { useState } from "react";
import PatientDetailsTabs from "./PatientDetailsTabs";
import PatientBasicDetails from "../patient-header/PatientBasicDetails";

const PatientDetails: React.FC = () => {
  const [activeTab, setActiveTab] = useState("1");
  const onTabChange = (newValue: string) => {
    setActiveTab(newValue);
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          marginInline: "26px",
        }}
      >
        <PatientBasicDetails activeTab={activeTab} />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          marginInline: "26px",
        }}
      >
        <PatientDetailsTabs activeTab={activeTab} onTabChange={onTabChange} />
      </Grid>
    </Grid>
  );
};
export default PatientDetails;
