import { HttpConstants } from "../protocols/http/http-constants";
import { HttpPostClient } from "../protocols/http/http-post-client";
import { CreateNewEnrollment } from "../../domain/usages/create-new-enrollment";

export class RemoteCreateNewEnrollmentByPatient implements CreateNewEnrollment {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async create(params: CreateNewEnrollment.Params): Promise<any> {
    const httpResponse = await this.httpPostClient.post({
      url: this.url,
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
